import { StyleSheet } from 'react-native';
import mixins from '../../app/styles';

const styles = StyleSheet.create({
  outer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: mixins.color.backgroundBlue,
    textAlign: 'center',
    maxWidth: 800,
    padding: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: mixins.color.whiteText,
    fontSize: 18,
    lineHeight: 32,
    marginVertical: 15,
  },
  heading: {
    color: mixins.color.whiteText,
    fontSize: mixins.font.titleFont,
    marginBottom: 50,
  },
  button: {
    marginTop: 50,
    maxWidth: 200,
  },
});

export default styles;
