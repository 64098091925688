import Button from '#/components/Button/Button';
import { useManageBoxControls } from '#/screens/stream/manageBox/utils';
import { useChatMessagesStore } from '#/store/chatMessages/chatMessagesStore';
import { ChatState } from '#/socket/document.type';
import { SocketContext } from '#/socket/socket.provider';
import { useContext, useEffect, useRef, useState } from 'react';
import { TextInput, View, Text, Pressable } from 'react-native';
import { styles } from './ChatBox.styles';
import CloseIcon from '#/assets/icons/close.svg';
import { ChatBoxStatusDisplay } from '#/components/ChatBoxStatusDisplay/ChatBoxStatusDisplay';
import mixins from '#/app/styles';
import { ChatsAuthor, ChatsMessage } from '#/store/chatMessages/chatMessagesStoreTypes';
import { useChatInitialization } from '#/components/ChatBox/hooks/useChatInitialization';
import { useKeyboardShowHide } from '#/components/ChatBox/hooks/useKeyboardShowHide';
import { getChatData } from '#/components/ChatBox/utils';
import { ChatBoxView } from '#/components/ChatBox/ChatBoxView';

type ChatBoxProps = {
  targetId?: string;
  placeholderText?: string;
};

export const ChatBox: React.FC<ChatBoxProps> = ({ targetId, placeholderText }) => {
  const inputRef = useRef<TextInput>(null);
  const { serverData, sendPatch } = useContext(SocketContext);
  const manageBoxControls = useManageBoxControls();
  const [message, setMessage] = useState('');
  const {
    chatsData,
    replyTo,
    setReplyTo,
    clearAllUnreadMessages,
    clearUnreadMessages,
    sendChatMessage,
  } = useChatMessagesStore();

  const [chatId, chatObject] =
    Object.entries(serverData.Platforms.Chats).find(c => c[1].TargetId === targetId) || [];
  const chatData = getChatData(chatsData, replyTo, targetId);

  useChatInitialization({ sendPatch, targetId, chatId });
  useKeyboardShowHide();

  useEffect(() => {
    if (!chatData.unreadMessages && !chatData.unseenTips) {
      return;
    }

    if (targetId) {
      clearUnreadMessages(targetId);
    } else {
      clearAllUnreadMessages();
    }
  }, [chatData.unreadMessages, targetId]);

  if (targetId && (!chatId || chatObject?.State !== ChatState.Connected)) {
    return <ChatBoxStatusDisplay chat={chatObject} chatId={chatId} />;
  }

  const handleSendMessage = () => {
    if (!chatId) {
      return;
    }

    sendChatMessage(chatId, message, replyTo);
    setMessage('');
    setTimeout(() => inputRef.current?.focus(), 5);
  };

  const messages = chatData.messages.slice(-100).reverse();

  if (chatData.messages.length === 0 && placeholderText) {
    return (
      <View style={styles.wrapper}>
        <Text style={styles.placeholderText}>{placeholderText}</Text>
      </View>
    );
  }

  const handleMessagePress = ({
    message,
    author,
  }: {
    message: ChatsMessage;
    author: ChatsAuthor;
  }) => {
    if (message.Internal) {
      return;
    }

    if (author.Self && message.ToAuthorId) {
      setReplyTo(message.ToAuthorId);
    }
    if (!author.Self) {
      setReplyTo(message.AuthorId);
    }

    if (!author.target) {
      return;
    }

    const [id] =
      Object.entries(serverData.Channels).find(
        ([_id, channel]) => channel.TargetId === author.target,
      ) || [];

    if (!id) {
      return;
    }

    manageBoxControls.editChannel(id, 'CHATS');
  };

  return (
    <View style={styles.wrapper}>
      <ChatBoxView
        authors={chatData.authors}
        messages={messages}
        onMessagePress={handleMessagePress}
        targets={serverData.Targets}
        targetId={targetId}
      />
      {chatId && (
        <View style={styles.inputRow}>
          {replyTo && (
            <Pressable style={styles.replyToWrapper} onPress={() => setReplyTo(undefined)}>
              <Text style={styles.replyToTitle}>private to</Text>
              <Text style={styles.replyToName}>{chatData.authors[replyTo].Name}</Text>
              <CloseIcon style={styles.replyToClose} fill={mixins.color.white} />
            </Pressable>
          )}
          <TextInput
            ref={inputRef}
            placeholder={replyTo ? 'Private mess age' : 'Public message'}
            value={message}
            onChangeText={setMessage}
            placeholderTextColor="#ccc"
            style={[styles.input, replyTo ? styles.replyInput : undefined]}
            onSubmitEditing={handleSendMessage}
          />
          <Button style={styles.button} onPress={handleSendMessage}>
            Send
          </Button>
        </View>
      )}
    </View>
  );
};
