import { View, Text, TouchableOpacity, ScrollView } from 'react-native';

// styles
import styles from './auth.styles';
import { MaterialInput } from '../../components/MaterialInput/MaterialInput';
import Button from '../../components/Button/Button';
import mixins from '../../app/styles';
import handleLink from '../../helpers/handle-link';
import { useLogin } from './auth.utils';
import { useIsMobile } from '../../utils';
import { useAppNavigation, useAppRoute } from '#/navigation/navigation.ref';

const AuthScreen = () => {
  const navigation = useAppNavigation();
  const { handleLogin, name, password, progress, setName, setPassword, show2fa, code, setCode } =
    useLogin();
  const isMobile = useIsMobile();
  const route = useAppRoute<'SignIn'>();

  return (
    <ScrollView>
      <View style={styles.webWrapper}>
        <View style={[styles.box, isMobile && styles.boxMobile]}>
          <Text style={[styles.webHeader, isMobile && mixins.styles.margin.topI5]}>Sign in</Text>
          <Text style={styles.webSubHeader}>to continue to Streamster</Text>
          {route.params?.handler === 'after-registration' && (
            <Text style={styles.registered}>
              Your account was created successfully, you can now sign in.
            </Text>
          )}
          {route.params?.handler === 'activated' && (
            <Text style={styles.registered}>
              Your account was created successfully, you can now sign in.
            </Text>
          )}

          {route.params?.handler === 'migrate-business' && (
            <Text style={styles.registered}>
              Your account type was switched to business,{'\n'} you can now sign in.
            </Text>
          )}

          {show2fa ? (
            <>
              <Text style={styles.registered}>
                Two-factor authentication required.{'\n'}Code has been sent to your email address.
              </Text>
              <form onSubmit={handleLogin} className="login-form">
                <View
                  style={[
                    styles.inputContainer,
                    styles.webInputContainer,
                    isMobile && mixins.styles.margin.bottomI10,
                  ]}
                >
                  <MaterialInput onChangeText={setCode} value={code} label="Two-factor code" />
                  <View style={[styles.actionRow, isMobile && styles.actionRowMobile]}>
                    <Button onPress={handleLogin} loading={progress}>
                      Submit
                    </Button>
                  </View>
                </View>
              </form>
            </>
          ) : (
            <form onSubmit={handleLogin} className="login-form">
              <View
                style={[
                  styles.inputContainer,
                  styles.webInputContainer,
                  isMobile && mixins.styles.margin.bottomI10,
                ]}
              >
                <MaterialInput
                  onChangeText={setName}
                  autoComplete="username"
                  value={name}
                  label="User name"
                />
                <MaterialInput
                  secureTextEntry
                  autoComplete="password"
                  onChangeText={setPassword}
                  value={password}
                  label="Password"
                />
                <View style={[styles.actionRow, isMobile && styles.actionRowMobile]}>
                  <View style={isMobile && mixins.styles.margin.topI10}>
                    <TouchableOpacity onPress={() => navigation.replace('ResetPassword')}>
                      <Text style={styles.forgotPassword}>Forgot your login or password?</Text>
                    </TouchableOpacity>
                  </View>
                  <Button onPress={handleLogin} loading={progress}>
                    Sign in
                  </Button>
                </View>
              </View>
            </form>
          )}
        </View>
        <View style={[styles.box, isMobile && styles.boxMobile]}>
          <View style={styles.linkWrap}>
            <Text style={[styles.webHeader, isMobile && mixins.styles.margin.topI5]}>
              Not yet registered?
            </Text>
            <Button
              onPress={() => navigation.replace('Register', {})}
              style={mixins.styles.margin.topI10}
              secondary
            >
              Create account
            </Button>
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

export default AuthScreen;
