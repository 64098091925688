import React from 'react';
import DefaultModal from '../DefaultModal/DefaultModal';
import { useGlobalModalStore } from './store';

const GlobalModal = () => {
  const { modal, hide } = useGlobalModalStore();

  if (!modal) {
    return null;
  }

  const onDismiss =
    !modal.onConfirm || modal.onDismiss
      ? () => {
          modal.onDismiss?.();
          hide();
        }
      : undefined;

  const onConfirm = () => {
    modal.onConfirm?.();
    hide();
  };

  return (
    <DefaultModal dismissText="Close" {...modal} alignItemsCenter onDismiss={onDismiss} onConfirm={onConfirm} />
  );
};

export default GlobalModal;
