import { Target } from '#/socket/document.type';
import React from 'react';
import { View, Text, FlatList, Pressable } from 'react-native';
import { styles } from './ChatBox.styles';
import {
  ChatsAuthor,
  ChatsMessage,
  ChatsMessageType,
} from '#/store/chatMessages/chatMessagesStoreTypes';
import { getShouldDisplayAuthor, renderAuthor } from '#/components/ChatBox/utils';

type Props = {
  targetId?: string;
  messages: ChatsMessage[];
  targets: Record<string, Target>;
  onMessagePress: (arg: { message: ChatsMessage; author: ChatsAuthor }) => void;
  authors: Record<string, ChatsAuthor>;
};

export const ChatBoxView: React.FC<Props> = ({
  authors,
  messages,
  onMessagePress,
  targets,
  targetId,
}) => {
  return (
    <FlatList
      style={styles.scrollViewStyles}
      contentContainerStyle={styles.scrollViewContainer}
      inverted
      keyboardDismissMode="none"
      data={messages}
      renderItem={({ item, index }: { item: ChatsMessage; index: number }) => {
        const author = authors[item.AuthorId];
        const previousMessage = messages[index + 1];
        const shouldDisplayAuthor = getShouldDisplayAuthor({
          author,
          message: item,
          previousMessage,
        });
        const fullTarget = targets[targetId || author.target || ''];

        return (
          <View
            key={`${item.Time}-${item.Msg}`}
            style={[styles.messageWrapper, author.Self && styles.myMessageWrapper]}
          >
            <Pressable
              onPress={() => onMessagePress({ message: item, author })}
              style={[
                styles.message,
                author.Self && styles.myMessage,
                item.Private && styles.privateMessageWrapper,
                item.Type === ChatsMessageType.Tip &&
                  (item.Private ? styles.privateTipMessage : styles.tipMessage),
              ]}
            >
              {shouldDisplayAuthor && (
                <Text style={styles.author}>
                  {renderAuthor({
                    author,
                    message: item,
                    target: fullTarget,
                    authors,
                  })}
                </Text>
              )}
              <Text style={styles.messageText}>{item.Msg}</Text>
            </Pressable>
          </View>
        );
      }}
    />
  );
};
