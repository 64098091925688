import { StyleSheet } from 'react-native';
import mixins from '../../app/styles';

const styles = StyleSheet.create({
  channelWrapper: {
    display: 'flex',
    marginRight: mixins.indent.i5,
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 5,
    paddingLeft: 15,
    paddingRight: 3,
    minWidth: 150,
    justifyContent: 'space-between',
    backgroundColor: '#565656',
    marginBottom: 10,
  },
  channelText: {
    color: mixins.color.whiteText,
  },
  channelIcon: {
    borderRadius: 50,
    width: 45,
    height: 45,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    margin: -8,
  },
  channelsRow: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 50,
    flexWrap: 'wrap',
    marginTop: 10,
  },
  title: {
    color: mixins.color.whiteText,
    fontSize: 16,
    marginBottom: 20,
  },
  label: {
    fontSize: 12,
    color: mixins.color.grey,
  },
  originalWrapper: {
    borderBottomWidth: 1,
    padding: 20,
    paddingVertical: 15,
    borderStyle: 'solid',
  },
  transcodedWrapper: {
    padding: 20,
    paddingVertical: 15,
    backgroundColor: '#232730',
  },
  option: {
    marginRight: 25,
  },
  optionBitrate: {
    flexGrow: 1,
    marginRight: 0,
  },
  optionFps: { width: 60 },
  optionsRow: {
    flexDirection: 'row',
    marginBottom: 10,
  },

  infoWrapper: {
    backgroundColor: '#151515',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
  },
  infoIcon: {
    height: 60,
    marginRight: 15,
  },
  infoText: {
    color: mixins.color.grey,
  },
  warningText: {
    color: mixins.color.red,
  },

  disabledWrapper: {
    alignItems: 'center',
  },
  disabledRed: {
    color: mixins.color.red,
    fontSize: 16,
    marginVertical: 5,
  },
  disabledBlue: {
    fontSize: 16,
    color: mixins.color.blue,
    textDecorationLine: 'underline',
  },

  inactive: {
    color: mixins.color.whiteText,
  },
  blockedSlider: {
    //@ts-expect-error missing pointer events for web
    pointerEvents: 'none',
  },
});

export default styles;
