import React from 'react';
import { Image, Pressable, Text, View } from 'react-native';
import type { Target } from '../../../socket/document.type';
import styles from './AddTarget.styles';

import CogsIcon from '../../../assets/icons/cogs.svg';
import mixins from '../../../app/styles';

const AddTarget: React.FC<{
  target: Target;
  handleChooseTarget: (target: Target) => void;
}> = React.memo(({ target, handleChooseTarget }) => (
  <Pressable onPress={() => handleChooseTarget(target)}>
    {({ hovered }) => (
      <View style={[styles.channel, hovered && styles.channelHover]}>
        <View style={styles.imageWrapper}>
          {target.Id === 'custom' ? (
            <CogsIcon height={80} fill={mixins.color.white} />
          ) : (
            <Image
              style={styles.channelImage}
              source={{ uri: `https://de10.streamster.io:6001/Files/targets/${target.Id}.png` }}
            />
          )}
        </View>
        <View style={styles.channelNameWrapper}>
          <Text style={styles.channelName}>{target.Name}</Text>
        </View>
      </View>
    )}
  </Pressable>
));

export default AddTarget;
