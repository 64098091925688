import { StyleSheet } from 'react-native';
import mixins from '../../app/styles';
import { DEVICE_WIDTH } from '../../utils';

const IMAGE_ORIGINAL_SIZE = {
  width: 934,
  height: 277,
};

const IMAGE_HEIGHT =
  (IMAGE_ORIGINAL_SIZE.height * (DEVICE_WIDTH - mixins.indent.i9 * 2)) / IMAGE_ORIGINAL_SIZE.width;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  flex: {
    flex: 1,
  },
  logoImg: {
    marginHorizontal: mixins.indent.i9,
    width: DEVICE_WIDTH - mixins.indent.i9 * 2,
    height: IMAGE_HEIGHT,
    marginTop: mixins.indent.i11 * 2,
  },
  inputContainer: {
    marginBottom: mixins.indent.i11 * 2,
  },
  inputWrap: {
    marginHorizontal: mixins.indent.i11,
    shadowColor: mixins.color.white,
    shadowOffset: {
      width: 5,
      height: 5,
    },
    shadowOpacity: 0.6,
    shadowRadius: mixins.indent.i10,
    elevation: 30,
  },
  input: {
    height: mixins.indent.i12,
    backgroundColor: mixins.color.white,
    paddingHorizontal: mixins.indent.i7,
    color: mixins.color.dirty,
    fontWeight: 'bold',
  },
  inputUp: {
    borderTopLeftRadius: mixins.indent.i3,
    borderTopRightRadius: mixins.indent.i3,
    borderBottomWidth: 0.5,
    borderBottomColor: mixins.color.input,
  },
  inputDown: {
    borderBottomLeftRadius: mixins.indent.i3,
    borderBottomRightRadius: mixins.indent.i3,
  },
  btnLogin: {
    backgroundColor: mixins.color.blue,
    marginTop: mixins.indent.i11,
    alignSelf: 'flex-end',
    borderRadius: mixins.indent.i3,
    paddingVertical: mixins.indent.i4,
    paddingHorizontal: mixins.indent.i11,
    borderColor: mixins.color.blueLight,
    borderWidth: 1,
  },
  btnText: {
    color: mixins.color.white,
    fontSize: mixins.indent.i8,
    fontWeight: 'bold',
  },
  linkWrap: {
    alignItems: 'center',
    marginBottom: mixins.indent.i10,
  },
  linkText: {
    color: mixins.color.blueLightest,
    fontSize: mixins.indent.i8,
    marginBottom: mixins.indent.i8,
  },
  indicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },

  // WEB

  webWrapper: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 15,
  },
  webInputContainer: {
    maxWidth: 400,
    width: '100%',
  },
  webHeader: {
    color: mixins.color.whiteText,
    fontSize: 30,
  },
  webSubHeader: {
    color: mixins.color.whiteText,
    fontSize: 20,
  },
  box: {
    maxWidth: 600,
    width: '100%',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,.3)',
    marginBottom: 50,
  },
  goBackIcon: {
    height: 30,
    fill: mixins.color.white,
    alignSelf: 'flex-start',
    borderWidth: 2,
    borderRadius: 500,
    borderStyle: 'solid',
    borderColor: mixins.color.white,
    padding: 5,
    cursor: 'pointer',
  },
  termsWrapper: {
    paddingVertical: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 25,
  },
  termsText: {
    color: mixins.color.whiteText,
    marginLeft: 10,
  },
  termsLink: {
    color: mixins.color.blueLight,
  },
  checkbox: {
    width: 20,
    height: 20,
  },
  btnRegister: {
    alignSelf: 'flex-end',
  },

  selectWrapper: {
    marginTop: 35,
  },
  select: {
    height: 41,
    padding: 0,
    fontWeight: 'bold',
    width: '100%',
    backgroundColor: 'transparent',
    borderColor: '#020f20',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: '#fff',
  },

  tariffBox: {
    backgroundColor: mixins.color.blueDark,
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 15,
    marginVertical: 30,
  },
  tariffText: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: mixins.color.input,
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
  },
  tariffName: {
    color: mixins.color.white,
    fontSize: 16,
    lineHeight: 32,
  },
  tariffError: {
    color: mixins.color.redDark,
  },
  haveReferralCodeText: {
    fontSize: mixins.font.defaultFont,
    marginTop: mixins.indent.i8,
    color: mixins.color.blue,
  },
});

export default styles;
