import { Platform, StyleSheet } from 'react-native';
import mixins, { adaptiveSize } from '../../app/styles';
import { DEVICE_WIDTH, HAS_NOTCH } from '../../utils';

export const CNANEL_SIZE = Math.round((DEVICE_WIDTH - 12 * 4) / 3);
export const CNANEL_LOGO_SIZE = CNANEL_SIZE - 18;

const isWeb = Platform.OS === 'web';

const styles = StyleSheet.create({
  container: {
    ...(isWeb && {
      justifyContent: 'center',
      alignItems: 'center',
    }),
    flex: 1,
    backgroundColor: mixins.color.connection,
  },
  containerWrap: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    ...(isWeb && {
      maxHeight: 200,
    }),
  },
  errorConnectionWrap: {
    alignItems: 'center',
  },
  errorConnectionText: {
    color: mixins.color.white,
    fontSize: adaptiveSize(20),
  },
  btnLogin: {
    marginTop: adaptiveSize(25),
    backgroundColor: mixins.color.deepBlue,
  },
  btnText: {
    color: mixins.color.white,
    fontSize: adaptiveSize(20),
    marginVertical: adaptiveSize(16),
    marginHorizontal: adaptiveSize(42),
  },
  icon: {
    marginBottom: adaptiveSize(60),
  },
  settingsIcon: {
    position: 'absolute',
    top: HAS_NOTCH ? adaptiveSize(40) : adaptiveSize(25),
    right: adaptiveSize(25),
    zIndex: 1,
    opacity: 0.4,
  },
  connectingText: {
    marginTop: mixins.indent.i5,
    color: mixins.color.blueLightest,
    marginBottom: 'auto',
  },
  loader: {
    marginBottom: 0,
  },
});

export default styles;
