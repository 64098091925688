import { StyleSheet } from 'react-native';
import mixins from '../../app/styles';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  button: {
    backgroundColor: mixins.color.darker,
    borderRadius: 0,
    color: mixins.color.white,
    borderColor: mixins.color.dark,
    paddingHorizontal: 15,
  },
  buttonActive: {
    backgroundColor: mixins.color.darker,
    borderRadius: 0,
    color: mixins.color.white,
    borderColor: mixins.color.blue,
    paddingHorizontal: 15,
  },
});

export default styles;
