import React from 'react';
import {
  ActivityIndicator,
  Pressable,
  PressableStateCallbackType,
  StyleProp,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import mixins from '../../app/styles';
import styles from './Button.styles';

interface Props {
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  icon?: React.ReactNode;
  disabled?: boolean;
  secondary?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  loading?: boolean;
  textSize?: 'normal' | 'big';
  color?: 'blue' | 'red';
}

const Button: React.FC<Props> = ({
  children,
  onPress,
  style,
  disabled,
  textStyle,
  secondary,
  icon,
  loading,
  textSize,
  color,
}) => {
  const renderContent = ({ hovered }: PressableStateCallbackType) => {
    if (typeof children === 'string') {
      return (
        <Text
          style={[
            styles.btnText,
            textStyle,
            secondary && styles.secondary,
            secondary && hovered && styles.secondaryTextHovered,
            textSize === 'big' && styles.btnTextBig,
            icon && styles.btnTextIcon,
          ]}
        >
          {children}
        </Text>
      );
    }

    if (
      Array.isArray(children) &&
      children.every(c => typeof c === 'string' || typeof c === 'number')
    ) {
      return (
        <Text
          style={[
            styles.btnText,
            textStyle,
            secondary && styles.secondary,
            secondary && hovered && styles.secondaryTextHovered,
            textSize === 'big' && styles.btnTextBig,
            icon && styles.btnTextIcon,
          ]}
        >
          {children.join('')}
        </Text>
      );
    }

    return children;
  };

  return (
    <Pressable
      style={({ hovered, pressed }) => [
        styles.wrapper,
        disabled && styles.disabled,
        secondary && styles.secondary,
        hovered && styles.hovered,
        pressed && styles.pressed,
        color === 'red' && styles.red,
        icon && children ? styles.wrapperIcon : undefined,
        !children && icon ? styles.justIcon : undefined,
        style,
      ]}
      disabled={disabled || loading}
      onPress={disabled ? undefined : onPress}
    >
      {state => (
        <>
          {icon}
          {renderContent(state)}
          {loading && (
            <View style={styles.loadingWrapper}>
              <ActivityIndicator color={mixins.color.white} />
            </View>
          )}
        </>
      )}
    </Pressable>
  );
};

export default Button;
