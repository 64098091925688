import React, { useCallback, useContext, useMemo } from 'react';
import { Text, TextInput, View } from 'react-native';
import Button from '../../../components/Button/Button';
import styles, { webStyles } from './Source.styles';

import AppleStoreBadge from '../../../assets/badges/apple-store.svg';
import GooglePlayBadge from '../../../assets/badges/google-play.svg';
import MsWindowsIcon from '../../../assets/icons/ms-windows.svg';
import HelpIcon from '../../../assets/icons/help-circle-outline.svg';
import mixins from '../../../app/styles';
import { SocketContext } from '../../../socket/socket.provider';
import { ToasterContext } from '../../../toaster/toaster.provider';
import type { SourceType } from '../ActiveStream.utils';
import handleLink from '../../../helpers/handle-link';
import { copyToClipboard, useIsMobile } from '../../../utils';
import PressableWithOpacityHover from '../../../components/PressableWithOpacityHover';

interface Props {
  source: SourceType;
  handleSourceChange: (source: SourceType) => void;
}

const HELP_URL = 'https://docs.streamster.io/?onepage-docs=mobile-app#getting-started';
const DOWNLOAD_URL = 'https://streamster.io/download-now/';

const Source: React.FC<Props> = ({ source, handleSourceChange }) => {
  const isMobile = useIsMobile();
  const { serverData, sendPatch } = useContext(SocketContext);
  const { success } = useContext(ToasterContext);

  const handleDownload = () => {
    window.location.href = DOWNLOAD_URL;
  };

  const [externalSourceId, externalSource] = useMemo(
    () => Object.entries(serverData.Ingests).find(([_id, i]) => i.Owner === 'ext') || [],
    [serverData.Ingests],
  );

  const handleToClipboard = (value: string) => {
    copyToClipboard(value);
    success?.({ message: 'Value copied to clipboard' });
  };

  const resetExternalValues = useCallback(() => {
    if (!externalSourceId) {
      return;
    }
    sendPatch(['Ingests', externalSourceId, 'ResetCounter'], 1);
  }, [sendPatch, externalSourceId]);

  return (
    <View>
      <View style={[styles.sourceTabsRow, mixins.styles.margin.topI6]}>
        <Button
          style={[
            source !== 'EXTERNAL' ? styles.sourceTab : styles.sourceTabActive,
            isMobile && styles.sourceTabMobile,
          ]}
          secondary={source !== 'EXTERNAL'}
          onPress={() => handleSourceChange('EXTERNAL')}
        >
          External encoder
        </Button>
        <Button
          style={[
            source !== 'APP' ? styles.sourceTab : styles.sourceTabActive,
            isMobile && styles.sourceTabMobile,
          ]}
          secondary={source !== 'APP'}
          onPress={() => handleSourceChange('APP')}
        >
          Streamster App
        </Button>
        <Button
          style={[
            source !== 'MOBILE' ? styles.sourceTab : styles.sourceTabActive,
            isMobile && styles.sourceTabMobile,
          ]}
          secondary={source !== 'MOBILE'}
          onPress={() => handleSourceChange('MOBILE')}
        >
          Android/iOS
        </Button>
      </View>
      <View style={styles.sourceWrapper}>
        {source === 'EXTERNAL' && (
          <>
            <View style={styles.row}>
              <Text style={styles.label}>Stream URL</Text>
              <TextInput
                style={[styles.input, isMobile && mixins.styles.margin.bottomI4]}
                value={externalSource?.Data.Output}
              />
              <Button
                secondary
                onPress={() => handleToClipboard(externalSource?.Data.Output || '')}
              >
                Copy
              </Button>
            </View>
            <View style={[styles.row, isMobile && mixins.styles.margin.topI8]}>
              <Text style={styles.label}>Stream key</Text>
              <TextInput
                style={[styles.input, isMobile && mixins.styles.margin.bottomI4]}
                value={externalSource?.Data.Options}
              />
              <Button
                secondary
                onPress={() => handleToClipboard(externalSource?.Data.Options || '')}
              >
                Copy
              </Button>
              <Button secondary style={mixins.styles.margin.leftI6} onPress={resetExternalValues}>
                Reset
              </Button>
            </View>
            <PressableWithOpacityHover
              onPress={() =>
                handleLink(
                  'https://docs.streamster.io/docs/in-browser-app/getting-started-5818/starting-stream-with-external-encoder-e-g-obs-6209/',
                  '_blank',
                )
              }
            >
              <View style={styles.helpRow}>
                <HelpIcon fill={mixins.color.blue} width={20} />
                <Text style={styles.helpText}>
                  Get help on how to configure OBS or other external encoder
                </Text>
              </View>
            </PressableWithOpacityHover>
          </>
        )}
        {source === 'APP' && (
          <>
            <Text style={styles.grayText}>
              Get the best streaming experience with native Windows application designed
              specifically for restreaming. Download, launch, login and stream.
            </Text>
            <Button
              secondary
              style={[styles.downloadButton, mixins.styles.margin.topI6]}
              onPress={handleDownload}
              icon={
                <MsWindowsIcon
                  fill={mixins.color.white}
                  height={20}
                />
              }
            >
              Download Streamster for Windows
            </Button>
            <PressableWithOpacityHover
              onPress={() =>
                handleLink(
                  'https://docs.streamster.io/?onepage-docs=desktop-app#getting-started',
                  '_blank',
                )
              }
            >
              <View style={styles.helpRow}>
                <HelpIcon fill={mixins.color.blue} width={20} />
                <Text style={styles.helpText}>Get help on how to configure Streamster</Text>
              </View>
            </PressableWithOpacityHover>
          </>
        )}
        {source === 'MOBILE' && (
          <>
            <Text style={styles.grayText}>
              Stream from Mobile. You can also control stream from Streamster Desktop App. Download
              mobile version here.
            </Text>
            <View style={[styles.row, mixins.styles.margin.topI6]}>
              <a
                href="https://play.google.com/store/apps/details?id=io.streamster"
                target="_blank"
                style={webStyles.downloadButtons}
              >
                <GooglePlayBadge height={50} />
              </a>
              <a
                href="https://apps.apple.com/md/app/streamster/id1578293910"
                target="_blank"
                style={webStyles.downloadButtons}
              >
                <AppleStoreBadge height={50} />
              </a>
            </View>
            <PressableWithOpacityHover onPress={() => handleLink(HELP_URL, '_blank')}>
              <View style={styles.helpRow}>
                <HelpIcon fill={mixins.color.blue} width={20} />
                <Text style={styles.helpText}>
                  Get help on how to configure Streamster Mobile App
                </Text>
              </View>
            </PressableWithOpacityHover>
          </>
        )}
      </View>
    </View>
  );
};

export default Source;
