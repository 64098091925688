import React from 'react';
import { View, Text } from 'react-native';
import Loader from '../../components/Loader/Loader';
import styles from './Statistics.styles';
import { AreaChart, Area, ResponsiveContainer, Tooltip, XAxis, YAxis, Label } from 'recharts';

import dayjs from 'dayjs';
import mixins from '../../app/styles';
import Pagination from '../../components/Pagination/Pagination';
import Heading from '../../components/Heading/Heading';
import CustomChartTooltip from '../../components/CustomChartTooltip/CustomChartTooltip';
import { formatPrice, useIsWebMobile } from '../../utils';
import { useAppNavigation } from '#/navigation/navigation.ref';
import { TransactionsResponse, UsageData } from '#/api/types';

interface Props {
  dailyStats?: UsageData[];
  monthlyStats?: UsageData[];
  hideTransactions?: boolean;
  transactionsData?: TransactionsResponse;
  onTransactionsPageChange?: (page: number) => void;
  transactionsPage?: number;
}

export const StatisticsBox: React.FC<Props> = ({
  dailyStats,
  transactionsData,
  monthlyStats,
  hideTransactions,
  onTransactionsPageChange = () => {},
  transactionsPage = 1,
}) => {
  const isWebMobile = useIsWebMobile();
  const navigation = useAppNavigation();

  const dailyData = (dailyStats || []).map(d => ({
    name: dayjs(d.time).format('DD/MM'),
    usage: d.value / 60,
  }));

  const monthlyData = (monthlyStats || []).map(d => ({
    name: dayjs(d.time).format('MMM YYYY'),
    usage: d.value / 60,
  }));

  return (
    <>
      <View style={styles.row}>
        <View style={[styles.columnHalf, isWebMobile && styles.columnWebMobile]}>
          <Heading style={mixins.styles.margin.topI5}>Daily</Heading>
          <View style={styles.columnContent}>
            {!dailyStats && <Loader />}
            {dailyStats && (
              <ResponsiveContainer width="100%" height={300}>
                <AreaChart
                  width={200}
                  height={60}
                  data={dailyData}
                  margin={{
                    top: 5,
                    right: 10,
                    left: 5,
                    bottom: 5,
                  }}
                >
                  <XAxis dataKey="name" style={{ fill: mixins.color.grayMedium }} offset={5} />
                  <YAxis
                    style={{ fill: mixins.color.grayMedium }}
                    tickFormatter={v => v.toFixed(2)}
                  >
                    <Label
                      value="Usage [h]"
                      fill={mixins.color.grayMedium}
                      position="insideLeft"
                      angle={-90}
                    />
                  </YAxis>
                  <Tooltip content={CustomChartTooltip} />
                  <Area
                    unit="h"
                    type="monotone"
                    dataKey="usage"
                    fillOpacity={0.1}
                    strokeWidth={3}
                    stroke={mixins.color.green}
                    fill={mixins.color.green}
                  />
                </AreaChart>
              </ResponsiveContainer>
            )}
          </View>
        </View>
        <View style={[styles.columnHalf, isWebMobile && styles.columnWebMobile]}>
          <Heading style={mixins.styles.margin.topI5}>Monthly</Heading>
          <View style={styles.columnContent}>
            {!monthlyStats && <Loader />}
            {monthlyStats && (
              <ResponsiveContainer width="100%" height={300}>
                <AreaChart
                  width={200}
                  height={60}
                  data={monthlyData}
                  margin={{
                    top: 5,
                    right: 10,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <XAxis dataKey="name" style={{ fill: mixins.color.grayMedium }} offset={5} />
                  <YAxis
                    style={{ fill: mixins.color.grayMedium }}
                    tickFormatter={v => v.toFixed(2)}
                  >
                    <Label
                      value="Usage [h]"
                      fill={mixins.color.grayMedium}
                      position="insideLeft"
                      angle={-90}
                      offset={-10}
                    />
                  </YAxis>
                  <Tooltip content={CustomChartTooltip} />
                  <Area
                    unit="h"
                    type="monotone"
                    dataKey="usage"
                    fillOpacity={0.1}
                    strokeWidth={3}
                    stroke={mixins.color.green}
                    fill={mixins.color.green}
                  />
                </AreaChart>
              </ResponsiveContainer>
            )}
          </View>
        </View>
      </View>
      {!hideTransactions && (
        <View style={[styles.column, isWebMobile && styles.columnWebMobile]}>
          <Heading style={mixins.styles.margin.topI5}>Transactions</Heading>
          <View style={styles.columnContent}>
            <View style={[styles.transactionRow, styles.transactionHeadingRow]}>
              <Text style={styles.columnDate}>Date</Text>
              <Text style={styles.columnDescription}>Description</Text>
              <Text style={styles.columnValue}>Value</Text>
              <Text style={styles.columnBalance}>Balance</Text>
            </View>
            {!transactionsData && <Loader />}
            {transactionsData &&
              navigation.isFocused() &&
              transactionsData.transactions.map(t => (
                <View style={styles.transactionRow} key={t.id}>
                  <Text style={styles.columnDate}>
                    {dayjs(t.createdAt).format('DD MMMM, YYYY HH:mm:ss')}
                  </Text>
                  <Text style={styles.columnDescription}>{t.description}</Text>
                  <Text style={styles.columnValue}>{formatPrice(t.value)}</Text>
                  <Text style={styles.columnBalance}>{formatPrice(t.balance)}</Text>
                </View>
              ))}
          </View>
          <View style={styles.paginationWrapper}>
            <Pagination
              currentPage={transactionsPage}
              totalPages={transactionsData?.pagination.pageCount || 1}
              handlePageChange={page => onTransactionsPageChange(page)}
            />
          </View>
        </View>
      )}
    </>
  );
};
