import React, { useContext, useState } from 'react';
import { TextInput, Image, Text, View, Platform, TouchableOpacity } from 'react-native';
import Button from '../../components/Button/Button';
import DefaultModal from '../../components/DefaultModal/DefaultModal';
import handleLink from '../../helpers/handle-link';
import { validateRMTPUrl, validateUrl } from '../../helpers/validators';
import {
  AutoLoginState,
  PlatformInfoFlags,
  TargetMode,
  type Channel,
} from '../../socket/document.type';
import styles from './EditChannelModal.styles';
import { SocketContext } from '#/socket/socket.provider';
import { ButtonSwitch } from '#/components/ButtonSwitch/ButtonSwitch';
import AlertCircle from '#/assets/icons/alert-circle.svg';
import CheckCircle from '#/assets/icons/check-circle.svg';
import { AuthStateToText, isErrorAuthState } from '#/utils/autologin';
import mixins from '#/app/styles';

interface Props {
  isAddVariant?: boolean;
  channel: Partial<Channel>;
  handleDismiss: () => void;
  handleSave: (channel: Partial<Channel>) => void;
}

const EditChannelModal: React.FC<Props> = ({
  channel,
  handleDismiss,
  handleSave,
  isAddVariant = false,
}) => {
  const { serverData, sendPatch } = useContext(SocketContext);
  const [data, setData] = useState({ ...channel });

  const platform = serverData.Platforms.PlatformInfos.find(s => s.TargetId == channel.TargetId);

  const supportsGetKey = platform != null && (platform.Flags & PlatformInfoFlags.GetKey) > 0;
  const supportsChats = platform != null && (platform.Flags & PlatformInfoFlags.Chats) > 0;

  const isRMTPvalid = validateRMTPUrl(data.RtmpUrl);
  const isWebUrlValid = !data.WebUrl || validateUrl(data.WebUrl);
  const isManualMode =
    (supportsGetKey && (data.TargetMode === TargetMode.ManualKey || !data.TargetMode)) ||
    !supportsGetKey;

  const displayLogoutButton = channel.AutoLoginState !== AutoLoginState.NotAuthenticated;
  const displayLoginButton = channel.AutoLoginState === AutoLoginState.NotAuthenticated;

  const handleChange = (field: keyof typeof data) => (value: string | number) => {
    setData(d => ({ ...d, [field]: value }));
  };

  const handleSaveInternal = () => {
    handleSave(data);
  };

  const handleLogoutClicked = () => {
    sendPatch({
      path: ['Platforms', 'Platforms', data.TargetId!, 'ResetCounter'],
      value: Math.round(1000 * Math.random()),
    });
  };

  return (
    <DefaultModal
      title={`${isAddVariant ? 'Add' : 'Edit'} ${data.Name}`}
      onDismiss={handleDismiss}
      onConfirm={handleSaveInternal}
      confirmText={isAddVariant ? 'Add channel' : 'Save'}
      dismissText="Cancel"
      icon={
        <Image
          style={styles.channelImage}
          source={{ uri: `https://de10.streamster.io:6001/Files/targets/${channel.TargetId}.png` }}
        />
      }
    >
      <View style={styles.rowWithMargin}>
        <Text style={styles.label}>Name</Text>
        <View style={styles.inputGroup}>
          <TextInput
            maxLength={1024}
            style={[styles.input, styles.inputWide]}
            value={data.Name}
            onChangeText={handleChange('Name')}
          />
          <Text style={styles.tip}>Give any short name or leave default</Text>
        </View>
      </View>
      <View style={styles.rowWithMargin}>
        <Text style={styles.label}>Web shortcut</Text>
        <View style={styles.inputGroup}>
          <View style={styles.row}>
            <TextInput
              maxLength={1024}
              style={[styles.input, styles.inputWide, !isWebUrlValid && styles.inputError]}
              value={data.WebUrl}
              onChangeText={handleChange('WebUrl')}
            />
            <Button
              disabled={!validateUrl(data.WebUrl)}
              style={styles.testButton}
              textStyle={styles.testButtonText}
              onPress={() =>
                Platform.OS === 'web'
                  ? window.open(data.WebUrl, '_blank')
                  : handleLink(data.WebUrl || '')
              }
            >
              Test
            </Button>
          </View>
          <Text style={isWebUrlValid ? styles.tip : styles.tipError}>
            Configure link to your account for quick access from the main page
          </Text>
        </View>
      </View>
      <View style={styles.rowWithMargin}>
        <Text style={styles.label}>Target</Text>
        <View style={styles.inputGroup}>
          {channel.AutoLoginState !== undefined && supportsGetKey && (
            <>
              <Text style={styles.explanationText}>
                Streamster supports a deep integration with the channel. You can log in under your
                account to the platform and the application will configure RTMP settings
                automatically. You also will get an access to CHATS from inside the application.
              </Text>
              <View style={styles.buttonRow}>
                <ButtonSwitch
                  selected={!isManualMode}
                  onPress={() => handleChange('TargetMode')(TargetMode.AutoLogin)}
                >
                  {`Auto config ${supportsChats ? ' + chats' : ''}`}
                </ButtonSwitch>
                <ButtonSwitch
                  selected={isManualMode}
                  onPress={() => handleChange('TargetMode')(TargetMode.ManualKey)}
                >
                  Manual
                </ButtonSwitch>
              </View>
            </>
          )}
          {!isManualMode && channel.AutoLoginState !== undefined && (
            <>
              {channel.AutoLoginState !== AutoLoginState.NotAuthenticated && (
                <View style={styles.statusRow}>
                  {isErrorAuthState(channel.AutoLoginState) ? (
                    <AlertCircle height={20} width={20} fill={mixins.color.red} />
                  ) : (
                    <CheckCircle height={20} width={20} fill={mixins.color.green} />
                  )}
                  <Text
                    style={{
                      color: isErrorAuthState(channel.AutoLoginState)
                        ? mixins.color.red
                        : mixins.color.green,
                    }}
                  >
                    {AuthStateToText[channel.AutoLoginState]}
                  </Text>
                  {displayLogoutButton && (
                    <Button secondary onPress={handleLogoutClicked}>
                      Log out
                    </Button>
                  )}
                </View>
              )}
              {displayLoginButton && (
                <Text style={[styles.explanationText, mixins.styles.margin.topI3]}>
                  <a
                    href="https://docs.streamster.io/docs/in-browser-app/features-5820/chats-9270/"
                    target="_blank"
                  >
                    Use Streamster desktop or mobile app to authenticate
                  </a>
                </Text>
              )}
            </>
          )}
          {isManualMode && (
            <>
              <View style={styles.rowWithMargin}>
                <Text style={[styles.label, styles.labelSmall]}>RTMP Url</Text>
                <View style={styles.inputGroup}>
                  <TextInput
                    maxLength={1024}
                    style={[styles.input, styles.inputWide, !isRMTPvalid && styles.inputError]}
                    value={data.RtmpUrl}
                    onChangeText={handleChange('RtmpUrl')}
                  />

                  <Text style={isRMTPvalid ? styles.tip : styles.tipError}>
                    {data.RtmpUrl
                      ? isRMTPvalid
                        ? 'Provide none-empty rtmp url'
                        : 'Provide rtmp url in correct format like "rtmp://host.com/app"'
                      : 'Provide none-empty rtmp url'}
                  </Text>
                </View>
              </View>
              <View style={styles.rowWithMargin}>
                <Text style={[styles.label, styles.labelSmall]}>Stream Key</Text>
                <View style={styles.inputGroup}>
                  <TextInput
                    maxLength={1024}
                    style={[styles.input, styles.inputWide, !data.Key && styles.inputError]}
                    value={data.Key}
                    onChangeText={handleChange('Key')}
                  />
                  <Text style={data.Key ? styles.tip : styles.tipError}>
                    Provide key or stream name
                  </Text>
                </View>
              </View>
              <TouchableOpacity
                onPress={() =>
                  handleLink(`https://streamster.io/manuals-webapp/${channel.TargetId}`, '_blank')
                }
              >
                <Text style={styles.helpText}>
                  You are not sure how to get the values? Check out our help
                </Text>
              </TouchableOpacity>
              {channel.AutoLoginState === undefined && supportsGetKey && (
                <>
                  <Text style={styles.whiteText}>
                    Streamster supports a deep integration with the channel. You can log in under
                    your account to the platform and the application will configure RTMP settings
                    automatically. You also will get an access to CHATS from inside the application.
                  </Text>

                  <TouchableOpacity
                    onPress={() =>
                      handleLink(`https://docs.streamster.io/docs/add-ons/multichat-8811/getting-started-with-obs-studio-8817/#authenticate-with-streamster-desktop-app`, '_blank')
                    }
                  >
                    <Text style={styles.helpText}>
                      Use Streamster desktop or mobile app to authenticate
                    </Text>
                  </TouchableOpacity>
                </>
              )}
            </>
          )}
        </View>
      </View>
    </DefaultModal>
  );
};

export default EditChannelModal;
