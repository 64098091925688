import {
  ApiUserMonitoringChannel,
  ApiUserMonitoringIngest,
  UserMonitoringChannelState,
} from '#/api/types';
import mixins from '#/app/styles';
import { View, Text, StyleSheet } from 'react-native';
import ArrowUpIcon from '#/assets/icons/arrow-up.svg';
import ArrowTopRightIcon from '#/assets/icons/arrow-top-right.svg';
import Tooltip from '#/components/Tooltip';
import { renderTooltipRow, tooltipTitleStyle } from '#/components/BusinessUserGrid/tooltipUtils';

const StateToColor = {
  [UserMonitoringChannelState.Ok]: mixins.color.green,
  [UserMonitoringChannelState.Off]: mixins.color.grayDarker,
  [UserMonitoringChannelState.Error]: mixins.color.redDark,
  [UserMonitoringChannelState.Warning]: mixins.color.orange,
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  wrapper: {
    flexShrink: 1,
  },
});

const StateToText = {
  [UserMonitoringChannelState.Error]: 'Error',
  [UserMonitoringChannelState.Off]: 'Off',
  [UserMonitoringChannelState.Ok]: 'Ok',
  [UserMonitoringChannelState.Warning]: 'Warning',
};

const TypeToText = {
  win: 'Windows app',
  ios: 'iOS app',
  android: 'Android app',
  ext: 'External',

};

export const IngestItem = ({
  value,
}: {
  value: ApiUserMonitoringChannel | ApiUserMonitoringIngest;
}) => {
  const color = StateToColor[value.state];

  const Icon = 'name' in value ? ArrowTopRightIcon : ArrowUpIcon;

  return (
    <Tooltip
      text={
        'name' in value ? (
          <>
            <Text style={tooltipTitleStyle}>Restream channel</Text>
            {renderTooltipRow('Target', value.name)}
            {renderTooltipRow('Bitrate', value.bitrate)}
            {renderTooltipRow('State', StateToText[value.state])}
          </>
        ) : (
          <>
            <Text style={tooltipTitleStyle}>Stream to cloud</Text>
            {renderTooltipRow('Source', TypeToText[value.type] || value.type)}
            {renderTooltipRow('Bitrate', value.bitrate)}
          </>
        )
      }
    >
      <View style={styles.wrapper}>
        <View style={styles.row}>
          <Icon
            fill={color}
            width={20}
            style={'type' in value ? mixins.styles.margin.rightI3 : undefined}
          />
          {'type' in value && <Text style={{ color }}>{value.bitrate} kbps</Text>}
        </View>
      </View>
    </Tooltip>
  );
};
