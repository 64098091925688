import Loader from '#/components/Loader/Loader';
import {
  useBusinessTariffsQuery,
  useBusinessUserQuery,
  useChangeBusinessTariffRequest,
  useChangeTariffRequest,
  useChangeTariffWithCryptoRequest,
  useCustomTopUpFunnelQuery,
  useDepositsQuery,
  useMeQuery,
  useMyTariffQuery,
  useTariffsQuery,
} from '#/api/api.portal';
import mixins from '#/app/styles';
import Heading from '#/components/Heading/Heading';
import PageWrapper from '#/components/PageWrapper/PageWrapper';
import { useSelectedBusinessUserStore } from '#/screens/home/selectedBusinessUser.store';
import { BusinessUserSwitcher } from '#/components/BusinessUserSwitcher/BusinessUserSwitcher';
import { BusinessTariff, Me, Tariff } from '#/api/types';
import { TariffsWrapper } from '#/screens/tariff/Tariff';
import { useContext, useMemo } from 'react';
import { prepareTariffsForPaykickstartModal } from '#/screens/tariff/Tariff.utils';
import { useAppNavigation } from '#/navigation/navigation.ref';
import { StackParamList } from '#/navigation/navigation.types';
import { useQueryClient } from '@tanstack/react-query';
import { getTariffsForUser } from '#/hooks/use-business-users';
import { AuthContext } from '#/screens/auth/auth.utils';

export const TariffScreen = () => {
  const { data: profile } = useMeQuery();
  const { selectedUserId } = useSelectedBusinessUserStore();

  if (!profile) {
    return <Loader />;
  }

  const isBusiness = profile.business;
  const isMyUserSelected = selectedUserId === profile.userId || !selectedUserId;

  return (
    <PageWrapper>
      {selectedUserId ? (
        <BusinessUserSwitcher />
      ) : (
        <Heading style={mixins.styles.margin.topI8}>Pricing plans</Heading>
      )}
      {isBusiness && !isMyUserSelected ? (
        <TariffBusinessContainer selectedUserId={selectedUserId} />
      ) : (
        <TariffContainer profile={profile} />
      )}
    </PageWrapper>
  );
};

const TariffBusinessContainer = ({ selectedUserId }: { selectedUserId: number }) => {
  const { data: tariffs = [] } = useBusinessTariffsQuery();
  const { data: user } = useBusinessUserQuery(selectedUserId);
  const changeTariffMutation = useChangeBusinessTariffRequest();
  const queryClient = useQueryClient();

  if (!user || !tariffs) {
    return <Loader />;
  }

  const tariff = {
    name: user.tariffName || '',
    id: user.tariffId,
    validTo: user.tariffExpiresAt === null ? undefined : user.tariffExpiresAt,
  };

  const handleChangeTariff = async (tariff: BusinessTariff) => {
    await changeTariffMutation.mutateAsync({ userId: selectedUserId, tariffId: tariff.id });
    queryClient.refetchQueries([`business/users/${selectedUserId}`]);
  };

  return (
    <TariffsWrapper
      tariffs={getTariffsForUser(tariffs, user)}
      onChangeTariff={handleChangeTariff}
      myTariff={tariff}
    />
  );
};

const TariffContainer = ({ profile }: { profile: Me }) => {
  const { webAuth } = useContext(AuthContext);
  const { data: funnel } = useCustomTopUpFunnelQuery();
  const { data: tariffs } = useTariffsQuery();
  const { data: deposits } = useDepositsQuery();
  const { data: myTariff, refetch: refetchMyTariff } = useMyTariffQuery();

  const [changeTariff] = useChangeTariffRequest();
  const { mutateAsync: changeTariffWithCrypto } = useChangeTariffWithCryptoRequest();
  const navigation = useAppNavigation();

  const tariffsWithPk = useMemo(
    () => prepareTariffsForPaykickstartModal(profile, tariffs).sort((a, z) => a.order - z.order),
    [profile, tariffs],
  );

  const handleChangeTariff = async (tariff: Tariff, method: 'CRYPTO' | 'CREDIT') => {
    if (!tariff) {
      return;
    }

    if (method === 'CREDIT') {
      await changeTariff(tariff.id);
    } else {
      const { data } = await changeTariffWithCrypto(tariff.id);

      location.href = data.redirect;

      return;
    }

    if (webAuth?.admin) {
      refetchMyTariff();
    } else {
      navigation.reset({
        routes: [
          {
            name: 'ThanksForSubscribing',
            params: {
              type: tariff.serverMonthly > 0 ? 'tariff-monthly' : 'tariff-per-minute',
              paymentMethod: 'balance',
              isProcessed: true,
            } as StackParamList['ThanksForSubscribing'],
          },
        ],
      });
    }
  };

  if (!tariffs || !myTariff) {
    return <Loader />;
  }

  return (
    <TariffsWrapper
      customTopUpFunnelId={funnel?.funnelId}
      tariffs={tariffsWithPk}
      myTariff={myTariff}
      deposits={deposits}
      me={profile}
      onChangeTariff={handleChangeTariff}
    />
  );
};
