import React, { useContext, useState } from 'react';

import DefaultModal from '../DefaultModal/DefaultModal';
import { ToasterContext } from '../../toaster/toaster.provider';
import { InputWithLabel } from '#/components/InputWIthLabel/InputWIthLabel';

interface Props {
  username?: string;
  onDismiss: () => void;
  onNewPassword: (password: string) => void;
}

export const ChangePasswordModal: React.FC<Props> = ({ username, onDismiss, onNewPassword }) => {
  const [formData, setFormData] = useState({ password: '', confirmPassword: '' });
  const { error } = useContext(ToasterContext);

  const handleChange = (field: keyof typeof formData) => (value: string) => {
    setFormData(data => ({ ...data, [field]: value }));
  };

  const handlePasswordChange = async () => {
    if (formData.password !== formData.confirmPassword) {
      return error({ message: "Passwords don't match" });
    }

    onNewPassword(formData.password);
  };

  return (
    <DefaultModal
      title="Change password"
      onDismiss={onDismiss}
      confirmText="Change password"
      onConfirm={handlePasswordChange}
      dismissText="Cancel"
    >
      <InputWithLabel name="Username" value={username} withMargin />
      <InputWithLabel
        name="Password"
        value={formData.password}
        onChange={handleChange('password')}
        withMargin
        secure
      />
      <InputWithLabel
        name="Confirm password"
        value={formData.confirmPassword}
        onChange={handleChange('confirmPassword')}
        withMargin
        tip={formData.password !== formData.confirmPassword ? "Passwords don't match" : undefined}
        secure
        tipError
      />
    </DefaultModal>
  );
};
