import { useContext, useEffect, useRef } from 'react';
import { AuthContext } from '../../screens/auth/auth.utils';
import { useChaportIdQuery, useStoreChaportIdRequest } from '../../api/api.portal';

const UpdateChaportUserDetails = () => {
  const loginRef = useRef('');
  const { isAuth, username } = useContext(AuthContext);
  const { data: chaportConf } = useChaportIdQuery({ skip: !isAuth || !username });
  const [storeChaportId] = useStoreChaportIdRequest();

  useEffect(() => {
    if (!isAuth || !username || !chaportConf) {
      return;
    }

    const register = () => {
      (window as any).chaport.on('ready', data => {
        if (data.visitorId) {
          storeChaportId({ id: data.visitorId }).catch(() =>
            console.log('Could not store chaport id'),
          );
        }
      });
    };

    const run = () =>
      (function (w: any, d: any, v3?: any) {
        w.chaportConfig = {
          appId: '62cc3eb80045b0f704ea95d6',
        };

        if (chaportConf.id) {
          w.chaportConfig.session = {
            user: {
              visitorId: chaportConf.id,
              token: chaportConf.hash,
            },
          };
        }

        if (w.chaport) return;
        v3 = w.chaport = {};
        v3._q = [];
        v3._l = {};
        v3.q = function () {
          v3._q.push(arguments);
        };
        v3.on = function (e, fn) {
          if (!v3._l[e]) v3._l[e] = [];
          v3._l[e].push(fn);
        };
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://app.chaport.com/javascripts/insert.js';
        s.onload = register;
        // eslint-disable-next-line prefer-destructuring
        var ss = d.getElementsByTagName('script')[0];
        ss.parentNode.insertBefore(s, ss);
      })(window, document);

    run();

    setTimeout(() => {
      const fn = (window as any)?.chaport?.setVisitorData;

      if (isAuth && loginRef.current !== username && typeof fn === 'function') {
        loginRef.current = username || '';
        fn({
          name: username,
        });
      }
    }, 1000);
  }, [isAuth, username, chaportConf, storeChaportId]);

  return null;
};

export default UpdateChaportUserDetails;
