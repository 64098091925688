export const retryIf = async <T extends any>({
  action,
  tries = 3,
  shouldRetry,
}: {
  action: () => PromiseLike<T>;
  tries?: number;
  shouldRetry?: (error: unknown) => boolean;
}): Promise<T> => {
  let triesLeft = tries;

  while (triesLeft > 0) {
    triesLeft--;

    try {
      const result = await action();

      return result;
    } catch (err) {
      if (triesLeft === 0) {
        throw err;
      }

      if (!shouldRetry || shouldRetry(err)) {
        await new Promise(resolve => setTimeout(resolve, (tries - triesLeft) * 2000));
      }
    }
  }

  throw new Error('Unable to finish');
};
