import { Target } from '#/socket/document.type';
import { getInitialPlatformChat } from '#/store/chatMessages/chatMessagesStore';
import { ChatsAuthor, ChatsMessage, ChatsData } from '#/store/chatMessages/chatMessagesStoreTypes';

export const getShouldDisplayAuthor = ({
  message,
  author,
  previousMessage,
}: {
  author: ChatsAuthor;
  message: ChatsMessage;
  previousMessage: ChatsMessage;
}) => {
  if (!author) {
    return false;
  }

  const isPrevMessageFromTheSameAuthor =
    previousMessage && previousMessage.AuthorId === message.AuthorId;

  if (author.Self) {
    if (!isPrevMessageFromTheSameAuthor && !message.target && !message.ToAuthorId) {
      return false;
    }

    if (!isPrevMessageFromTheSameAuthor && message.target) {
      return true;
    }

    if (previousMessage?.ToAuthorId !== message.ToAuthorId && message.Private) {
      return true;
    }

    return false;
  }

  if (isPrevMessageFromTheSameAuthor && message.Private === previousMessage.Private) {
    return false;
  }

  return true;
};

export const renderAuthor = ({
  author,
  authors,
  message,
  target,
}: {
  author: ChatsAuthor;
  message: ChatsMessage;
  target: Target;
  authors: Record<string, ChatsAuthor>;
}) => {
  let name = '';

  if (author) {
    if (author.target) {
      name = author.Self ? target.Name : `${author.Name} | ${target.Name}`;
    } else {
      name = author.Self ? '' : author.Name;
    }
  }

  if (message.Private) {
    if (author && author.Self && message.ToAuthorId) {
      name += ` private @ ${authors[message.ToAuthorId].Name}`;
    } else {
      name += ` @ private`;
    }
  }

  return name;
};

export const getChatData = (
  chatsData: ChatsData,
  authorId: string | undefined,
  targetId?: string,
): ChatsData[string] => {
  const defaultState = getInitialPlatformChat();

  if (targetId) {
    if (authorId && chatsData[targetId]) {
      return {
        ...chatsData[targetId],
        messages: chatsData[targetId].messages.filter(
          m => m.AuthorId === authorId || m.ToAuthorId === authorId,
        ),
      };
    }

    return chatsData[targetId] || defaultState;
  }

  Object.entries(chatsData).forEach(([target, chat]) => {
    defaultState.messages.push(...chat.messages.slice(-100).map(m => ({ ...m, target })));
    defaultState.unreadMessages += chat.unreadMessages;
    defaultState.unseenTips += chat.unseenTips;

    Object.values(chat.authors).forEach(author => {
      defaultState.authors[author.Id] = { ...author, target };
    });
  });

  defaultState.messages.sort((a, z) => a.Time.getTime() - z.Time.getTime());

  return defaultState;
};
