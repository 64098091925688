import { Platform, StyleSheet } from 'react-native';
import mixins from '../../app/styles';

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: mixins.color.blue,
    borderRadius: mixins.indent.i3,
    paddingVertical: mixins.indent.i3,
    paddingHorizontal: mixins.indent.i10,
    borderColor: mixins.color.blueLight,
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  wrapperIcon: {
    paddingLeft: mixins.indent.i5,
  },
  justIcon: {
    paddingHorizontal: mixins.indent.i3,
  },
  btnTextIcon: {
    marginLeft: mixins.indent.i5,
  },
  red: { backgroundColor: mixins.color.red, borderColor: mixins.color.red },
  btnText: {
    color: mixins.color.white,
    fontSize: Platform.OS === 'web' ? mixins.font.middleFont : mixins.font.defaultFont,
    fontWeight: '600',
    textAlign: 'center',
    userSelect: 'none',
  },
  btnTextBig: {
    fontSize: mixins.font.bigFont,
  },
  disabled: {
    opacity: 0.7,
  },
  pressed: {
    opacity: 0.9,
  },
  secondary: {
    backgroundColor: 'transparent',
  },
  hovered: {
    backgroundColor: mixins.color.blueBright,
    borderColor: mixins.color.blueBright,
  },
  secondaryText: {
    color: mixins.color.blue,
  },
  secondaryTextHovered: {},
  loadingWrapper: {
    position: 'absolute',
    backgroundColor: '#00000090',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styles;
