import { StyleSheet } from 'react-native';
import mixins from '../../../app/styles';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  label: {
    color: mixins.color.grayDarker,
    width: 80,
    marginRight: 15,
  },
  input: {
    color: mixins.color.whiteText,
    borderColor: mixins.color.grayDarkest,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    marginRight: 15,
    height: 40,
    width: 400,
  },
  sourceTabsRow: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  sourceTab: {
    borderRadius: 0,
    borderWidth: 0,
    paddingVertical: 15,
    borderBottomWidth: 4,
    borderBottomColor: 'transparent',
  },
  sourceTabMobile: {
    paddingHorizontal: 15,
  },
  sourceTabActive: {
    borderRadius: 0,
    paddingVertical: 15,
    borderWidth: 0,
    borderBottomWidth: 4,
    borderBottomColor: mixins.color.blue,
    backgroundColor: 'transparent',
  },
  grayText: {
    color: mixins.color.grayDarker,
    marginVertical: 10,
  },
  helpText: {
    color: mixins.color.blue,
    marginLeft: 10,
    cursor: 'pointer',
    fontSize: mixins.font.defaultFont,
  },
  sourceWrapper: {
    padding: 15,
    marginTop: 5,
  },
  helpRow: {
    marginTop: mixins.indent.i9,
    flexDirection: 'row',
    alignItems: 'center',
  },
  downloadButton: {
    alignSelf: 'flex-start',
    paddingHorizontal: mixins.indent.i6,
  },
});

export const webStyles = { downloadButtons: { marginBottom: 15, marginRight: 15 } };

export default styles;
