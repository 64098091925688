import { View, Text, StyleSheet, TextInput } from 'react-native';
import mixins from '../../app/styles';
import { ReactNode } from 'react';

const styles = StyleSheet.create({
  input: {
    backgroundColor: mixins.color.dark,
    borderBottomWidth: 2,
    borderColor: mixins.color.grayDarkest,
    margin: 2,
    paddingVertical: 5,
    flexGrow: 1,
    color: mixins.color.white,
  },
  inputWide: {
    flexGrow: 1,
    minWidth: 400,
  },
  inputError: {
    borderColor: mixins.color.redDark,
  },
  inputGroup: {
    flexGrow: 1,
  },
  row: {
    flexDirection: 'row',
  },
  rowWithMargin: {
    marginBottom: mixins.indent.i5,
    flexDirection: 'row',
  },
  label: {
    lineHeight: 40,
    width: 130,
    color: mixins.color.grey,
  },
  labelSmall: {
    width: 90,
  },
  tip: {
    fontSize: 12,
    color: mixins.color.grey,
  },
  tipError: {
    fontSize: 12,
    color: mixins.color.redDark,
  },
});

interface Props {
  onChange?: (text: string) => void;
  name: string;
  tip?: string;
  tipError?: boolean;
  value?: string;
  withMargin?: boolean;
  wide?: boolean;
  secure?: boolean;
  children?: ReactNode;
}

export const InputWithLabel = ({
  withMargin,
  onChange,
  name,
  tip,
  tipError,
  value,
  wide,
  secure,
  children,
}: Props) => {
  return (
    <View style={withMargin ? styles.rowWithMargin : styles.row}>
      <Text style={styles.label}>{name}</Text>
      <View style={styles.inputGroup}>
        {children ? (
          children
        ) : (
          <TextInput
            maxLength={1024}
            style={[styles.input, wide && styles.inputWide]}
            value={value}
            onChangeText={onChange}
            secureTextEntry={secure}
          />
        )}
        {tip && <Text style={tipError ? styles.tipError : styles.tip}>{tip}</Text>}
      </View>
    </View>
  );
};
