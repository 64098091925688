import Loader from '#/components/Loader/Loader';
import { useState } from 'react';
import {
  useBusinessUserTransactionsQuery,
  useBusinessUserUsageDailyQuery,
  useBusinessUserUsageMonthlyQuery,
  useMeQuery,
  useTransactionsQuery,
  useUsageDailyQuery,
  useUsageMonthlyQuery,
} from '#/api/api.portal';
import mixins from '#/app/styles';
import Heading from '#/components/Heading/Heading';
import PageWrapper from '#/components/PageWrapper/PageWrapper';
import { useSelectedBusinessUserStore } from '#/screens/home/selectedBusinessUser.store';
import { BusinessUserSwitcher } from '#/components/BusinessUserSwitcher/BusinessUserSwitcher';
import { StatisticsBox } from '#/screens/statistics/Statistics';

export const StatisticsScreen = () => {
  const { data: profile } = useMeQuery();
  const { selectedUserId } = useSelectedBusinessUserStore();

  if (!profile) {
    return <Loader />;
  }

  const isBusiness = profile.business;
  const isMyUserSelected = selectedUserId === profile.userId || !selectedUserId;

  return (
    <PageWrapper>
      {selectedUserId ? (
        <BusinessUserSwitcher />
      ) : (
        <Heading style={mixins.styles.margin.topI8}>Statistics</Heading>
      )}
      {isBusiness && !isMyUserSelected ? (
        <StatisticsBusinessContainer selectedUserId={selectedUserId} />
      ) : (
        <StatisticsContainer />
      )}
    </PageWrapper>
  );
};

const StatisticsBusinessContainer = ({ selectedUserId }: { selectedUserId: number }) => {
  const [page, setPage] = useState(1);
  const { data: dailyStats } = useBusinessUserUsageDailyQuery(selectedUserId);
  const { data: monthlyStats } = useBusinessUserUsageMonthlyQuery(selectedUserId);
  const { data: transactionsData } = useBusinessUserTransactionsQuery(selectedUserId, page);

  return (
    <StatisticsBox
      transactionsPage={page}
      onTransactionsPageChange={setPage}
      dailyStats={dailyStats}
      monthlyStats={monthlyStats}
      transactionsData={transactionsData}
    />
  );
};

const StatisticsContainer = () => {
  const [page, setPage] = useState(1);
  const { data: dailyStats } = useUsageDailyQuery();
  const { data: monthlyStats } = useUsageMonthlyQuery();
  const { data: transactionsData } = useTransactionsQuery(page);

  return (
    <StatisticsBox
      transactionsPage={page}
      onTransactionsPageChange={setPage}
      dailyStats={dailyStats}
      monthlyStats={monthlyStats}
      transactionsData={transactionsData}
    />
  );
};
