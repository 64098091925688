import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Text, StyleSheet, TouchableOpacity } from 'react-native';
import styles, { css } from './InfoTooltip.styles';
import InformationIcon from '../../assets/icons/information-outline.svg';
import mixins from '../../app/styles';

const InfoTooltip: React.FC<{
  text: string;
  onPress?: () => void;
  style?: any;
}> = ({ style, text, onPress }) => {
  const [isHovered, setIsHovered] = useState<{ top: number; left: number } | undefined>(undefined);

  return (
    <div
      style={css.wrapper}
      onMouseOver={e => {
        const viewportOffset = e.currentTarget.getBoundingClientRect();
        const { top, left } = viewportOffset;
        if (left + 200 < window.innerWidth) {
          setIsHovered({ top: top - 10, left });
        } else {
          setIsHovered({ top: top + 30, left: left - 200 });
        }
      }}
      onMouseLeave={() => {
        setIsHovered(undefined);
      }}
    >
      <TouchableOpacity onPress={onPress}>
        <InformationIcon style={style} fill={isHovered ? mixins.color.blue : mixins.color.white} />
      </TouchableOpacity>
      {isHovered &&
        ReactDOM.createPortal(
          <Text style={StyleSheet.compose(styles.tooltip, isHovered)}>{text}</Text>,
          document.querySelector('#root'),
        )}
    </div>
  );
};

export default InfoTooltip;
