import {
  useBusinessTransactionsQuery,
  useBusinessUsageDailyQuery,
  useBusinessUsageMonthlyQuery,
} from '#/api/api.portal';
import { StatisticsBox } from '#/screens/statistics/Statistics';
import { useState } from 'react';

export const BusinessUsersStatistics = () => {
  const [page, setPage] = useState(1);
  const { data: dailyStats } = useBusinessUsageDailyQuery();
  const { data: monthlyStats } = useBusinessUsageMonthlyQuery();
  const { data: transactionsData } = useBusinessTransactionsQuery(page);

  return (
    <StatisticsBox
      transactionsPage={page}
      onTransactionsPageChange={setPage}
      dailyStats={dailyStats}
      monthlyStats={monthlyStats}
      transactionsData={transactionsData}
    />
  );
};
