import React, { ReactNode, useEffect } from 'react';
import { Modal, Platform, Pressable, ScrollView, Text, View } from 'react-native';
import Button from '../Button/Button';

import CloseIcon from '../../assets/icons/close.svg';

import styles from './DefaultModal.styles';
import mixins from '../../app/styles';
import { useIsMobile } from '../../utils';

export interface Props {
  title?: string;
  onConfirm?: () => void;
  onDismiss?: () => void;
  confirmText?: string;
  dismissText?: string;
  icon?: ReactNode;
  rawContent?: boolean;
  children?: ReactNode;
  alignItemsCenter?: boolean;
  confirmLoading?: boolean;
  additionalButtons?: ReactNode;
}

const DefaultModal: React.FC<Props> = ({
  children,
  title,
  confirmText = 'Ok',
  dismissText,
  onConfirm,
  onDismiss,
  icon,
  rawContent = false,
  alignItemsCenter = false,
  additionalButtons,
  confirmLoading,
}) => {
  const isMobile = useIsMobile();
  const displayDismiss = onDismiss && dismissText;
  const displayConfirm = !!onConfirm;

  const tweakWebStyle = (ref: Modal | null) => {
    if (Platform.OS !== 'web' || !ref) {
      return;
    }

    const modalDiv = ref as unknown as HTMLDivElement;

    const height = document.querySelector('#layout header')?.clientHeight;

    modalDiv.style.top = `${height}px`;
    modalDiv.parentElement!.parentElement!.style.top = `${height}px`;
  };

  const onDismissWebHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (e.currentTarget === e.target) {
      onDismiss?.();
    }
  };

  useEffect(() => {
    if (Platform.OS === 'web' && onDismiss) {
      const handler = (event: KeyboardEvent) => {
        if (event.key.toLowerCase() === 'escape') {
          onDismiss();
        }
      };

      document.addEventListener('keydown', handler);

      return () => document.removeEventListener('keydown', handler);
    }
  }, [onDismiss]);

  return (
    <Modal transparent style={styles.modal} ref={tweakWebStyle}>
      <View style={styles.backdrop} onClick={onDismissWebHandler}>
        <View style={[styles.container, isMobile && styles.containerMobile]}>
          <View style={styles.header}>
            {icon && <View style={styles.iconWrapper}>{icon}</View>}
            {title && <Text style={styles.title}>{title}</Text>}
            {onDismiss && (
              <View style={styles.dismissIconWrapper}>
                <Pressable onPress={onDismiss}>
                  <CloseIcon width={25} height={25} fill={mixins.color.white} />
                </Pressable>
              </View>
            )}
          </View>
          {rawContent ? (
            <View style={alignItemsCenter ? styles.rawContentCentered : styles.rawContent}>
              {children}
            </View>
          ) : (
            <ScrollView
              style={styles.content}
              contentContainerStyle={alignItemsCenter ? styles.contentCentered : undefined}
            >
              {children}
            </ScrollView>
          )}
          {(displayConfirm || displayDismiss) && (
            <View style={styles.buttonsWrapper}>
              {additionalButtons}
              {onConfirm && (
                <Button loading={confirmLoading} onPress={onConfirm}>
                  {confirmText}
                </Button>
              )}
              {onDismiss && dismissText && (
                <Button onPress={onDismiss} secondary style={styles.dismissButton}>
                  {dismissText}
                </Button>
              )}
            </View>
          )}
        </View>
      </View>
    </Modal>
  );
};

export default DefaultModal;
