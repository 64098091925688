import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  channelList: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    padding: 10,
  },
  channelFlagWrapper: {
    flexDirection: 'row',
    padding: 10,
    backgroundColor: '#303030',
  },
  channelFlagButton: {
    borderRadius: 10,
    backgroundColor: '#535f65',
    borderWidth: 0,
    marginRight: 8,
    paddingLeft: 5,
    paddingRight: 15,
  },
  channelIcon: {
    width: 20,
    height: 20,
  },
});

export default styles;
