import React, { useContext } from 'react';
import { HomeScreenUser } from './HomeScreenUser';
import { HomeScreenBusiness } from './HomeScreenBusiness';
import PageWrapper from '#/components/PageWrapper/PageWrapper';
import { useMeQuery } from '#/api/api.portal';
import { AuthContext } from '#/screens/auth/auth.utils';
import { HomeScreenAdmin } from '#/screens/home/HomeScreenAdmin';

const HomeScreen: React.FC<{}> = () => {
  const { webAuth } = useContext(AuthContext);
  const { data: profile } = useMeQuery();

  if (!profile) {
    return null;
  }

  return (
    <PageWrapper>
      {webAuth?.admin ? (
        <HomeScreenAdmin />
      ) : profile?.business ? (
        <HomeScreenBusiness />
      ) : (
        <HomeScreenUser />
      )}
    </PageWrapper>
  );
};

export default HomeScreen;
