import { StyleSheet } from 'react-native';
import mixins from '../../app/styles';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  flex: {
    flex: 1,
  },

  // WEB

  webWrapper: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 15,
  },
  webInputContainer: {
    marginBottom: mixins.indent.i11 * 3,
    maxWidth: 400,
    width: '100%',
  },
  webHeader: {
    color: mixins.color.whiteText,
    fontSize: 30,
  },
  webSubHeader: {
    color: mixins.color.whiteText,
    fontSize: 20,
    textAlign: 'center',
  },
  box: {
    maxWidth: 600,
    width: '100%',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,.3)',
    marginBottom: 50,
    paddingBottom: 50,
  },
  goBackIcon: {
    height: 30,
    fill: mixins.color.white,
    alignSelf: 'flex-start',
    borderWidth: 2,
    borderRadius: 500,
    borderStyle: 'solid',
    borderColor: mixins.color.white,
    padding: 5,
    cursor: 'pointer',
  },
});

export default styles;
