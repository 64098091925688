import { StyleSheet } from 'react-native';
import mixins from '../../app/styles';

const styles = StyleSheet.create({
  section: {
    display: 'flex',
    color: mixins.color.input,
    fontSize: mixins.font.sectionFont,
    marginBottom: mixins.indent.i5,
  },
  page: {
    display: 'flex',
    color: mixins.color.input,
    fontSize: mixins.font.titleFont,
    marginBottom: mixins.indent.i5,
    justifyContent: 'center',
  },
  alert: {
    display: 'flex',
    color: mixins.color.input,
    fontSize: mixins.font.bigFont,
    marginBottom: mixins.indent.i5,
    justifyContent: 'center',
    textAlign: 'center',
  },
});

export default styles;
