import { createZustandStore } from '#/utils/zustand';

interface Store {
  selectedOwnerId: number | null;
  selectedUserId: number | null;
  selectedUserLogin: string;
  selectUser: (id: number | string | null, login?: string) => void;
}

export const useSelectedBusinessUserStore = createZustandStore(
  'SelectedBusinessUser',
  {
    selectedOwnerId: null,
    selectedUserId: null,
    selectedUserLogin: '',
  } as Store,
  (set, get) => ({
    selectUserAsAdmin: (ownerId: number, id: number, login?: string) => {
      set(store => {
        store.selectedOwnerId = ownerId;
      });
      get().selectUser(id, login);
    },
    selectUser: (id: number | string | null, login?: string) =>
      set(store => {
        store.selectedUserId = id ? Number(id) : null;
        store.selectedUserLogin = login || '';
      }),
  }),
);
