import type http from 'http';
import { normalizeApiData } from '../api/api-helper';
import { API_BALANCER_URLS, API_LOGIN_URLS } from '../api/api.const';
import { ApiError, ValidationError, PermissionError } from '../error/error';
import NetInfo, { NetInfoState } from '@react-native-community/netinfo';

import type { Response, Options } from './api.types';

const getErrorText = (data: any): string => {
  if (Array.isArray(data)) {
    return data.map(error => error.msg).join('\n');
  }

  if ('error' in data) {
    return data.error;
  }

  return 'Something went wrong...';
};

export function isValid<T>(url: string, response: Response<T>): Response<T> | never {
  if (response.code === 401) {
    throw new PermissionError(`Access denied [${response.code}] to ${url}`);
  }
  if (!response.status) {
    throw new ValidationError(getErrorText(response.data), {
      code: response.code,
      data: response.data,
    });
  }
  return response;
}

const FETCH_TIMEOUT = 5000;

export const waitForNetwork = async () => {
  const hasNetwork = await NetInfo.fetch();

  if (!hasNetwork.isInternetReachable) {
    await new Promise(resolve => {
      let unsubFnRef: { ref: (() => void) | undefined } = { ref: undefined };

      const handler = (state: NetInfoState) => {
        if (state.isInternetReachable) {
          unsubFnRef.ref?.();
          resolve(undefined);
        }
      };

      unsubFnRef.ref = NetInfo.addEventListener(handler);
    });
  }
};

export default async function api<T>(
  url: string,
  options: Options = { method: 'GET' },
  headers?: http.OutgoingHttpHeaders,
): Promise<[Response<T>, Headers]> | never {
  for (const [i, server] of API_LOGIN_URLS.entries()) {
    try {
      const { body, port, ...restOptions } = options;

      const controller = new AbortController();
      const id = setTimeout(() => controller.abort(), FETCH_TIMEOUT);

      const data = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...headers,
        },
        ...restOptions,
        ...(body && {
          body: typeof body === 'object' ? JSON.stringify(body) : String(body),
        }),
        // aborting after some time as we have redundant servers
        signal: controller.signal,
      };

      await waitForNetwork();

      let targetUrl = url.startsWith('http') ? url : `${server}${url}`;

      if (port) {
        targetUrl = targetUrl.replace(/\d{4}/, `${port}`);
      }

      const result = await fetch(targetUrl, data);

      clearTimeout(id);

      if (result.status === 401) {
        throw new PermissionError('Auth error', {
          code: result.status,
        });
      }

      const normalizeResponse = normalizeApiData(result, await result.json());

      return [isValid(url, normalizeResponse), result.headers];
    } catch (e: any) {
      if (e instanceof ValidationError || e instanceof PermissionError) {
        throw e;
      }

      // Throw err if last server fails or url is absolute
      if (url.startsWith('http') || i === API_BALANCER_URLS.length) {
        throw new ApiError(e.message, {
          code: 500,
          stack: e.stack,
        });
      }
    }
  }

  throw new ApiError('Unknown error', {
    code: 500,
  });
}
