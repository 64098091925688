import React, { MouseEventHandler, ReactNode, useContext } from 'react';
import './styles.scss';
import logo from '../../assets/img/logoName.png';
import { AuthContext } from '../../screens/auth/auth.utils';
import { SocketContext } from '../../socket/socket.provider';
import Button from '../Button/Button';
import { useMobileWebAppStore } from '../WebNavigation/mobileAppWeb.store';
import { navigationRef } from '../../navigation/navigation.ref';
import { ToasterContext } from '../../toaster/toaster.provider';
import { HeaderBalance } from '#/components/HeaderBalance/HeaderBalance';

export const WebLayout: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const { logout, isAuth, isAccountActive, resendVerificationEmail } = useContext(AuthContext);
  const { success, error } = useContext(ToasterContext);
  const { cleanServerData } = useContext(SocketContext);
  const { isMobileWebApp } = useMobileWebAppStore();

  const onLogoClick: React.MouseEventHandler = e => {
    e.preventDefault();

    navigationRef.current?.reset({
      routes: [{ name: 'Home' }],
    });
  };

  const handleLogout = () => {
    cleanServerData();
    logout();
  };

  const handleResendVerificationEmail: MouseEventHandler<HTMLAnchorElement> = e => {
    e.preventDefault();

    resendVerificationEmail()
      .then(() => success({ message: 'Verification link has been resent' }))
      .catch(err => error({ message: err.message }));
  };

  const inactiveAccBannerVisible = isAuth && !isAccountActive();

  return (
    <div id="layout" className={isAuth ? 'logged-in' : undefined}>
      <header className={inactiveAccBannerVisible ? 'with-banner' : ''}>
        {isAuth ? (
          <a href="https://app.streamster.io" onClick={onLogoClick}>
            <img src={logo} />
          </a>
        ) : (
          <a href={isMobileWebApp ? undefined : 'https://streamster.io'}>
            <img src={logo} />
          </a>
        )}
        {inactiveAccBannerVisible ? (
          <p className="account-inactive">
            Please confirm your email address.{' '}
            <a href="#" onClick={handleResendVerificationEmail}>
              Resend the verification e-mail
            </a>
          </p>
        ) : null}
        {isAuth && (
          <>
            <HeaderBalance />
            <Button secondary onPress={handleLogout}>
              Log out
            </Button>
          </>
        )}
      </header>
      <main>
        <div className="content">{children}</div>
      </main>
    </div>
  );
};
