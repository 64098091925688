import { StyleSheet } from 'react-native';
import mixins from '../../app/styles';

const styles = StyleSheet.create({
  heading: {
    color: mixins.color.input,
    fontSize: 30,
    marginBottom: 25,
    marginTop: 50,
    textAlign: 'center',
  },
  buttonLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginVertical: 50,
  },
  wrapper: {},
  buttonInactive: {
    backgroundColor: mixins.color.grayDark,
  },
  typeButton: {
    marginHorizontal: 15,
  },
  plansRow: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  button: {
    backgroundColor: mixins.color.blue,
    borderRadius: mixins.indent.i3,
    paddingVertical: mixins.indent.i4,
    paddingHorizontal: mixins.indent.i11,
    borderColor: mixins.color.blueLight,
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonText: {
    color: mixins.color.white,
    fontSize: mixins.font.middleFont,
    fontWeight: 'bold',
  },
  modalText: {
    color: mixins.color.whiteText,
    fontSize: 16,
    textAlign: 'center',
    marginVertical: 'auto',
  },
  modalTextBold: {
    fontWeight: 'bold',
  },

  balanceOuter: {
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  activeTariffOuter: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  activeTariffText: {
    color: mixins.color.whiteText,
    fontSize: 16,
    marginBottom: mixins.indent.i2,
  },
  activeTariffWrapper: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: mixins.color.blueDark,
    paddingHorizontal: 30,
    paddingVertical: 15,
    borderRadius: 15,
  },
  balanceOuterMobile: {
    marginTop: mixins.indent.i7,
    marginLeft: 0,
  },
  balanceWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: mixins.color.blueDark,
    paddingHorizontal: 30,
    paddingVertical: 15,
    borderRadius: 15,
  },
  balanceText: {
    color: mixins.color.whiteText,
    fontSize: 16,
    textAlign: 'center',
    marginRight: mixins.indent.i11,
  },
  bold: {
    fontWeight: 'bold',
  },
  currentTariff: {
    flexDirection: 'column',
    textAlign: 'center',
    paddingTop: 10,
    paddingBottom: 20,
    marginBottom: 25,
    backgroundColor: mixins.color.blueLight,
  },

  basicTariffInfo: {
    padding: 15,
    backgroundColor: mixins.color.blueDark,
    color: mixins.color.whiteText,
    borderRadius: 15,
    fontSize: 16,
  },
  modalInput: { width: 400, marginHorizontal: 'auto', marginTop: mixins.indent.i12 },
});

export default styles;
