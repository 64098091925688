import { Linking, Platform } from 'react-native';

const handleLink = (url: string, target: '_self' | '_blank' = '_self') => {
  if (Platform.OS === 'web') {
    return window.open(url, target);
  }

  Linking.canOpenURL(url).then(supported => {
    if (supported) {
      Linking.openURL(url);
    }
  });
};

export const onPressOpenLink = (url: string, target: '_self' | '_blank' = '_self') => () =>
  handleLink(url, target);

export default handleLink;
