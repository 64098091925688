import { StyleSheet } from 'react-native';
import mixins from '../../../app/styles';

const styles = StyleSheet.create({
  row: {
    justifyContent: 'center',
    flexDirection: 'row',
    gap: 10,
    paddingHorizontal: 35,
    paddingTop: 20,
    marginVertical: 'auto',
  },
  image: {
    width: '100%',
    height: 150,
    marginVertical: 20,
  },
  text: {
    color: mixins.color.whiteText,
    fontSize: 16,
  },
  textRed: {
    color: mixins.color.red,
  },
  textUnderline: {
    textDecorationLine: 'underline'
  },
  textBold: {
    color: mixins.color.whiteText,
    fontSize: 16,
    fontWeight: 'bold',
  },
  wrapper: {
    textAlign: 'center',
    justifyContent: 'center',
    maxWidth: 600,
    alignSelf: 'center',
    flexGrow: 1,
    paddingVertical: 35,
    paddingHorizontal: 10,
  },
  currentTariff: {
    flexDirection: 'column',
    textAlign: 'center',
    paddingVertical: 15,
    marginBottom: 10,
    backgroundColor: mixins.color.blueLight,
  },
});

export default styles;
