import { useMeQuery, useMyTariffQuery } from '#/api/api.portal';
import mixins from '#/app/styles';
import { Text, View, StyleSheet } from 'react-native';
import Button from '../Button/Button';
import { useIsMobile } from '#/utils';
import { navigationRef } from '#/navigation/navigation.ref';
import { useSelectedBusinessUserStore } from '#/screens/home/selectedBusinessUser.store';
import { useContext, useEffect } from 'react';
import { AuthContext } from '#/screens/auth/auth.utils';

interface Props {}

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 'auto',
    backgroundColor: `${mixins.color.blueLight}44`,
    paddingVertical: 10,
    paddingHorizontal: 20,
    marginTop: -10,
    marginRight: 40,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  text: {
    color: mixins.color.whiteText,
  },
  button: {
    marginLeft: 10,
    paddingHorizontal: 15,
  },
});

export const HeaderBalance: React.FC<Props> = () => {
  const { webAuth } = useContext(AuthContext);
  const { data, isLoading } = useMeQuery();
  const { data: myTariff } = useMyTariffQuery({ enabled: !webAuth?.admin});
  const { selectedUserId, selectUser } = useSelectedBusinessUserStore();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!selectedUserId && data?.business) {
      selectUser(data.userId);
    }
  }, [selectUser, selectedUserId, data]);

  useEffect(() => {
    return () => selectUser(null);
  }, []);

  if (
    isMobile ||
    isLoading ||
    (data?.balance === 0 && !data.business && myTariff?.paymentMethod !== 'balance') ||
    webAuth?.admin
  ) {
    return null;
  }

  return (
    <>
      {data?.enoughBalance === false && (
        <p className="account-inactive">
          Your balance does not contain enough money to renew subscriptions for all of your accounts
          in next 5 days
        </p>
      )}
      <View style={styles.wrapper}>
        <Text style={styles.text}>Your balance: ${data?.balance.toFixed(2)}</Text>
        <Button
          style={styles.button}
          onPress={() => navigationRef.current?.navigate('Tariff', { openTopUp: true })}
        >
          Top up
        </Button>
      </View>
    </>
  );
};
