import { StyleSheet } from 'react-native';
import mixins from '../../../app/styles';

const styles = StyleSheet.create({
  balanceTextSecondLine: {
    color: mixins.color.whiteText,
    marginTop: mixins.indent.i6,
    marginBottom: mixins.indent.i6,
    fontSize: mixins.font.middleFont,
    textAlign: 'center',
  },
  heading: {
    color: mixins.color.whiteText,
    marginTop: mixins.indent.i6,
    marginBottom: mixins.indent.i6,
    fontSize: mixins.font.defaultFont,
    textAlign: 'center',
  },
  topUpButtonsRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  leftColumn: {
    width: 300,
    alignItems: 'center',
  },
  topUpButton: {
    margin: 5,
  },

  bonusRow: {
    flexDirection: 'row',
    paddingVertical: 8,
    paddingHorizontal: 12,
  },
  bonusRowEven: {
    backgroundColor: mixins.color.darker,
  },
  bonusRange: {
    color: mixins.color.white,
    fontSize: mixins.font.defaultFont,
  },
  bonusValue: {
    color: mixins.color.white,
    marginLeft: 'auto',
    fontSize: mixins.font.defaultFont,
    fontWeight: 'bold',
  },
  bonusColumn: {
    flexGrow: 1,
    marginLeft: 25,
  },
  row: {
    flexDirection: 'row',
  },
  customValueRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 25,
  },
  input: {
    backgroundColor: mixins.color.darker,
    paddingVertical: 8,
    borderRadius: 5,
    paddingHorizontal: 10,
    marginHorizontal: 5,
    fontSize: 24,
    width: 100,
    color: mixins.color.white,
    textAlign: 'center',
  },
  amountButtons: {
    paddingHorizontal: 15,
    fontSize: 24,
  },
});

export default styles;
