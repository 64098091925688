export const ALL_FEATURES: Array<{ name: string; tooltip?: string }> = [
  {
    name: 'Web streaming app',
    tooltip: 'Multistream from your browser',
  },
  {
    name: 'Desktop streaming app',
    tooltip: 'Software for HD live streaming for Windows.',
  },
  {
    name: 'Mobile streaming app',
    tooltip:
      'Multistream from your mobile or use your smartphone as a remote control to your PC live stream.',
  },
  {
    name: 'Unlimited traffic',
    tooltip: 'Pay once a month, create an unlimited streams.',
  },
  {
    name: 'No watermark',
    tooltip: 'No Streamster watermarks on your video',
  },
  {
    name: 'Multiple devices simultaneous usage',
    tooltip: 'Use several PCs or mobile devices to create live streams',
  },
  { name: 'Max. Resolution/FPS for Desktop app and Web app - 4k/60FPS' },
  { name: 'Max. Resolution/FPS for Mobile app - 1080p/30FPS' },
];
