import { useManageBoxControls } from '#/screens/stream/manageBox/utils';
import { useEffect } from 'react';
import { Keyboard, Platform } from 'react-native';

export const useKeyboardShowHide = () => {
  if (Platform.OS === 'web') {
    return;
  }

  useEffect(() => {
    let lastExpandedState = false;

    const showSubscription = Keyboard.addListener('keyboardDidShow', () => {
      lastExpandedState = useManageBoxControls.getState().expanded;
      if (lastExpandedState === false) {
        useManageBoxControls.getState().toggleExpand();
      }
    });
    const hideSubscription = Keyboard.addListener('keyboardDidHide', () => {
      if (lastExpandedState === false && useManageBoxControls.getState().expanded) {
        useManageBoxControls.getState().toggleExpand();
      }
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);
};
