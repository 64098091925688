import type { CSSProperties } from 'react';
import { StyleSheet } from 'react-native';
import mixins from '../../app/styles';

const styles = StyleSheet.create({
  tooltip: {
    position: 'fixed' as any,
    marginLeft: 40,
    width: 200,
    padding: mixins.indent.i5,
    backgroundColor: mixins.color.darker,
    color: mixins.color.whiteText,
    zIndex: 9999999,
    fontSize: 14,
    top: 0,
    left: 0,
  },
});

export const css = {
  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  } as CSSProperties,
};

export default styles;
