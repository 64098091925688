import React from 'react';
import { TouchableOpacity, View } from 'react-native';

import mixins from '../../../app/styles';
import { buttonStyles } from './Status.styles';

const TabButton = ({ icon: Icon, active, onPress }) => (
  <TouchableOpacity onPress={onPress}>
    <View style={[buttonStyles.button, active && buttonStyles.buttonActive]}>
      <Icon fill={mixins.color.white} width={40} height={40} />
    </View>
  </TouchableOpacity>
);

export default TabButton;
