import React, { useContext, useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Button from '../../components/Button/Button';
import DefaultModal from '../../components/DefaultModal/DefaultModal';
import mixins from '../../app/styles';
import { useMigrateToBusinessMutation } from '#/api/api.portal';
import { useAppNavigation } from '#/navigation/navigation.ref';
import { AuthContext } from '#/screens/auth/auth.utils';

const styles = StyleSheet.create({
  box: {
    paddingLeft: 150,
    borderTopColor: mixins.color.grayDarkest,
    borderTopWidth: 2,
    marginTop: mixins.indent.i12,
    paddingTop: mixins.indent.i8,
  },
  title: {
    color: mixins.color.whiteText,
    fontSize: 20,
    textTransform: 'uppercase',
    textAlign: 'center',
    marginBottom: mixins.indent.i6,
  },
  text: {
    textAlign: 'center',
    color: mixins.color.whiteText,
    fontSize: 15,
    lineHeight: 24,
    maxWidth: 600,
  },
  button: {
    marginTop: mixins.indent.i8,
    alignSelf: 'center',
    height: 40,
  },
});

export const MigrateToBusinessBox = () => {
  const [isConfirmModalVisible, setConfirmModalVisibility] = useState(false);
  const migrateToBusiness = useMigrateToBusinessMutation();
  const { logout } = useContext(AuthContext);
  const navigation = useAppNavigation();

  const handleMigrate = async () => {
    await migrateToBusiness.mutateAsync();
    setConfirmModalVisibility(false);
    await logout();

    setTimeout(() => {
      navigation.reset({
        routes: [{ name: 'SignIn', params: { handler: 'migrate-business' } }],
      });
    }, 0);
  };

  return (
    <View style={styles.box}>
      <Text style={styles.title}>For business</Text>
      <Text style={styles.text}>
        To manage multiple streamers accounts, you can switch your profile to the Business mode.{' '}
        <a href="https://streamster.io/products/streamster-business-studio/" target="_BLANK">
          Read more about business accounts.
        </a>
      </Text>
      <Button style={styles.button} onPress={() => setConfirmModalVisibility(true)}>
        Switch to business
      </Button>
      {isConfirmModalVisible && (
        <DefaultModal
          title="Upgrade to business"
          dismissText="Cancel"
          confirmText="Upgrade"
          onConfirm={handleMigrate}
          onDismiss={() => setConfirmModalVisibility(false)}
        >
          <Text style={styles.text}>
            This step is irreversible. Your current pricing plan and account settings will stay the
            same, but you’ll get the opportunity to add and manage more users within your account.
          </Text>
        </DefaultModal>
      )}
    </View>
  );
};
