import { BusinessTariff, Tariff, UserMonitoringUserSessionState } from '#/api/types';
import mixins from '#/app/styles';
import { ActivityStatus } from '#/components/ActivityStatus/ActivityStatus';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { IngestItem } from '#/components/BusinessUserGrid/IngestItem';
import Tooltip from '#/components/Tooltip';
import { renderTooltipRow, tooltipTitleStyle } from '#/components/BusinessUserGrid/tooltipUtils';
import { OptionsMenu } from '#/components/OptionsMenu/OptionsMenu';
import BroadcastIcon from '#/assets/icons/broadcast.svg';

import AccountIcon from '#/assets/icons/account.svg';
import ImageAreaIcon from '#/assets/icons/image-area.svg';
import { useAppNavigation } from '#/navigation/navigation.ref';
import { BusinessUserListItem } from '#/hooks/use-business-users';

dayjs.extend(relativeTime);

const styles = StyleSheet.create({
  item: {
    backgroundColor: mixins.color.darker,
    padding: 10,
    paddingRight: 0,
    borderWidth: 2,
    borderColor: mixins.color.dark,
    borderStyle: 'solid',
    width: 300,
    gap: 5,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inner: {
    flexDirection: 'column',
    gap: 5,
    flexGrow: 1,
    flexShrink: 1,
  },
  list: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 5,
  },
  login: {
    color: mixins.color.whiteText,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginLeft: 5,
    marginRight: 5,
  },
  status: {
    color: mixins.color.grayDarkest,
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    marginLeft: 'auto',
  },
  offlineStatus: {
    color: mixins.color.grayDarkest,
    fontWeight: 'bold',
  },
  topColumn: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ingestList: {
    gap: 0,
    flexDirection: 'row',
    marginLeft: 10,
  },
  active: {
    borderColor: mixins.color.blue,
  },
  bottomColumn: {
    height: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
});

interface Props {
  users: BusinessUserListItem[];
  tariffs: Tariff[];
  selectedUserId: number | null;
  selectUser: (id: string | number | null, login?: string) => void;
}

export const BusinessUserGrid: React.FC<Props> = ({
  users = [],
  tariffs,
  selectUser,
  selectedUserId,
}) => {
  const navigation = useAppNavigation();

  return (
    <View style={styles.list}>
      {users.map((user, i) => (
        <Pressable
          key={`${user.id}${i}`}
          style={[styles.item, selectedUserId === user.id && styles.active]}
          onPress={() => selectUser(user.id, user.login)}
        >
          <View style={styles.inner}>
            <View style={styles.topColumn}>
              <ActivityStatus
                size="small"
                active={user.isOnline}
                letter={user.admin ? 'A' : undefined}
              />
              <Tooltip
                text={
                  <>
                    <Text style={tooltipTitleStyle}>User information</Text>
                    {renderTooltipRow('Name', user.login)}
                    {renderTooltipRow(
                      'State',
                      user.state === UserMonitoringUserSessionState.Online ? 'Online' : 'Offline',
                    )}
                    {renderTooltipRow(
                      'Plan',
                      tariffs.find(t => t.id === user.tariffId)?.name || '-',
                    )}
                    {renderTooltipRow(
                      'Expires',
                      user.tariffExpiresAt ? dayjs().to(dayjs(user.tariffExpiresAt)) : '-',
                    )}
                  </>
                }
              >
                <Text style={styles.login}>{user.login}</Text>
              </Tooltip>
              {user.isOnline && <Text style={styles.status}>online: {user.server}</Text>}
            </View>
            <View style={styles.bottomColumn}>
              {user.isOnline && (
                <>
                  {user.ingest && <IngestItem value={user.ingest} />}
                  <View style={styles.ingestList}>
                    {user.channels.map((channel, i) => (
                      <IngestItem key={i} value={channel} />
                    ))}
                  </View>
                </>
              )}
              {!user.isOnline && (
                <Text style={styles.offlineStatus}>
                  Last session: {user.stop ? dayjs().to(dayjs(user.stop)) : 'unknown'}
                </Text>
              )}
            </View>
          </View>
          <OptionsMenu
            options={[
              {
                title: 'Preview',
                onPress: () => {
                  if (user.webRtcPage) {
                    window.open(user.webRtcPage, '_blank');
                  }
                },
                disabled: !user.isStreaming,
                icon: ImageAreaIcon,
              },
              {
                title: 'Live Stream',
                onPress: () => {
                  selectUser(user.id, user.login);
                  navigation.navigate('ActiveStream');
                },
                icon: BroadcastIcon,
              },
              {
                title: 'Statistics',
                onPress: () => {
                  selectUser(user.id, user.login);
                  navigation.navigate('Statistics');
                },
                icon: AccountIcon,
              },
              {
                title: 'Profile',
                onPress: () => {
                  selectUser(user.id, user.login);
                  navigation.navigate('Profile');
                },
                icon: AccountIcon,
              },
            ]}
          />
        </Pressable>
      ))}
    </View>
  );
};
