import React, { useState, useEffect, useContext, useRef } from 'react';
import ReactDOM from 'react-dom';
import {
  View,
  Text,
  TouchableOpacity,
  PanResponder,
  Animated,
  Easing,
  Platform,
  useWindowDimensions,
} from 'react-native';

// types
import type { ToasterMessage } from '../toaster/toaster.types';

// contect
import { ToasterContext } from '../toaster/toaster.provider';

// constants
import mixins, { BLUE_GRADIENT, GREEN_GRADIENT, RED_GRADIENT } from '../app/styles';
import { useIsWebMobile } from '../utils';

// styles
import styles from './toaster-messages.styles';
import { LinearGradient } from '../components/LinearGradient/LinearGradient';

const DURATION = 300;
const INDENT_MULTIPLIER = 12;
const SCALE_DIVIDER = 30;

const MAX_VISIBLE_TOASTS = 3;

const MAX_HEADER_LINES = 5;

type Props = {
  message: ToasterMessage;
  index: number;
};

const ToastMessage = (props: Props) => {
  const dimensions = useWindowDimensions();
  const isMobileWeb = useIsWebMobile();
  const { messages, removeToasterMessage } = useContext(ToasterContext);
  const { message, index } = props;
  const pan = useRef(new Animated.ValueXY()).current;
  const animated = useRef(new Animated.Value(0)).current;
  const messagesIndex = index + 1;
  const [isTap, setIsTap] = useState(false);
  const MAX_TEXT_LINES = Math.round(dimensions.height / 2 / mixins.lineHeight.default);

  const animatedDelete = (direction: 'left' | 'right') =>
    Animated.timing(pan, {
      toValue: direction === 'left' ? -dimensions.width : dimensions.width,
      duration: DURATION,
      useNativeDriver: false,
    }).start(() => removeToasterMessage(message.unique));

  useEffect(() => {
    let timeout: any;

    if (index === 0 && !isTap) {
      timeout = setTimeout(() => {
        animatedDelete('left');
      }, props.message.ttl);
    }

    Animated.timing(animated, {
      toValue: 1,
      duration: DURATION,
      easing: Easing.sin,
      useNativeDriver: false,
    }).start();

    return () => {
      timeout ? clearTimeout(timeout) : null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, isTap]);

  const panResponder = useRef(
    PanResponder.create({
      onMoveShouldSetPanResponder: () => true,
      onPanResponderGrant: () => {
        pan.setOffset({
          // @ts-expect-error - usage of private property
          x: pan.x._value,
          // @ts-expect-error - usage of private property
          y: pan.y._value,
        });
      },
      onPanResponderMove: Animated.event([null, { dx: pan.x }], {
        useNativeDriver: false,
      }),
      onPanResponderRelease: () => {
        // @ts-expect-error - usage of private property
        if (pan.x._value > 0) {
          // drag right
          animatedDelete('right');
        } else {
          // drag left
          animatedDelete('left');
        }
        pan.flattenOffset();
      },
    }),
  ).current;

  const handlePressTost = () => {
    props.message.onClick?.();

    // expanding disabled
    // if (index === 0) {
    //   setIsTap(true);
    // }
  };

  if (index >= MAX_VISIBLE_TOASTS) {
    return null;
  }

  const getColor = () => {
    switch (props.message.type) {
      case 'error':
        return RED_GRADIENT;
      case 'success':
        return GREEN_GRADIENT;
      default:
        return BLUE_GRADIENT;
    }
  };

  const getTextColor = () => {
    switch (props.message.type) {
      case 'error':
        return styles.textWhite;
      default:
        return undefined;
    }
  };

  return (
    <Animated.View
      style={[
        styles.messageBlock,
        isMobileWeb && styles.messageBlockMobileWeb,
        {
          zIndex: messages.length - index,
          transform: [
            { translateX: pan.x },
            {
              translateY: animated.interpolate({
                inputRange: [0, 1],
                outputRange: [
                  (messagesIndex - 1) * INDENT_MULTIPLIER,
                  messagesIndex * INDENT_MULTIPLIER,
                ],
              }),
            },
            {
              scale: animated.interpolate({
                inputRange: [0, 1],
                outputRange: [1, 1 - (messagesIndex - 1) / SCALE_DIVIDER],
              }),
            },
          ],
        },
      ]}
      {...panResponder.panHandlers}
    >
      <TouchableOpacity activeOpacity={1} onPress={handlePressTost} onPressIn={handlePressTost}>
        <LinearGradient colors={getColor()} style={styles.messageGradient} key={message.unique}>
          <View style={styles.textWrap}>
            <Text
              style={[styles.heading, getTextColor()]}
              numberOfLines={isTap ? MAX_HEADER_LINES : 1}
            >
              {message.header}
            </Text>
            <Text style={[styles.text, getTextColor()]} numberOfLines={isTap ? MAX_TEXT_LINES : 2}>
              {message.message}
            </Text>
          </View>
        </LinearGradient>
      </TouchableOpacity>
    </Animated.View>
  );
};

export default function ToasterMessages() {
  const { messages } = useContext(ToasterContext);

  const component = (
    <View style={styles.container}>
      {messages.map((message, i) => (
        <ToastMessage key={message.unique} message={message} index={i} />
      ))}
    </View>
  );

  if (!messages.length) {
    return null;
  }

  return Platform.OS === 'web'
    ? ReactDOM.createPortal(component, document.querySelector('#root'))
    : component;
}
