import mixins from '#/app/styles';
import Heading from '#/components/Heading/Heading';
import { useBusinessUsers } from '#/hooks/use-business-users';
import { useSelectedBusinessUserStore } from '#/screens/home/selectedBusinessUser.store';
import { View } from 'react-native';

import styles from './BusinessUserSwitcher.module.scss';
import { ActivityStatus } from '#/components/ActivityStatus/ActivityStatus';
import ChevronUp from '#/assets/icons/chevron-up.svg';
import { useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '#/screens/auth/auth.utils';

const BusinessUserSwitcherInner = () => {
  const selectRef = useRef<HTMLDivElement>(null);
  const { userList } = useBusinessUsers('name');
  const { selectedUserId, selectUser } = useSelectedBusinessUserStore();
  const [isOpen, setOpen] = useState(false);

  const currentUser = userList.find(u => u.id === selectedUserId);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const handler = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handler);

    return () => document.removeEventListener('click', handler);
  }, [isOpen]);

  if (!currentUser) {
    return null;
  }

  return (
    <View style={[mixins.styles.margin.bottomI5, { zIndex: 999 }]}>
      <Heading style={[mixins.styles.margin.topI8, { flexDirection: 'row', alignItems: 'center' }]}>
        User
        <div ref={selectRef} className={`${styles.wrapper} ${isOpen ? styles.wrapperOpen : ''}`}>
          <div className={styles.select} onClick={() => setOpen(s => !s)}>
            <ActivityStatus
              active={currentUser.isOnline}
              letter={currentUser.admin ? 'A' : undefined}
            />
            <span>{currentUser.login}</span>
            <ChevronUp width={30} fill={mixins.color.whiteText} />
          </div>
          {isOpen && (
            <div className={styles.dropdown}>
              {userList.map(user => (
                <div
                  key={user.login}
                  className={styles.user}
                  onClick={() => {
                    selectUser(user.id, user.login);
                    setOpen(false);
                  }}
                >
                  <ActivityStatus
                    active={user.isOnline}
                    letter={user.admin ? 'A' : undefined}
                    size="medium"
                  />
                  <span>{user.login}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </Heading>
    </View>
  );
};

export const BusinessUserSwitcher = () => {
  const { selectedUserId, selectedUserLogin } = useSelectedBusinessUserStore();
  const { webAuth } = useContext(AuthContext);

  if (!selectedUserId) {
    return null;
  }

  if (webAuth?.admin) {
    return <Heading>User {selectedUserLogin}</Heading>;
  }

  return <BusinessUserSwitcherInner />;
};
