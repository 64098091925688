import React, { useEffect, useRef, useState } from 'react';
import {
  TextInputProps,
  View,
  Text,
  TextInput,
  StyleSheet,
  NativeSyntheticEvent,
  TextInputFocusEventData,
} from 'react-native';
import mixins from '../../app/styles';
import { useIsMobile } from '../../utils';
import { useMobileWebAppStore } from '../WebNavigation/mobileAppWeb.store';

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    paddingTop: 35,
  },
  input: {
    backgroundColor: 'transparent',
    paddingVertical: 10,
    paddingHorizontal: 0,
    color: mixins.color.white,
    borderBottomColor: mixins.color.white,
    borderBottomWidth: 2,
    fontWeight: '700',
  },
  inputActive: {
    borderBottomColor: mixins.color.blue,
  },
  inputMobile: {
    paddingVertical: 15,
  },
  label: {
    position: 'absolute',
    top: 55,
    left: 0,
    transform: [{ translateY: '-50%' as any }],
    color: mixins.color.white,
    transition: 'all ease .25s',
    fontWeight: 700,
    pointerEvents: 'none',
  },
  labelActive: {
    top: 25,
    color: mixins.color.blue,
  },
  font16: {
    fontSize: 16,
  },
});

export const MaterialInput: React.FC<TextInputProps & { label?: string }> = ({
  label,
  onChangeText,
  style,
  onFocus,
  onBlur,
  ...props
}) => {
  const { isMobileWebApp } = useMobileWebAppStore();
  const isMobile = useIsMobile();
  const viewRef = useRef<any>(null);
  const [hasValue, setHasValue] = useState(Boolean(props.value));
  const [isFocused, setFocus] = useState(false);

  const handleChangeValue = (value: string) => {
    setHasValue(Boolean(value));
    onChangeText?.(value);
  };

  const handleOnBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    setFocus(false);
    onBlur?.(e);
  };

  const handleOnFocus = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    setFocus(true);
    onFocus?.(e);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      try {
        const autofillInDom = viewRef.current?.querySelector('input:-webkit-autofill');
        if (autofillInDom) {
          setHasValue(true);
          onChangeText?.(autofillInDom.value);
          clearInterval(interval);
        }
        // ignore if querySelector fails on firefox
      } catch (e) {}
    }, 50);
    setTimeout(() => clearInterval(interval), 1000);

    return () => clearInterval(interval);
  }, [onChangeText]);

  return (
    <View style={styles.container} ref={viewRef}>
      <Text style={[styles.label, hasValue ? styles.labelActive : undefined]}>{label}</Text>
      <TextInput
        {...props}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        style={[
          styles.input,
          style,
          isFocused ? styles.inputActive : undefined,
          isMobile && styles.inputMobile,
          isMobileWebApp && styles.font16,
        ]}
        onChangeText={handleChangeValue}
      />
    </View>
  );
};
