import { create } from 'zustand';

interface Store {
  isMobileWebApp: boolean;
  setMobileWebApp: (_state: boolean) => void;
}

export const useMobileWebAppStore = create<Store>(set => ({
  isMobileWebApp: false,
  setMobileWebApp: state => set({ isMobileWebApp: state }),
}));
