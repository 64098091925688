import React, { ReactNode } from 'react';
import * as Sentry from '@sentry/react';

// types
import type { ToasterContextType, ToasterTypes } from '../toaster/toaster.types';
import { Errors, ValidationError } from '../error/error';
import { getRandomId } from '../utils';

export const ToasterContext = React.createContext<ToasterContextType>(undefined!);

type Props = {
  children: ReactNode;
};

type State = {
  context: ToasterContextType;
};

interface MessageOpts {
  title?: string;
  message: string;
  ttl?: number;
}

interface AddMessageOpts {
  title: string;
  message: string;
  ttl?: number;
  type: ToasterTypes;
  onClick?: () => void;
}

const toasterContextRef: { current: State['context'] } = { current: null as any };

export class ToasterProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      context: {
        error: this.error,
        info: this.info,
        warning: this.warning,
        fatal: this.fatal,
        success: this.success,
        stateError: null,
        removeToasterMessage: this.removeToasterMessage,
        messages: [],
      },
    };
  }

  componentDidCatch(error: Error): void {
    if (!(error instanceof ValidationError)) {
      Sentry.captureException(error);
    }

    this.addToasterMessage({
      message: error.message,
      title: 'Something went wrong',
      type: 'error',
      ttl: 3000,
    });
  }

  fatal = (error: Errors): void => {
    this.setError(error);
  };

  error = (message: MessageOpts): void => {
    this.addToasterMessage({ type: 'error', title: 'Error', ttl: 3000, ...message });
  };

  success = (message: MessageOpts): void => {
    this.addToasterMessage({ type: 'success', title: 'Success', ttl: 2000, ...message });
  };

  info = (message: MessageOpts): void => {
    this.addToasterMessage({ type: 'info', title: 'Info', ttl: 2000, ...message });
  };

  warning = (message: MessageOpts): void => {
    this.addToasterMessage({ type: 'warning', title: 'Warning', ttl: 2000, ...message });
  };

  setError = (stateError: Errors): void => {
    this.setState(prevState => ({
      context: {
        ...prevState.context,
        stateError,
      },
    }));
  };

  removeToasterMessage = (unique: string): void => {
    const { messages } = this.state.context;

    this.setState(prevState => ({
      context: {
        ...prevState.context,
        messages: messages.filter(message => unique !== message.unique),
      },
    }));
  };

  addToasterMessage = (message: AddMessageOpts): void => {
    const {
      context: { messages },
    } = this.state;
    this.setState(prevState => ({
      context: {
        ...prevState.context,
        messages: messages.concat([
          {
            type: message.type,
            message: message.message,
            header: message.title,
            ttl: message.ttl,
            unique: getRandomId(),
          },
        ]),
      },
    }));
  };

  render(): JSX.Element {
    toasterContextRef.current = this.state.context;

    return (
      <ToasterContext.Provider value={this.state.context}>
        {this.props.children}
      </ToasterContext.Provider>
    );
  }
}

export const getToasterContext = () => toasterContextRef.current;
