import React from 'react';
import {
  NavigationContainerRef,
  RouteProp,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { StackNavigation, StackParamList } from './navigation.types';

export const navigationRef = React.createRef<NavigationContainerRef<StackParamList>>();

export const useAppNavigation = () => useNavigation<StackNavigation>();

export const useAppRoute = <T extends keyof StackParamList>() =>
  useRoute<RouteProp<StackParamList, T>>();
