import { StyleSheet } from 'react-native';
import mixins from '../../../app/styles';

const styles = StyleSheet.create({
  text: {
    color: mixins.color.orange,
    fontSize: 18,
    fontWeight: 'bold',
  },
  textRed: {
    color: mixins.color.red,
    fontSize: 18,
    fontWeight: 'bold',
  },
  previewWrapper: {
    flexDirection: 'column',
    flexShrink: 1,
    flexGrow: 1,
  },
  buttonWrapper: {
    width: 55,
  },
  indicationsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  chartWrapper: {
    height: 300,
    flexGrow: 1,
  },
  sourcesWrapper: {
    height: 300,
    flexGrow: 1,
    paddingLeft: 25,
    paddingRight: 25,
  },
  tabs: {
    flexDirection: 'row',
  },
  controls: {
    marginTop: mixins.indent.i5,
    marginBottom: mixins.indent.i9,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonInactive: {
    backgroundColor: 'transparent',
  },
  errorMsg: {
    position: 'absolute',
    top: -25,
    width: '100%',
    textAlign: 'center',
    color: mixins.color.red,
    fontSize: 15,
  },
  bitrateWarning: {
    color: mixins.color.hotOrange,
  },
  bitrateWarningWrapper: {
    height: 35,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
});

export const buttonStyles = StyleSheet.create({
  button: {
    borderRightColor: 'transparent',
    borderRightWidth: 8,
    borderStyle: 'solid',
    opacity: 0.6,
    marginVertical: 5,
  },
  buttonActive: {
    borderRightColor: mixins.color.blue,
    opacity: 1,
  },
});

export const chartMargins = {
  top: 5,
  right: 30,
  left: 5,
  bottom: 5,
};

export default styles;
