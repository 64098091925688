import mixins from '#/app/styles';
import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  planWrapper: {
    backgroundColor: mixins.color.darker,
    width: 320,
    margin: 10,
    display: 'flex',
    alignItems: 'center',
    paddingVertical: 25,
    paddingHorizontal: 10,
    borderWidth: 2,
    borderColor: mixins.color.darker,
    borderStyle: 'solid',
    zIndex: 11,
  },
  planWrapperActive: {
    borderColor: mixins.color.blue,
  },
  currentPlan: {
    borderWidth: 3,
    borderColor: mixins.color.blue,
    borderStyle: 'solid',
    backgroundColor: mixins.color.blue,
    color: mixins.color.white,
    borderRadius: 50,
    height: 24,
    position: 'absolute',
    top: -12,
    paddingHorizontal: 15,
    textTransform: 'uppercase',
  },
  planName: {
    color: mixins.color.lime,
    fontSize: 20,
    textTransform: 'uppercase',
    marginBottom: 15,
  },
  planPriceRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  planPrice: {
    fontSize: 50,
    color: mixins.color.white,
  },
  planCurrency: {
    color: mixins.color.whiteText,
    fontSize: 15,
    marginTop: 12,
    marginRight: 5,
  },
  planTime: {
    color: mixins.color.white,
    fontSize: 14,
  },
  planDescription: {
    color: mixins.color.whiteText,
    borderTopWidth: 1,
    borderColor: mixins.color.grayDark,
    borderStyle: 'solid',
    paddingTop: 15,
    paddingBottom: 25,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  planSelect: {
    marginTop: 'auto',
    alignSelf: 'center',
  },
  planFeature: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    zIndex: 10,
  },

  label: {
    color: mixins.color.whiteText,
    width: 85,
    flexShrink: 0,
    fontSize: 15,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginRight: 5,
  },
  value: {
    color: mixins.color.grey,
    fontSize: 15,
  },
  labelIcon: {
    width: 18,
    height: 18,
    fill: mixins.color.white,
  },
  featureTooltipIcon: {
    width: 14,
    height: 14,
    marginTop: 2,
    marginLeft: mixins.indent.i4,
  },
});
