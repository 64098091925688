import React from 'react';
import { Pressable, StyleSheet } from 'react-native';

const PressableWithOpacityHover = ({
  children,
  style,
  disabled,
  ...rest
}: React.ComponentProps<typeof Pressable>) => (
  <Pressable
    {...rest}
    disabled={disabled}
    style={
      typeof style === 'function'
        ? style
        : ({ hovered, pressed }) =>
            StyleSheet.compose(
              {
                opacity: disabled ? 0.8 : pressed ? 0.9 : hovered ? 0.8 : 1,
              },
              style,
            )
    }
  >
    {children}
  </Pressable>
);

export default PressableWithOpacityHover;
