import React, { useMemo, useState } from 'react';
import { CustomTarget } from '../../helpers/defaultData';
import { Target, TargetFlags } from '../../socket/document.type';
import DefaultModal from '../DefaultModal/DefaultModal';
import CheckIcon from '../../assets/icons/check.svg';
import CloseIcon from '../../assets/icons/close.svg';
import mixins from '../../app/styles';
import Button from '../Button/Button';
import AddTarget from './AddTarget/AddTarget';
import { ScrollView, View } from 'react-native';
import styles from './AddChannelModal.styles';

interface Props {
  handleClose: () => void;
  handleChooseTargetToAdd: (target: Target) => void;
  targets: Record<string, Target>;
}

const ALL_FLAGS = Object.entries(TargetFlags)
  .filter(([flag, v]) => !Number.isFinite(Number(flag)) && Number(v) > 0)
  .sort(([_, v1], [__, v2]) => Number(v1) - Number(v2)) as Array<[string, number]>;

const ALL_FLAG_NAMES = ALL_FLAGS.map(([flag]) => flag);

const AddChannelModal = ({ handleClose, handleChooseTargetToAdd, targets }: Props) => {
  const [selectedFlags, setFlags] = useState(ALL_FLAG_NAMES);
  const sortedTargets = useMemo(
    () => [
      CustomTarget,
      ...Object.values(targets)
        .map(t => ({
          ...t,
          decodedFlags: ALL_FLAGS.slice()
            .reverse()
            .reduce(
              (acc, [flagName, flagValue]) => {
                if (acc.value >= flagValue) {
                  return { flags: [...acc.flags, flagName], value: acc.value - flagValue };
                }
                return acc;
              },
              { flags: [] as string[], value: t.Flags },
            ).flags,
        }))
        .filter(target => target.decodedFlags.some(tag => selectedFlags.includes(tag)))
        .sort((a, b) => a.Name.localeCompare(b.Name)),
    ],
    [targets, selectedFlags],
  );

  const toggleFlag = (flag: string) => () => {
    setFlags(
      selectedFlags.includes(flag)
        ? selectedFlags.filter(f => f !== flag)
        : [...selectedFlags, flag],
    );
  };

  return (
    <DefaultModal title="Select destination" onDismiss={handleClose} rawContent>
      <View style={styles.channelFlagWrapper}>
        {ALL_FLAG_NAMES.map(flag => {
          const Icon = selectedFlags.includes(flag) ? CheckIcon : CloseIcon;

          return (
            <Button
              icon={<Icon fill={mixins.color.white} style={styles.channelIcon} />}
              key={flag}
              style={styles.channelFlagButton}
              onPress={toggleFlag(flag)}
            >
              {flag}
            </Button>
          );
        })}
      </View>
      <ScrollView>
        <View style={styles.channelList}>
          {sortedTargets.map(target => (
            <AddTarget
              handleChooseTarget={handleChooseTargetToAdd}
              target={target}
              key={target.Id}
            />
          ))}
        </View>
      </ScrollView>
    </DefaultModal>
  );
};

export default AddChannelModal;
