import { useContext } from 'react';
import { StreamingToCloudBehavior, Document } from '../../socket/document.type';
import { ToasterContext } from '#/toaster/toaster.provider';
import { SendPatch } from '#/socket/socket.type';
import {
  updateChannelFieldAction,
  updateStreamingToCloudStartedAction,
} from '#/socket/socket.actions';
import { AuthContext } from '#/screens/auth/auth.utils';
import socket from '#/socket/socket';
import { decodeToken } from '#/utils';

export const useUserLimits = () => {
  const tokenOverride = socket.getTokenOverride();
  const { decodedAccessToken: dat } = useContext(AuthContext);

  if (!tokenOverride) {
    return { ...dat, maxChannels: dat.mch, maxInput: dat.tri, transcodingChannels: dat.tra };
  }

  const data = decodeToken(tokenOverride);

  return { ...data, maxChannels: data.mch, maxInput: data.tri, transcodingChannels: data.tra };
};

export const useToggleChannel = ({
  serverData,
  maxChannels,
  sendPatch,
}: {
  serverData: Document;
  maxChannels: number;
  sendPatch: SendPatch;
}) => {
  const { error } = useContext(ToasterContext);
  const func = (channel: keyof typeof serverData.Channels) => {
    const channelData = serverData.Channels[channel];
    const totalEnabledChannels = Object.values(serverData.Channels).reduce(
      (total, c) => total + (c?.IsOn ? 1 : 0),
      0,
    );

    if (channelData.IsOn === false && totalEnabledChannels >= maxChannels) {
      return error({
        message: `Your plan does not allow more than ${maxChannels} channels`,
        title: 'Limit reached',
      });
    }

    if (
      channelData.IsOn === false &&
      serverData.Settings.StreamingToCloud === StreamingToCloudBehavior.FirstChannel &&
      !serverData.Settings.StreamingToCloudStarted
    ) {
      setTimeout(() => sendPatch(updateStreamingToCloudStartedAction(true)), 100);
    }

    sendPatch(updateChannelFieldAction(channel, 'IsOn', !channelData.IsOn));
  };

  return func;
};
