import { useContext, useEffect, useState } from 'react';
import { View, Text, ScrollView, Pressable } from 'react-native';
import { CheckBox } from 'react-native-web';
import useIsMounted from '../../hooks/is-mounted-hook';
import ArrowLeftIcon from '../../assets/icons/arrow-left.svg';

// context
import { AuthContext } from '../auth/auth.utils';
import { ToasterContext } from '../../toaster/toaster.provider';

// styles
import styles from './register.styles';
import { MaterialInput } from '../../components/MaterialInput/MaterialInput';
import handleLink from '../../helpers/handle-link';
import mixins from '../../app/styles';
import Button from '../../components/Button/Button';
import { useIsMobile } from '../../utils';
import { useMobileWebAppStore } from '../../components/WebNavigation/mobileAppWeb.store';
import { useAppNavigation, useAppRoute } from '#/navigation/navigation.ref';
import { useCouponTariffQuery } from '#/api/api.portal';
import Loader from '#/components/Loader/Loader';

const RegisterScreen = () => {
  const isMounted = useIsMounted();
  const isMobile = useIsMobile();
  const navigation = useAppNavigation();
  const route = useAppRoute<'Register' | 'RegisterCoupon'>();
  const isCouponRegister = route.name === 'RegisterCoupon';
  const [isReferralCodeVisible, setReferralCodeVisibility] = useState(
    Boolean(route?.params?.referralCode),
  );
  const { isMobileWebApp, setMobileWebApp } = useMobileWebAppStore();
  const [progress, setProgress] = useState(false);
  const { register } = useContext(AuthContext);
  const { error } = useContext(ToasterContext);
  const [fields, setFields] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    email: '',
    couponCode: '',
    referralCode: route?.params?.referralCode || '',
    terms: false,
  });
  const { data: tariff, isLoading } = useCouponTariffQuery(fields.couponCode, {
    enabled: Boolean(isCouponRegister && fields.couponCode),
    cacheTime: 1,
  });

  useEffect(() => {
    if (route.params?.mobileApp && !isMobileWebApp) {
      setMobileWebApp(true);
    }
  }, [route?.params?.mobileApp]);

  const setFieldValue = (field: keyof typeof fields) => (value: string | boolean | number) => {
    setFields(f => ({ ...f, [field]: value }));
  };

  const requiredFields: Array<keyof typeof fields> = [
    'email',
    'password',
    'username',
    'terms',
    'confirmPassword',
  ];
  const isFormValid =
    requiredFields.every(field => (typeof field === 'string' ? field.trim().length > 0 : field)) &&
    fields.password === fields.confirmPassword;

  const handleRegister = async () => {
    const { username, password, email, confirmPassword, terms, couponCode, referralCode } = fields;

    if (password !== confirmPassword) {
      return error({ message: "Passwords don't match" });
    }
    if (!terms) {
      return error({ message: 'Please accept our terms and conditions' });
    }
    if (!isFormValid) {
      return error({ message: 'Please fill all fields' });
    }
    if (isCouponRegister && !couponCode) {
      return error({ message: 'Please enter coupon code to use this promotion' });
    }

    try {
      setProgress(true);
      await register({ login: username, password, email, couponCode, referralCode });
      navigation.navigate('SignIn', { handler: 'after-registration' });
    } catch (e: any) {
      error({ message: e.message });
    } finally {
      if (isMounted()) {
        setProgress(false);
      }
    }
  };

  return (
    <ScrollView>
      <View style={styles.webWrapper}>
        <View style={styles.box}>
          {!isMobileWebApp ? (
            <ArrowLeftIcon
              onClick={() => navigation.navigate('SignIn')}
              style={styles.goBackIcon}
            />
          ) : null}
          <Text style={[styles.webHeader, isMobile && mixins.styles.margin.topI6]}>
            Create account
          </Text>
          <Text style={styles.webSubHeader}>to continue to Streamster</Text>
          <View style={[styles.inputContainer, styles.webInputContainer]}>
            <MaterialInput
              onChangeText={setFieldValue('username')}
              value={fields.username}
              autoComplete="username"
              label="User name"
            />
            <MaterialInput
              onChangeText={setFieldValue('email')}
              value={fields.email}
              autoComplete="email"
              label="Email"
            />
            <MaterialInput
              secureTextEntry
              onChangeText={setFieldValue('password')}
              value={fields.password}
              autoComplete="password"
              label="Password"
            />
            <MaterialInput
              secureTextEntry
              onChangeText={setFieldValue('confirmPassword')}
              value={fields.confirmPassword}
              autoComplete="password"
              label="Confirm password"
            />
            {isReferralCodeVisible ? (
              <MaterialInput
                onChangeText={setFieldValue('referralCode')}
                value={fields.referralCode}
                label="Referral code"
              />
            ) : (
              <Pressable
                onPress={() => {
                  setReferralCodeVisibility(true);
                }}
              >
                <Text style={styles.haveReferralCodeText}>Have a referral code?</Text>
              </Pressable>
            )}
            {isCouponRegister && (
              <>
                <MaterialInput
                  onChangeText={setFieldValue('couponCode')}
                  value={fields.couponCode}
                  label="Coupon code"
                />
                {fields.couponCode && (
                  <View style={styles.tariffBox}>
                    {isLoading && <Loader size={30} />}
                    {tariff && (
                      <Text style={styles.tariffText}>
                        Coupon code valid for pricing plan:
                        <br /> <Text style={styles.tariffName}>{tariff.name}</Text>
                      </Text>
                    )}
                    {!isLoading && !tariff && (
                      <Text style={[styles.tariffText, styles.tariffError]}>
                        Coupon code is invalid or already used
                      </Text>
                    )}
                  </View>
                )}
              </>
            )}
            <View style={styles.termsWrapper}>
              <CheckBox
                style={styles.checkbox}
                color={mixins.color.blue}
                value={fields.terms}
                onValueChange={setFieldValue('terms')}
              />
              <Text style={styles.termsText} onPress={() => setFieldValue('terms')(!fields.terms)}>
                I agree to{' '}
                <Text
                  style={styles.termsLink}
                  onPress={() => handleLink('https://streamster.io/terms-conditions/')}
                >
                  Terms & Conditions
                </Text>
              </Text>
            </View>
            <Button onPress={handleRegister} loading={progress} style={styles.btnRegister}>
              Create
            </Button>
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

export default RegisterScreen;
