import mixins from '#/app/styles';
import { Text, StyleSheet, View } from 'react-native';

const styles = StyleSheet.create({
  tooltipRow: {
    flexDirection: 'row',
  },
  tooltipTitle: {
    color: mixins.color.grayDarker,
    fontWeight: 'bold',
    marginBottom: 5,
    minWidth: 200,
  },
  tooltipField: {
    color: mixins.color.grayDarker,
    width: 70,
    paddingLeft: 10,
    flexShrink: 0,
  },
  tooltipValue: {
    color: mixins.color.whiteText,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

export const tooltipTitleStyle = styles.tooltipTitle;

export const renderTooltipRow = (name: string, value: string | number) => (
  <View style={styles.tooltipRow}>
    <Text style={styles.tooltipField}>{name}</Text>
    <Text style={styles.tooltipValue}>{value}</Text>
  </View>
);
