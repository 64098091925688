import { Realtime, Types } from 'ably';
import api from '../../api/api';
import { API_HOST_URL } from '../../api/api.const';

export const AblyTokenRef = { token: '' };

export const ably = new Realtime.Promise({
  autoConnect: false,
  authCallback: async (_data, cb) => {
    if (!AblyTokenRef.token) {
      return cb('Missing token', '');
    }

    const [response] = await api(
      `${API_HOST_URL}/ably-token`,
      { method: 'GET' },
      { Authorization: `Bearer ${AblyTokenRef.token}` },
    );

    cb(undefined as any, response.data as any);
  },
});
