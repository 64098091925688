import { StyleSheet } from 'react-native';
import mixins from '../../app/styles';

const styles = StyleSheet.create({
  wrapper: {
    padding: mixins.indent.i5,
    backgroundColor: mixins.color.black,
    borderColor: mixins.color.black,
  },
  label: {
    color: mixins.color.grayDarker,
    fontWeight: 'bold',
  },
  value: {
    marginTop: mixins.indent.i2,
    color: mixins.color.grayDarker,
  },
});

export default styles;
