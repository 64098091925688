import { useAdminOnlineUsersQuery, useBusinessUserTokenRequest } from '#/api/api.portal';
import mixins from '#/app/styles';
import CloseIcon from '#/assets/icons/close.svg';
import PlusIcon from '#/assets/icons/plus.svg';
import Button from '#/components/Button/Button';
import { ChatBoxView } from '#/components/ChatBox/ChatBoxView';
import Loader from '#/components/Loader/Loader';
import { AUTH_OVERRIDE_KEY } from '#/helpers/consts';
import { BusinessUserListItem } from '#/hooks/use-business-users';
import { AuthContext } from '#/screens/auth/auth.utils';
import chatBoxStyles from '#/screens/chats/Chats.screen.styles';
import { HubConnection } from '#/socket/socket';
import { normalizePatch, SocketContext } from '#/socket/socket.provider';
import { NewChatsEvent } from '#/store/chatMessages/chatMessagesStoreTypes';
import { UNIQUE_ID, useSocketConnectionSwitcher } from '#/utils';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Text, View } from 'react-native';
import styles from './BusinessUsersChats.module.scss';
import { DeviceState, Document } from '#/socket/document.type';
import { defaultState } from '#/socket/socket.const';
import { applyPatch } from 'fast-json-patch';

type Props = {
  users: BusinessUserListItem[];
  visible: boolean;
  adminView?: boolean;
};

export const BusinessUsersChats: React.FC<Props> = ({ users, visible, adminView = false }) => {
  useSocketConnectionSwitcher({ active: visible });

  const { serverData } = useContext(SocketContext);
  const sockets = useRef<Record<string, HubConnection>>({});
  const { webAuth } = useContext(AuthContext);
  const [requestChatToken] = useBusinessUserTokenRequest({
    adminRequest: webAuth?.admin,
    tokenType: 'chats',
  });
  const [messages, setMessages] = useState<Record<string, any>>({});
  const socketDatas = useRef<Record<string, Document>>({});

  useEffect(() => {
    return () => {
      Object.values(sockets.current).forEach(socket => socket.end());
    };
  }, []);

  if (!visible) {
    return null;
  }

  const onlineUsers = users.filter(user => user.isOnline);

  const handleInitialize = (user: BusinessUserListItem) => async () => {
    setMessages(m => ({ ...m, [user.login]: { loading: true, messages: [], authors: [] } }));

    const token = await requestChatToken(user.id, user.login);

    const onReceiveMessages = (message: NewChatsEvent) => {
      const a = message.Targets.map(t => t.Authors.map(a => ({ ...a, target: t.TargetId })))
        .flat()
        .reduce((acc, author) => {
          acc[author.Id] = author;
          return acc;
        }, {} as any);
      const m = message.Targets.map(t => t.Messages.map(m => ({ ...m, target: t }))).flat();

      setMessages(messages => ({
        ...messages,
        [user.login]: {
          loading: false,
          id: user.id,
          messages: [...messages[user.login].messages, ...m],
          authors: { ...messages[user.login].authors, ...a },
        },
      }));
    };

    const socket = new HubConnection({
      background: true,
      onReceiveMessages,
      token: token.data.access_token,
    });

    sockets.current[user.login] = socket;
    socketDatas.current[user.login] = defaultState.serverData;

    await socket.createConnection(msg => {
      const patch = normalizePatch(msg.Changes);
      socketDatas.current[user.login] = applyPatch(
        socketDatas.current[user.login],
        patch,
        false,
        false,
      ).newDocument;
    });
    await socket.connectWithToken(token.data.access_token, user.id);
    await socket.start();
  };

  const handleChatClose = (login: string) => async () => {
    await sockets.current[login].end();
    setMessages(({ [login]: _user, ...messages }) => messages);
    socketDatas.current[login] = defaultState.serverData;
  };

  const handleMessagePress = (login: string) => async () => {
    const userDetails = messages[login];

    const data = {
      login,
      userId: userDetails.id,
    };

    localStorage.setItem(AUTH_OVERRIDE_KEY, JSON.stringify(data));
    window.open('/connect?redirect=AuthOverride', '_blank');
  };

  const isUserOffline = (login: string) => {
    return !Object.entries(socketDatas.current[login]?.Devices || {}).some(
      ([id, d]) => id !== UNIQUE_ID && d.State === DeviceState.Online,
    );
  };

  const isConnectingToUser = (login: string) => {
    return Object.entries(socketDatas.current[login]?.Devices || {}).length === 0;
  };

  return (
    <div>
      <div className={styles.list}>
        {onlineUsers.length === 0 && <Text style={chatBoxStyles.chatName}>No online users</Text>}
        {onlineUsers
          .filter(u => !messages[u.login])
          .map(u => (
            <Button
              key={u.login}
              onPress={handleInitialize(u)}
              secondary
              icon={<PlusIcon width={20} fill={mixins.color.whiteText} />}
            >
              {u.login}
            </Button>
          ))}
      </div>
      <div className={styles.grid}>
        {Object.entries(messages).map(([login, chat]) => {
          return (
            <View style={chatBoxStyles.chat} key={login}>
              <View style={chatBoxStyles.chatNameRow}>
                <Text style={chatBoxStyles.chatName}>{login}</Text>
                <Button
                  secondary
                  onPress={handleChatClose(login)}
                  icon={<CloseIcon fill={mixins.color.white} width={16} />}
                />
              </View>
              <View style={chatBoxStyles.chatInner}>
                {chat.loading ? (
                  <View style={chatBoxStyles.alert}>
                    <Loader noMargin />
                    <Text style={chatBoxStyles.chatName}>Waiting for messages...</Text>
                  </View>
                ) : (
                  <ChatBoxView
                    key={login}
                    authors={chat.authors}
                    messages={[...chat.messages.slice().reverse()]}
                    targets={serverData.Targets}
                    onMessagePress={handleMessagePress(login)}
                  />
                )}
                {isConnectingToUser(login) ? (
                  <div className={styles.userOffline}>Connecting...</div>
                ) : (
                  isUserOffline(login) && (
                    <div className={styles.userOffline}>User is now offline</div>
                  )
                )}
              </View>
            </View>
          );
        })}
      </div>
    </div>
  );
};
