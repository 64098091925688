import { AutoLoginState } from '#/socket/document.type';

export const AuthStateToText = {
  [AutoLoginState.Unknown]: 'State is unknown',
  [AutoLoginState.InProgress]: 'Authenticating...',
  [AutoLoginState.Authenticated]: 'Getting config...',
  [AutoLoginState.NotAuthenticated]: 'Not authenticated',
  [AutoLoginState.KeyObtained]: 'Logged in',
  [AutoLoginState.KeyNotFound]: 'Config not obtained',
};

export const isErrorAuthState = (state: AutoLoginState | undefined) =>
  state ? [AutoLoginState.KeyNotFound, AutoLoginState.NotAuthenticated].includes(state) : false;
