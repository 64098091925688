/* eslint-disable camelcase */
import type { Me, PaykickstartProduct, Tariff } from '../../api/types';

export const cleanName = (name: string) =>
  name.replace(/(\([\w\s]+\)|(: [\w\s]+))|(\| [\w\s]+)/gi, '').trim();

export type WithPK<T> = T & { el?: { dataset: any } };

export const prepareForPaykickstartModal = (me: Me, pk: PaykickstartProduct, qty?: number) => {
  const w = window as any;
  const hash = `productPK${pk.id}`;

  const custom_fields = {
    userName: me?.login,
    id: me?.userId,
    email: me?.email,
    first_name: me?.login,
    last_name: me?.login,
    qt: qty,
  };

  w.PKWidgetsData = w.PKWidgetsData || {};

  w.PKWidgetsData[hash] = {
    host: 'https://app.paykickstart.com',
    fpd: pk.funnelId,
    custom_fields,
  };

  const el = {
    dataset: { pkHash: hash, pkIsInited: '1' },
  };

  if (!document.getElementById(`pk-widget-container-${hash}`)) {
    w.PKWIDGET?.initCss(hash);
    w.PKWIDGET?.createIFrame(el, hash, false);
  }

  return el;
};

export const prepareTariffsForPaykickstartModal = (
  me: Me | undefined,
  tariffs: Tariff[] | undefined,
): WithPK<Tariff>[] => {
  if (!me || !tariffs) {
    return [];
  }

  return tariffs.map(tariff => {
    if (!tariff.PaykickstartProduct) {
      return tariff;
    }

    return {
      ...tariff,
      el: prepareForPaykickstartModal(me, tariff.PaykickstartProduct),
    };
  });
};

export const prepareDepositsForPaykickstartModal = (
  me: Me | undefined,
  deposits: PaykickstartProduct[] | undefined,
): WithPK<PaykickstartProduct>[] => {
  if (!me || !deposits) {
    return [];
  }

  return deposits.map(deposit => ({
    ...deposit,
    el: prepareForPaykickstartModal(me, deposit),
  }));
};

export const getTooltipForLine = (line: string) => {
  const [withBrackets, inBrackets] = line.match(/\((.*)\)/) || ['', ''];
  const normalizedLine = line.toLowerCase().replace(withBrackets, '');

  if (normalizedLine.includes('vpn')) {
    return `Protect your live stream and other traffic with a secured and encrypted connection. Available in Streamster Desktop app only. ${inBrackets}`;
  }
  if (normalizedLine.includes('channels')) {
    return `Number of platforms you are able to stream to simultaneously, any RTMP endpoints. ${inBrackets}`;
  }
  if (normalizedLine.includes('transcoding')) {
    return `Stream with 2 different quality parameters sets to different platforms. ${inBrackets}`;
  }
  if (normalizedLine.includes('bitrate')) {
    return `Maximum streaming bitrate for each channel. ${inBrackets}`;
  }
  if (normalizedLine.includes('original')) {
    return `Limitations for the 1st set of preferences, if the Transcoding is active ${inBrackets}`;
  }
  if (normalizedLine.includes('transcoded')) {
    return `Settings limitations for transcoded stream (set #2 of preferences), if transcoding is active. ${inBrackets}`;
  }
  if (normalizedLine.includes('bandwidth')) {
    return `Pay once a month, create unlimited streams. ${inBrackets}`;
  }

  return inBrackets;
};
