import { StyleSheet } from 'react-native';
import mixins, { adaptiveSize } from '../../app/styles';

const styles = StyleSheet.create({
  indicatorContent: {
    alignItems: 'center',
    marginHorizontal: 5,
  },
  indicatorContentLandscape: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  indicatorText: {
    marginTop: adaptiveSize(5),
    fontSize: adaptiveSize(18),
    color: '#268000',
    fontWeight: 'bold',
  },
  indicatorTextLandscape: {
    paddingBottom: mixins.indent.i3,
    marginLeft: mixins.indent.i1,
  },
});

export default styles;
