import mixins from '#/app/styles';
import { StyleSheet, Text } from 'react-native';

const styles = StyleSheet.create({
  outer: {
    backgroundColor: mixins.color.hotOrange,
    color: mixins.color.whiteText,
    position: 'absolute',
    padding: 2,
    paddingHorizontal: 4,
    textTransform: 'uppercase',
    fontSize: 10,
    right: -15,
    top: -12,
    borderRadius: 3,
    zIndex: 1
  },
});

export const BetaBadge = () => {
  return <Text style={styles.outer}>Beta</Text>;
};
