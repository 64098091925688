import React, { useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import ChevronUp from '../../../assets/icons/chevron-up.svg';
import mixins from '../../../app/styles';
import styles from './Tab.styles';

const Tab = ({ title, children, defaultOpen = false }) => {
  const [isOpen, setOpen] = useState(defaultOpen);

  return (
    <View style={styles.wrapper}>
      <TouchableOpacity
        style={mixins.styles.cursorPointer}
        onPress={() => setOpen(state => !state)}
      >
        <Text style={styles.title}>{title}</Text>
      </TouchableOpacity>
      <View style={styles.toggleWrapper}>
        <TouchableOpacity onPress={() => setOpen(state => !state)}>
          <View>
            <ChevronUp fill={mixins.color.white} style={isOpen ? styles.icon : styles.iconActive} />
          </View>
        </TouchableOpacity>
      </View>
      {isOpen && <View style={styles.contentWrapper}>{children}</View>}
    </View>
  );
};

export default Tab;
