import React from 'react';
import { View } from 'react-native';

import type { Props } from './types';

export const LinearGradient: React.FC<Props> = ({ children, colors, style }) => (
  <View
    style={{
      ...style,
      // background is not valid option in types, ignore it
      ['background' as any]: `linear-gradient(${colors.join(', ')})`,
    }}
  >
    {children}
  </View>
);
