import App from './app/app';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';

if (import.meta.env.MODE === 'production' && import.meta.env.APP_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.APP_SENTRY_DSN,
    integrations: [],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
