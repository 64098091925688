import { Platform, StyleSheet } from 'react-native';
import mixins from '../app/styles';
import { IS_IOS, HAS_NOTCH } from '../utils';

const IOS_INDENT = HAS_NOTCH ? mixins.indent.i10 : mixins.indent.i7;

const isWeb = Platform.OS === 'web';

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 0,
    zIndex: 99000,
    paddingVertical: mixins.indent.i7,
    paddingTop: mixins.indent.i7 + mixins.indent.i2,
    height: IS_IOS ? 'auto' : 150,
    width: '100%',
    ...(isWeb
      ? { bottom: 0, pointerEvents: 'none' }
      : {
          top: IS_IOS ? IOS_INDENT : 0,
        }),
  },
  messageBlock: {
    shadowColor: mixins.color.dark,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 2.2,
    position: 'absolute',
    top: 0,
    maxWidth: 500,
    width: '100%',
    paddingHorizontal: mixins.indent.i5,
    ...(isWeb && { left: '50%', marginLeft: -250, borderRadius: 10, pointerEvents: 'auto' }),
  },
  messageBlockMobileWeb: {
    left: '0',
    borderRadius: 10,
    marginLeft: 0,
    pointerEvents: 'auto',
  },
  messageGradient: {
    elevation: 5,
    width: '100%',
    backgroundColor: 'white',
    marginBottom: 4,
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  icon: {
    fontSize: 32,
    color: 'white',
    marginRight: mixins.indent.i3,
  },
  heading: {
    paddingRight: mixins.indent.i4,
    fontSize: mixins.font.defaultFont,
    color: mixins.color.dark,
    lineHeight: mixins.lineHeight.big,
    fontWeight: 'bold',
    paddingBottom: mixins.indent.i2,
    flex: 1,
  },
  text: {
    fontSize: mixins.font.bigFont,
    color: mixins.color.dark,
    lineHeight: mixins.lineHeight.big,
    flex: 1,
    paddingRight: mixins.indent.i4,
  },
  textWhite: {
    color: mixins.color.white,
  },
  textWrap: {
    flex: 1,
    padding: mixins.indent.i7,
  },
});

export default styles;
