import { Types } from 'ably';
import { useCallback, useContext, useEffect } from 'react';
import { useMeQuery } from '../../api/api.portal';
import { AuthContext } from '../../screens/auth/auth.utils';
import { useGlobalModalStore } from '../GlobalModal/store';
import { Text } from 'react-native';
import mixins from '../../app/styles';
import { SocketContext } from '../../socket/socket.provider';
import { StyleSheet } from 'react-native';
import { AblyTokenRef, ably } from './ably';
import { navigationRef } from '../../navigation/navigation.ref';
import { useIsBusinessUser } from '../../hooks';

const styles = StyleSheet.create({
  modalText: {
    textAlign: 'center',
    fontSize: 16,
    color: mixins.color.whiteText,
  },
});

interface PaykickstartPaymentReceived {
  type: 'PaykickstartPaymentReceived';
  data: {
    transactionId: string;
    paymentId: string;
    isProcessed: boolean;
    type: 'tariff' | 'one-time';
  };
}

interface AccountActivated {
  type: 'AccountActivated';
  data: {};
}

type AblyMessageData = PaykickstartPaymentReceived | AccountActivated;
interface AblyMessage extends Types.Message {
  data: AblyMessageData;
}

const AblyRealtime = () => {
  const { cleanServerData } = useContext(SocketContext);
  const isBusinessUser = useIsBusinessUser();
  const { isAuth, webAuth, logout, accountActivated } = useContext(AuthContext);
  const { data: me } = useMeQuery({ enabled: isAuth });
  const globalModal = useGlobalModalStore();

  const handleMessage = useCallback(
    (payload: AblyMessage) => {
      const message = payload.data;

      switch (message.type) {
        case 'AccountActivated': {
          accountActivated();
          break;
        }
        case 'PaykickstartPaymentReceived': {
          const navState = navigationRef.current?.getCurrentRoute();

          if (navState?.name === 'ThanksForSubscribing') {
            if (navState.params && 'paymentId' in navState.params) {
              return;
            }

            return navigationRef.current?.reset({
              routes: [
                {
                  name: 'ThanksForSubscribing',
                  params: message.data,
                },
              ],
            });
          }

          if (message.data.type === 'one-time') {
            return globalModal.show({
              title: 'Thanks for topping up your account',
              rawContent: true,
              children: (
                <Text style={styles.modalText}>Your account has been successfully topped up.</Text>
              ),
              dismissText: 'Close',
            });
          }

          globalModal.show({
            title: 'Your pricing plan was changed',
            children: (
              <>
                <Text style={styles.modalText}>
                  If you’re using the Streamster app and it is running, please relaunch it for
                  changes to take effect.
                </Text>
                <Text style={styles.modalText}>
                  Please note, that the subscription will be renewed in 1 month unless you disable
                  the automatic renewal in your pricing plan settings.
                </Text>
              </>
            ),
            onDismiss: undefined,
            confirmText: 'Go back to the app',
            onConfirm: () => {
              cleanServerData();
              logout();

              setTimeout(() => {
                navigationRef.current?.reset({ routes: [{ name: 'SignIn' }] });
              });
            },
          });

          break;
        }
        default: {
          console.log('Unknown message', message);
        }
      }
    },
    [cleanServerData, globalModal, logout],
  );

  useEffect(() => {
    let channel: Types.RealtimeChannelPromise | undefined;

    if (webAuth?.accessToken && isAuth && me && !isBusinessUser && !webAuth.admin) {
      AblyTokenRef.token = webAuth?.accessToken!;

      ably.auth.authorize().then(async () => {
        channel = ably.channels.get(`user#${me?.userId}`);
        channel.subscribe(handleMessage);
      });
    }

    if (!webAuth?.accessToken) {
      AblyTokenRef.token = '';
      ably.close();
    }

    return () => {
      channel?.detach();
    };
  }, [isAuth, webAuth, me, handleMessage, isBusinessUser]);

  return null;
};

export default AblyRealtime;
