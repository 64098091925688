import { Scene, SceneItem, SceneItemSource, Target, TargetFlags } from '../socket/document.type';
import { getDeviceId } from '../utils';

export const getInputSource = (DeviceName: { DeviceId: string; Name: string }) =>
  ({
    Device: {
      DeviceName,
    },
    Image: null,
    Lovense: null,
    Web: null,
    CaptureDisplay: null,
    CaptureWindow: null,
    Capture: null,
  } as SceneItemSource);

export const getEmptyLayerWithDevice = (DeviceName: { DeviceId: string; Name: string }) =>
  ({
    Ptz: { T: 0, L: 0, W: 1, H: 1 },
    Rect: { T: 0, L: 0, W: 1, H: 1 },
    Source: getInputSource(DeviceName),
    Visible: true,
    Filters: {},
  } as SceneItem);

export const getCameraDevice = (DeviceId: string, Name: string) => ({
  DeviceId,
  Name,
});

export const FRONT_CAMERA_DEVICE = getCameraDevice('@camera:front', 'Front camera');
export const BACK_CAMERA_DEVICE = getCameraDevice('@camera:back', 'Back camera');

export const DEFAULT_VIDEO_INPUTS = {
  '@camera:front': {
    Name: 'Front camera',
    State: 5,
    Type: 1,
  },
  '@camera:back': {
    Name: 'Back camera',
    State: 5,
    Type: 1,
  },
};

export const getDefaultScene = () =>
  ({
    Audios: {},
    Items: { '0': getEmptyLayerWithDevice(FRONT_CAMERA_DEVICE) },
    Owner: getDeviceId(),
  } as Scene);

export const CustomTarget: Target = {
  DefaultRtmpUrl: '',
  Flags: TargetFlags.None,
  Hint: '',
  Id: 'custom',
  Name: 'Custom',
  WebUrl: '',
};

export const DefaultTranscoder = {
  Bitrate: 2500,
  Fps: 30,
  Resolution: { Height: 720, Width: 1280 },
};
