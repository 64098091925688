import { UserMonitoringIngestState, UserMonitoringUserSessionState } from '#/api/types';
import mixins from '#/app/styles';
import { ActivityStatus } from '#/components/ActivityStatus/ActivityStatus';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import BroadcastIcon from '#/assets/icons/broadcast.svg';
import ImageAreaIcon from '#/assets/icons/image-area.svg';
import { IngestItem } from '#/components/BusinessUserList/IngestItem';
import { useAppNavigation } from '#/navigation/navigation.ref';
import { BusinessUserListItem } from '#/hooks/use-business-users';
import Tooltip from '#/components/Tooltip';

dayjs.extend(relativeTime);

const styles = StyleSheet.create({
  item: {
    backgroundColor: mixins.color.darker,
    padding: 10,
    flexDirection: 'row',
    borderWidth: 2,
    borderColor: mixins.color.dark,
    borderStyle: 'solid',
  },
  list: {
    flexDirection: 'column',
    gap: 5,
  },
  login: {
    color: mixins.color.whiteText,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  status: {
    color: mixins.color.grayDarkest,
    fontWeight: 'bold',
  },
  loginColumn: {
    width: 100,
    marginHorizontal: mixins.indent.i5,
  },
  actionsColumn: {
    marginLeft: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: -2,
  },
  ingestList: {
    gap: 5,
    flexDirection: 'row',
    overflow: 'hidden',
    flexShrink: 1,
    paddingRight: 10,
  },
  active: {
    borderColor: mixins.color.blue,
  },
  noStreamText: {
    maxWidth: 80,
    color: mixins.color.grayMedium,
    fontWeight: 'bold',
    lineHeight: 16,
  },
});

interface Props {
  users: BusinessUserListItem[];
  selectedUserId: number | null;
  selectUser: (id: string | number | null, login?: string) => void;
}

export const BusinessUserList: React.FC<Props> = ({ users = [], selectedUserId, selectUser }) => {
  const navigation = useAppNavigation();

  return (
    <View style={styles.list}>
      {users.map((user, i) => (
        <Pressable
          key={`${user.id}${i}`}
          style={[styles.item, selectedUserId === user.id && styles.active]}
          onPress={() => selectUser(user.id, user.login)}
        >
          <ActivityStatus active={user.isOnline} letter={user.admin ? 'A' : undefined} />
          <View style={styles.loginColumn}>
            <Text style={styles.login}>{user.login}</Text>
            <Text style={styles.status}>
              {user.isOnline ? `online: ${user.server}` : 'offline'}
            </Text>
          </View>
          {user.isOnline && (
            <>
              {user.ingest?.state === UserMonitoringIngestState.Offline ? (
                <Text style={styles.noStreamText}>No stream to cloud</Text>
              ) : (
                <View style={styles.ingestList}>
                  {user.ingest && <IngestItem value={user.ingest} />}
                  {user.channels.map((channel, i) => (
                    <IngestItem key={i} value={channel} />
                  ))}
                </View>
              )}
            </>
          )}
          {!user.isOnline && (
            <Text style={styles.status}>
              Last session: {user.stop ? dayjs().to(dayjs(user.stop)) : 'unknown'}
            </Text>
          )}
          <View style={styles.actionsColumn}>
            <Tooltip text="Preview">
              <Pressable
                disabled={!user.isStreaming}
                style={({ hovered }) => ({
                  opacity: user.isStreaming ? (hovered ? 1 : 0.2) : 0.09,
                })}
                onPress={() => {
                  if (user.webRtcPage) {
                    window.open(user.webRtcPage, '_blank');
                  }
                }}
              >
                <ImageAreaIcon fill="#fff" width={18} />
              </Pressable>
            </Tooltip>
            <Tooltip text="Live Stream">
              <Pressable
                disabled={user.state === UserMonitoringUserSessionState.Offline}
                style={({ hovered }) => ({
                  opacity:
                    user.state === UserMonitoringUserSessionState.Offline
                      ? 0.09
                      : hovered
                      ? 1
                      : 0.2,
                })}
                onPress={() => {
                  selectUser(user.id, user.login);
                  navigation.navigate('ActiveStream');
                }}
              >
                <BroadcastIcon fill="#fff" width={20} />
              </Pressable>
            </Tooltip>
          </View>
        </Pressable>
      ))}
    </View>
  );
};
