import { Platform, StyleSheet } from 'react-native';
import mixins from '../../app/styles';

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: Platform.OS === 'web' ? 'row' : 'column',
    marginBottom: 15,
  },
  rowSpaced: {
    marginTop: 25,
  },
  label: {
    color: mixins.color.whiteText,
    minWidth: 150,
    fontSize: 16,
    lineHeight: 39,
  },
  input: {
    color: mixins.color.input,
    paddingHorizontal: 10,
    paddingVertical: 10,
    flexGrow: 1,
    backgroundColor: mixins.color.darker,
  },
  wrapper: {
    maxWidth: 650,
  },
  button: {
    marginLeft: Platform.OS === 'web' ? 15 : 0,
  },
  actionButton: {
    height: 39,
  },
  deleteButton: {
    backgroundColor: mixins.color.red,
    borderColor: mixins.color.red,
    marginTop: mixins.indent.i5,
  },
  value: {
    color: mixins.color.white,
    minWidth: 120,
    fontSize: 16,
  },
  deleteText: {
    color: mixins.color.whiteText,
    fontSize: 18,
    textAlign: 'center',
  },
  whiteText: {
    color: mixins.color.whiteText,
  },
  redText: {
    fontSize: 16,
    textAlign: 'center',
    marginTop: 25,
    color: mixins.color.red,
    fontWeight: 'bold',
  },

  deleteCheckbox: {
    paddingVertical: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 25,
  },
  deleteTextCheckbox: {
    color: mixins.color.whiteText,
    textAlign: 'left',
    marginLeft: 20,
    fontSize: 14,
  },
  checkbox: {
    width: 20,
    height: 20,
  },
});

export default styles;
