import { StyleSheet } from 'react-native';
import {
  CHANNEL_BOX_HEIGHT,
  CHANNEL_BOX_WIDTH,
} from '../../../components/ChannelToggle/ChannelToggle.styles';
import mixins from '../../../app/styles';

const styles = StyleSheet.create({
  channelList: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addChannelWrapper: {
    width: CHANNEL_BOX_WIDTH,
    height: CHANNEL_BOX_HEIGHT,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  grayText: {
    color: mixins.color.grayDarker,
    marginVertical: 10,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
});

export const webStyles = { downloadButtons: { marginBottom: 15, marginRight: 15 } };

export default styles;
