export const formatUsage = (val: number | null) => {
  if (!val) {
    return `0m`;
  }

  const hours = Math.floor(val / 60);
  const minutes = Math.round(val % 60);

  return hours ? `${hours}h ${minutes}m` : `${minutes}m`;
};
