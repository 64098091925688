import { Pressable } from 'react-native';
import DotsIcon from '#/assets/icons/dots-vertical.svg';
import mixins from '#/app/styles';
import { StyleSheet } from 'react-native';
import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

const styles = StyleSheet.create({
  optionStyle: {
    flexDirection: 'row',
    gap: 10,
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
});

interface Option {
  title: string;
  onPress: () => void;
  disabled?: boolean;
  icon: React.FC<React.SVGProps<SVGSVGElement> & { title?: string }>;
}

interface Props {
  options: Option[];
}

export const OptionsMenu: React.FC<Props> = ({ options = [] }) => {
  const [isOpened, setIsOpened] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [{ top, left }, setOffset] = useState({ top: 0, left: 0 });

  const handleOpen: MouseEventHandler<HTMLDivElement> = e => {
    const viewportOffset = e.currentTarget.getBoundingClientRect();
    const { top, left, width, height } = viewportOffset;

    setOffset({ top: top + height + 5, left: left + width / 2 });
    setIsOpened(opened => !opened);
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (!dropdownRef.current?.contains(e.target as Node)) {
        setIsOpened(false);
      }
    };

    document.body.addEventListener('click', handleClick);

    return () => document.body.removeEventListener('click', handleClick);
  }, [isOpened]);

  return (
    <>
      <div onClick={handleOpen}>
        <DotsIcon height={25} fill={mixins.color.whiteText} />
      </div>
      {isOpened &&
        ReactDOM.createPortal(
          <div
            ref={dropdownRef}
            style={{
              top,
              left,
              position: 'absolute',
              background: '#000',
              color: mixins.color.white,
              transform: 'translateX(-50%)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {options.map(option => (
              <Pressable
                key={option.title}
                disabled={option.disabled}
                style={({ hovered }) => ({
                  ...styles.optionStyle,
                  backgroundColor: !option.disabled && hovered ? mixins.color.grayDark : undefined,
                  ...(option.disabled && { opacity: 0.5 }),
                })}
                onPress={() => {
                  setIsOpened(false);
                  option.onPress();
                }}
              >
                {<option.icon fill={mixins.color.whiteText} width={20} />}
                {option.title}
              </Pressable>
            ))}
          </div>,
          document.querySelector('#root'),
        )}
    </>
  );
};
