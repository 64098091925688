import mixins from '#/app/styles';
import { Platform, StyleSheet } from 'react-native';

const BORDER_RADIUS = 10;

export const styles = StyleSheet.create({
  messageWrapper: {
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginVertical: 3,
  },
  message: {
    backgroundColor: '#152530',
    borderRadius: BORDER_RADIUS,
    padding: 5,
    paddingHorizontal: 10,
    fontWeight: '400',
    borderTopLeftRadius: 0,
    maxWidth: '100%',
  },
  tipMessage: {
    backgroundColor: '#982e27',
  },
  privateTipMessage: {
    backgroundColor: '#450005',
  },
  myMessageWrapper: {
    justifyContent: 'flex-end',
  },
  privateMessageWrapper: {
    backgroundColor: '#000',
    borderWidth: 1,
    borderColor: mixins.color.grayDarker,
  },
  myMessage: {
    backgroundColor: '#153025',
    borderTopRightRadius: 0,
    borderTopLeftRadius: BORDER_RADIUS,
  },
  author: {
    fontSize: 12,
    color: '#9ea106',
  },
  messageText: {
    fontSize: 16,
    color: mixins.color.whiteText,
  },
  messageList: {
    gap: 3,
  },
  inputRow: {
    flexDirection: 'row',
    marginTop: 'auto',
    padding: 15,
    flexShrink: 0,
  },
  input: {
    backgroundColor: '#0a0d16',
    color: mixins.color.whiteText,
    padding: 5,
    paddingHorizontal: 15,
    flexGrow: 1,
    borderTopLeftRadius: Platform.OS === 'web' ? 0 : 25,
    borderBottomLeftRadius: Platform.OS === 'web' ? 0 : 25,
    paddingRight: 35,
    flexShrink: 1,
    flex: 1,
  },
  replyInput: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  button: {
    padding: 0,
    borderRadius: Platform.OS === 'web' ? 0 : 25,
    marginLeft: -25,
  },
  wrapper: {
    flex: 1,
  },
  scrollViewStyles: {
    flex: 1,
  },
  scrollViewContainer: {
    padding: 15,
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  placeholderText: {
    margin: 'auto',
    color: mixins.color.whiteText,
    fontSize: mixins.font.bigFont,
  },
  replyToWrapper: {
    borderTopLeftRadius: 25,
    borderBottomLeftRadius: 25,
    backgroundColor: '#000',
    borderWidth: 1,
    paddingTop: 5,
    borderColor: mixins.color.grayDarker,
    paddingLeft: 15,
    paddingRight: 30,
  },
  replyToTitle: {
    color: '#9ea106',
    fontSize: 12,
    lineHeight: 12,
  },
  replyToName: {
    lineHeight: 16,
    fontWeight: 'bold',
    color: mixins.color.whiteText,
  },
  replyToClose: {
    position: 'absolute',
    right: 4,
    top: 7,
    width: 20,
    height: 22,
  },
});
