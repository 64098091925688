import React, { useContext, useEffect, useState } from 'react';
import { View, Text, ScrollView } from 'react-native';
import ArrowLeftIcon from '../../assets/icons/arrow-left.svg';

// context
import { ToasterContext } from '../../toaster/toaster.provider';

// styles
import styles from './activate.styles';
import { AuthContext } from '../auth/auth.utils';
import { useIsMobile } from '../../utils';
import mixins from '../../app/styles';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import { useAppNavigation, useAppRoute } from '#/navigation/navigation.ref';

const ActivateScreen = () => {
  const isMobile = useIsMobile();
  const navigation = useAppNavigation();
  const route = useAppRoute<'Activate'>();
  const [codeInvalid, setCodeInvalid] = useState(false);
  const { success } = useContext(ToasterContext);
  const { activate, isAuth, accountActivated } = useContext(AuthContext);

  useEffect(() => {
    if (route.params?.code) {
      activate(route.params.code)
        .then(() => {
          if (isAuth) {
            setTimeout(() => {
              success({ message: 'Your account was activated' });
              accountActivated();
              navigation.navigate('Home');
            }, 100);
          } else {
            navigation.navigate('SignIn', { handler: 'activated' });
          }
        })
        .catch(() => {
          setCodeInvalid(true);
        });
    }
  }, [route.params?.code]);

  return (
    <ScrollView>
      <View style={styles.webWrapper}>
        <View style={[styles.box, isAuth && { marginTop: 200, paddingTop: 30 }]}>
          {!isAuth && (
            <ArrowLeftIcon
              onClick={() => navigation.navigate('SignIn')}
              style={styles.goBackIcon}
            />
          )}
          <Text style={[styles.webHeader, isMobile && mixins.styles.margin.topI6]}>
            Account activation
          </Text>
          {route.params?.code && !codeInvalid ? (
            <>
              <Text style={styles.webSubHeader}>Please wait...</Text>
              <Loader style={mixins.styles.margin.topI12} />
            </>
          ) : (
            <>
              <Text style={styles.webSubHeader}>
                Your activation link is invalid, please try again
              </Text>
              {!isAuth && (
                <Button
                  style={mixins.styles.margin.topI12}
                  onPress={() => navigation.navigate('SignIn')}
                >
                  Go to login screen
                </Button>
              )}
            </>
          )}
        </View>
      </View>
    </ScrollView>
  );
};

export default ActivateScreen;
