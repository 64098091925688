import mixins from '#/app/styles';
import Button from '#/components/Button/Button';
import Loader from '#/components/Loader/Loader';
import { useAppNavigation } from '#/navigation/navigation.ref';
import { Chat, ChatState } from '#/socket/document.type';
import { closeChatAction, reconnectPlatformChatAction } from '#/socket/socket.actions';
import { SocketContext } from '#/socket/socket.provider';
import { useContext } from 'react';
import { View, Text, StyleSheet, Platform } from 'react-native';
import CloseIcon from '#/assets/icons/close.svg';

const styles = StyleSheet.create({
  statusWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    gap: 15,
    padding: Platform.OS === 'web' ? 15 : 0,
  },
  statusText: { color: mixins.color.whiteText, paddingHorizontal: 25, textAlign: 'center' },
});

export const ChatBoxStatusDisplay = ({ chat, chatId }: { chat?: Chat; chatId?: string }) => {
  const navigation = useAppNavigation();
  const { sendPatch } = useContext(SocketContext);

  const loginButtonVisibleFor = [
    ChatState.NotAuthenticated,
    ChatState.ConnectionFailed,
    ChatState.ConnectionNotInitialized,
  ];
  const reconnectButtonVisibleFor = [
    ChatState.ConnectionFailed,
    ChatState.ConnectionNotInitialized,
  ];

  if (!chat) {
    return <Loader />;
  }

  const handleLoginClicked = () => {
    navigation.navigate('WebLogin', { page: chat.TargetId.toLowerCase() });
  };

  const handleReconnectClicked = () => {
    if (!chatId) {
      return;
    }

    sendPatch(reconnectPlatformChatAction(chatId));
  };

  const handleCloseChat = () => {
    if (!chatId) {
      return;
    }

    sendPatch(closeChatAction(chatId));
  };

  const shouldShowLoginButton = loginButtonVisibleFor.includes(chat.State);

  return (
    <View style={styles.statusWrapper}>
      <Text style={styles.statusText}>{chat.StateDetails}</Text>
      {shouldShowLoginButton && Platform.OS !== 'web' && (
        <Button onPress={handleLoginClicked}>Login</Button>
      )}
      {shouldShowLoginButton && Platform.OS === 'web' && (
        <Text>
          <a
            href="https://docs.streamster.io/docs/in-browser-app/features-5820/chats-9270/"
            target="_blank"
          >
            Use Streamster desktop or mobile app to authenticate
          </a>
        </Text>
      )}
      {reconnectButtonVisibleFor.includes(chat.State) && (
        <Button onPress={handleReconnectClicked}>Reconnect</Button>
      )}
      {chat.StateDetails && (
        <Button
          onPress={handleCloseChat}
          color="red"
          icon={<CloseIcon width={20} height={20} fill={mixins.color.whiteText} />}
        >
          Close chat
        </Button>
      )}
    </View>
  );
};
