import { createZustandStore } from '#/utils/zustand';

interface Store {
  currentScreen: 'channel' | 'sources' | 'zoom' | 'allChats' | null;
  expanded: boolean;
  channelToEdit: string | null;
  channelScreen: 'CHATS' | 'SETTINGS';
  close: () => void;
}

export const useManageBoxControls = createZustandStore(
  'ManageBoxControls',
  {
    currentScreen: null,
    expanded: false,
    channelToEdit: null,
    channelScreen: 'SETTINGS' as const,
  } as Store,
  (set, get) => ({
    toggleExpand: () => set({ expanded: !get().expanded }),
    setChannelScreen: (channelScreen: Store['channelScreen']) => set({ channelScreen }),
    close: () => set({ currentScreen: null, channelToEdit: null }),
    editChannel: (channelToEdit: string, channelScreen: Store['channelScreen'] = 'SETTINGS') =>
      get().channelToEdit === channelToEdit
        ? get().close()
        : set({ channelToEdit, currentScreen: 'channel', channelScreen }),
    showSources: () => set({ currentScreen: 'sources' }),
    toggleSources: () =>
      set({ currentScreen: get().currentScreen !== 'sources' ? 'sources' : null }),
    toggleZoom: () => set({ currentScreen: get().currentScreen !== 'zoom' ? 'zoom' : null }),
    toggleAllChats: () =>
      set({
        currentScreen: get().currentScreen !== 'allChats' ? 'allChats' : null,
        channelToEdit: undefined,
      }),
  }),
);
