import React, { useContext } from 'react';
import { Text } from 'react-native';

import { SocketContext } from '../../socket/socket.provider';

import ChannelToggle from '../ChannelToggle/ChannelToggle';

import { useIsMobile } from '../../utils';

import { CustomTarget } from '../../helpers/defaultData';
import { useToggleChannel, useUserLimits } from '#/components/ChannelList/utils';

interface Props {
  handleShowTranscoding?: () => void;
  displayChatBadges?: boolean;
}

const ChannelList: React.FC<Props> = ({ handleShowTranscoding, displayChatBadges }) => {
  const isMobile = useIsMobile();
  const {
    serverData,
    sendPatch,
    commonData: { isStreaming },
  } = useContext(SocketContext);
  const { maxChannels, maxInput, transcodingChannels } = useUserLimits();
  console.log(useUserLimits());
  const toggleChannel = useToggleChannel({
    serverData,
    maxChannels: Number(maxChannels),
    sendPatch,
  });

  if (Object.values(serverData.Targets).length === 0) {
    return <Text>Loading...</Text>;
  }

  const maxChannelsLimitReached =
    Object.values(serverData.Channels).reduce((acc, c) => acc + (c?.IsOn ? 1 : 0), 0) >=
    Number(maxChannels);

  return Object.entries(serverData.Channels)
    .filter(([_channelId, channel]) => Boolean(channel))
    .map(([channelId, channel]) => (
      <ChannelToggle
        sendPatch={sendPatch}
        target={serverData.Targets[channel.TargetId] || CustomTarget}
        id={channelId}
        handleToggleState={toggleChannel}
        data={channel}
        key={channelId}
        displayChatBadge={displayChatBadges}
        settings={serverData.Settings}
        transcodingEnabled={Number(transcodingChannels) > 0}
        isStreaming={isStreaming}
        maxActiveChannels={Number(maxChannels)}
        maxActiveChannelsReached={maxChannelsLimitReached}
        maxInput={maxInput}
        handleShowTranscoding={handleShowTranscoding}
        mobile={isMobile}
      />
    ));
};

export default ChannelList;
