import mixins from '#/app/styles';
import { useIsMobile } from '#/utils';
import { StyleSheet, Text, View } from 'react-native';

import CheckIcon from '#/assets/icons/check-bold.svg';
import InfoTooltip from '#/components/InfoTooltip/InfoTooltip';
import { ALL_FEATURES } from '#/screens/tariff/Tariff.consts';

const styles = StyleSheet.create({
  allFeaturesWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: mixins.indent.i10,
  },
  allFeaturesWrapperMobile: {
    marginTop: 25,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  allFeaturesText: {
    color: mixins.color.white,
    fontSize: mixins.font.defaultFont,
    margin: mixins.indent.i2,
  },
  allFeaturesTextMobile: {
    color: mixins.color.white,
    fontSize: mixins.font.defaultFont,
    marginBottom: mixins.indent.i5,
    paddingLeft: mixins.indent.i8,
  },
  allFeaturesFeature: {
    flexDirection: 'row',
    paddingVertical: mixins.indent.i2,
  },
  allFeaturesChekcIcon: {
    width: 24,
    height: 24,
    fill: mixins.color.white,
  },
  allFeaturesTooltipIcon: {
    width: 20,
    height: 20,
    marginLeft: mixins.indent.i7,
  },

  label: {
    color: mixins.color.whiteText,
    width: 85,
    flexShrink: 0,
    fontSize: 15,
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginRight: 5,
  },
  value: {
    color: mixins.color.grey,
    fontSize: 15,
  },
});

export const FeaturesBox = () => {
  const isMobile = useIsMobile();

  return (
    <View style={isMobile ? styles.allFeaturesWrapperMobile : styles.allFeaturesWrapper}>
      <Text style={isMobile ? styles.allFeaturesTextMobile : styles.allFeaturesText}>
        All pricing plans include
      </Text>
      <View>
        {ALL_FEATURES.map(line => (
          <View key={line.name} style={styles.allFeaturesFeature}>
            <Text style={styles.label}>
              <CheckIcon style={styles.allFeaturesChekcIcon} />
            </Text>
            <Text style={styles.value}>{line.name}</Text>
            {line.tooltip && (
              <InfoTooltip text={line.tooltip} style={styles.allFeaturesTooltipIcon} />
            )}
          </View>
        ))}
      </View>
    </View>
  );
};
