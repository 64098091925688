import { Platform, StyleSheet } from 'react-native';
import mixins from '../../app/styles';

const isWeb = Platform.OS === 'web';

export const CHANNEL_BOX_WIDTH = 260;
export const CHANNEL_BOX_HEIGHT = isWeb ? 125 : 80;

const styles = StyleSheet.create({
  editChannel: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: '#393939',
    margin: 10,
    width: CHANNEL_BOX_WIDTH,
    height: CHANNEL_BOX_HEIGHT,
    position: 'relative',
  },
  editChannelMobileWeb: {
    margin: 0,
    width: '100%',
    height: CHANNEL_BOX_HEIGHT,
    borderRadius: 10,
    overflow: 'hidden',
    borderWidth: 5,
    backgroundColor: '#404040',
    borderColor: '#404040',
    borderStyle: 'solid',
    justifyContent: 'center',
  },
  editChannelMobile: {
    margin: 0,
    height: 70,
    flex: 1,
    overflow: 'hidden',
    borderWidth: 5,
    backgroundColor: '#404040',
    borderColor: '#404040',
    borderStyle: 'solid',
    justifyContent: 'center',
  },
  mobileWebOuterWrapper: {
    borderRadius: 10,
    overflow: 'hidden',
    width: '31%',
    alignItems: 'center',
    margin: '1%',
  },
  mobileOuterWrapper: {
    overflow: 'hidden',
    width: 70,
    alignItems: 'center',
  },
  mobileOuterLandscape: {
    overflow: 'hidden',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'row',
  },
  loaderMobile: {
    marginBottom: 10,
  },
  editChannelMobilePending: {
    borderColor: mixins.color.orange,
  },
  editChannelMobileInactive: {
    borderColor: '#404040',
    paddingBottom: 20,
  },
  editChannelMobileError: {
    borderColor: mixins.color.redDark,
    paddingBottom: 20,
  },
  editChannelMobileActive: {
    borderColor: mixins.color.greenDarker,
    paddingBottom: 20,
  },
  details: {
    position: 'relative',
    flexGrow: 1,
    height: CHANNEL_BOX_HEIGHT,
    padding: 5,
    paddingHorizontal: 10,
  },
  inputsWrapper: {
    marginTop: 10,
    marginBottom: 20,
  },
  imageWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    height: CHANNEL_BOX_HEIGHT,
    width: CHANNEL_BOX_HEIGHT,
    backgroundColor: mixins.color.grayDarkest,
    overflow: 'hidden',
  },
  channelImage: {
    width: CHANNEL_BOX_HEIGHT,
    height: CHANNEL_BOX_HEIGHT,
  },
  mobileChannelImage: {
    width: CHANNEL_BOX_HEIGHT - 20,
    height: CHANNEL_BOX_HEIGHT - 20,
  },
  customChannelImage: {
    width: CHANNEL_BOX_HEIGHT - 20,
    height: CHANNEL_BOX_HEIGHT - 20,
  },
  mobileCustomChannelImage: {
    width: CHANNEL_BOX_HEIGHT - 40,
    height: CHANNEL_BOX_HEIGHT - 40,
  },
  channelName: {
    fontSize: mixins.font.defaultFont,
    color: mixins.color.whiteText,
    width: CHANNEL_BOX_WIDTH - CHANNEL_BOX_HEIGHT - 40,
    overflow: 'hidden',
    paddingLeft: 2,
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexGrow: 1,
    alignSelf: 'stretch',
  },
  btnToggleState: {},

  iconLink: {
    width: 20,
    height: 20,
    left: 10,
    top: 10,
    position: 'absolute',
    cursor: 'pointer',
  },
  iconHelp: {
    width: 20,
    height: 20,
    left: 10,
    top: 40,
    position: 'absolute',
    cursor: 'pointer',
  },
  statusBadge: {
    display: 'flex',
    width: 20,
    height: 20,
    borderRadius: 50,
    position: 'absolute',
    right: 15,
    top: 15,
    borderWidth: 1,
    borderColor: mixins.color.blueLight,
  },

  buttonRow: {
    marginTop: 8,
    marginBottom: 5,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  statusBadgePending: {
    backgroundColor: mixins.color.orange,
  },
  statusBadgeWarning: {
    backgroundColor: '#404040',
    color: mixins.color.paleGold,
  },
  statusBadgeInactive: {
    backgroundColor: '#404040',
    color: mixins.color.whiteText,
  },
  statusBadgeError: {
    backgroundColor: mixins.color.redDark,
    color: mixins.color.whiteText,
  },
  statusBadgeActive: {
    backgroundColor: mixins.color.greenDarker,
    color: mixins.color.whiteText,
  },
  bitrate: {
    color: mixins.color.whiteText,
    textAlign: 'right',
    marginTop: 5,
    fontWeight: '500',
  },
  mobileBadgeStatus: {
    width: 20,
    height: 5,
    borderRadius: 5,
    marginTop: 5,
  },
  mobileBadgeStatusLandscape: {
    height: 20,
    width: 5,
    borderRadius: 5,
    marginLeft: 5,
    flexShrink: 0,
  },
  mobileOpenedStatus: {
    width: '100%',
    height: 4,
    marginBottom: 5,
    backgroundColor: 'transparent',
  },
  mobileOpenedStatusLandscape: {
    height: '100%',
    width: 4,
    marginRight: 5,
    backgroundColor: 'transparent',
  },
  mobileOpenedStatusActive: {
    backgroundColor: mixins.color.blue,
  },
  statusText: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    textAlign: 'center',
    padding: isWeb ? 3 : 0,
    paddingTop: isWeb ? 0 : 3,
    marginBottom: isWeb ? -5 : 0,
    fontWeight: '500',
    fontSize: isWeb ? 14 : 10,
    zIndex: 10,
  },
  toggleButton: {
    backgroundColor: mixins.color.white,
    borderRadius: 50,
    width: 30,
    height: 30,
  },
  toggleLoading: {
    width: 24,
    height: 24,
    marginLeft: 10,
    marginRight: 0,
  },
  removeIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: mixins.color.redDark,
    borderRadius: 2,
  },
  blockMessage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: mixins.color.brown,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: isWeb ? 10 : 5,
  },
  blockMessageDescription: {
    color: mixins.color.orange,
    textAlign: 'center',
    fontSize: isWeb ? 14 : 12,
  },
  blockMessageTitle: {
    color: mixins.color.orange,
    fontWeight: 'bold',
    textAlign: 'center',
  },

  badgeWrapper: {
    position: 'absolute',
    top: 2,
    right: 0,
    borderRadius: 20,
    backgroundColor: '#4585a3',
  },
  badgeWrapperRed: {
    backgroundColor: mixins.color.hotOrange,
  },
  badgeText: {
    minWidth: 20,
    paddingHorizontal: 7,
    color: 'white',
    lineHeight: 20,
    textAlign: 'center',
    fontSize: 14,
  },
});

export default styles;
