import { Platform, StyleSheet } from 'react-native';
import { DEVICE_HEIGHT, DEVICE_WIDTH, MAX_WIDTH_DEVICE } from '../utils';

export function adaptiveSize(size: number): number {
  if (Platform.OS === 'web') {
    return size;
  }
  return Math.round((size * 360) / MAX_WIDTH_DEVICE);
}

const FONT_COEFFICIENT = 2;
const proportion = DEVICE_WIDTH / MAX_WIDTH_DEVICE;
const proportionText =
  Platform.OS === 'web' ? 1.3 : (1 - proportion) / FONT_COEFFICIENT + proportion;

const DIRECTIONS = ['top', 'right', 'bottom', 'left'] as const;

const color = {
  dark: '#202020',
  darker: '#191919',
  dirty: '#303030',
  green: '#54c637',
  greenLight: '#00ff00',
  greenPlay: '#1e634f',
  greenDarker: '#006400',
  greenOpaque: 'rgba(121, 255, 131, .7)',
  red: '#ff0000',
  redDark: '#aa0000',
  purple: '#542d4f',
  black: '#000000',
  grey: '#AAAAAA',
  grayDarker: '#a5a19d',
  grayMedium: '#818181',
  grayDarkest: '#404040',
  grayAnother: '#434449',
  grayLightest: '#dcdcdc',
  grayDark: '#1d1e22',
  deepBlue: '#180880',
  blue: '#1070D0',
  blueShadow: '#0d316c',
  blueDark: '#10243b',
  blueLight: '#3c6bac',
  blueBright: '#1759c5',
  white: '#E0E0E0',
  input: '#C8C8C8',
  orange: '#e0a502',
  lime: '#79ff83',
  blueLightest: '#60a8fc',
  backgroundBlue: '#0b0d14',
  backgroundBlueLight: '#11151d',
  whiteText: '#efeff0',
  channelsBlue: '#1d222b',
  border: '#131b2b',
  connection: '#0A0D14',
  previewBackground: '#090909',
  previewErrorText: '#bdbdbd',
  brown: '#604041',
  gold: '#dfbf0e',
  hotOrange: '#fe6919',
  calmOrange: '#c15d00',
  paleOrange: '#e49169',
  paleGold: '#bd9b00',
  bottomSheet: '#121926',
};

const indent = {
  i1: adaptiveSize(2),
  i2: adaptiveSize(4),
  i3: adaptiveSize(6),
  i4: adaptiveSize(8),
  i5: adaptiveSize(12),
  i6: adaptiveSize(14),
  i7: adaptiveSize(16),
  i8: adaptiveSize(20),
  i9: adaptiveSize(26),
  i10: adaptiveSize(32),
  i11: adaptiveSize(36),
  i12: adaptiveSize(45),
};

const lineHeight = {
  large: Math.floor(32 * proportionText),
  big: Math.floor(17 * proportionText),
  default: Math.floor(15 * proportionText) + 7,
};

const font = {
  titleFont: adaptiveSize(36),
  modalFont: adaptiveSize(20),
  largeFont: adaptiveSize(24),
  sectionFont: adaptiveSize(26),
  bigFont: adaptiveSize(18),
  defaultFont: adaptiveSize(16),
  middleFont: adaptiveSize(14),
  smallFont: adaptiveSize(12),
  iconFont: adaptiveSize(10),
};

const size = {
  player: adaptiveSize(270),
  joystick: 185,
  channel: adaptiveSize(120),
  joystickSmall: 175,
};

const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

const margin = StyleSheet.create(
  Object.entries(indent).reduce((acc, [name, value]) => {
    DIRECTIONS.forEach(
      dir =>
        (acc[`${dir}${capitalize(name)}`] = {
          [`margin${capitalize(dir)}`]: value,
        }),
    );

    return acc;
  }, {} as any) as Record<MarginDirVal, any>,
);

type IndentNameValue = Capitalize<keyof typeof indent>;
type MarginDirVal = `${(typeof DIRECTIONS)[number]}${IndentNameValue}`;

export const ORANGE_GRADIENT = ['#FBDCB8', '#FEFFBE'];
export const BLUE_GRADIENT = ['#BFF0FF', '#8BD5FF'];
export const GREEN_GRADIENT = ['#B8FBD7', '#DBFFBE'];
export const RED_GRADIENT = ['#f00', '#bc0a0a'];

const mixins = {
  color,
  indent,
  lineHeight,
  font,
  size,
  styles: {
    margin,
    ...StyleSheet.create({ cursorPointer: { cursor: 'pointer', display: 'flex' } }),
  },
};

export default mixins;
