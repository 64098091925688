import { View, Text } from 'react-native';
import { TooltipProps } from 'recharts';
import styles from './CustomChartTooltip.styles';

const CustomChartTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    return (
      <View style={styles.wrapper}>
        <Text style={styles.label}>Date: {label}</Text>
        <Text style={styles.value}>Usage: {payload[0].value?.toFixed(2)}h</Text>
      </View>
    );
  }

  return null;
};

export default CustomChartTooltip;
