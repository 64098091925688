import { Platform } from 'react-native';

export const validateUrl = (rmtp = '') => {
  try {
    const url = new URL(rmtp);

    return url.protocol.startsWith('http');
  } catch (_e) {
    return false;
  }
};

export const validateRMTPUrl = (rmtp = '') => {
  try {
    const url = new URL(rmtp);

    if (Platform.OS === 'web') {
      return url.protocol.startsWith('rtmp');
    }

    return typeof url === 'object' && (url as any)._url.startsWith('rtmp');
  } catch (_e) {
    return false;
  }
};
