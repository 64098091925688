import Loader from '#/components/Loader/Loader';
import { AUTH_OVERRIDE_KEY } from '#/helpers/consts';
import { useAppNavigation } from '#/navigation/navigation.ref';
import { useSelectedBusinessUserStore } from '#/screens/home/selectedBusinessUser.store';
import socket from '#/socket/socket';
import { useEffect } from 'react';

export const AuthOverrideScreen = () => {
  const nav = useAppNavigation();
  const { selectUser } = useSelectedBusinessUserStore();

  useEffect(() => {
    const rawData = localStorage.getItem(AUTH_OVERRIDE_KEY);

    if (!rawData) {
      return;
    }

    try {
      localStorage.removeItem(AUTH_OVERRIDE_KEY);
      const data = JSON.parse(rawData);
      selectUser(data.userId, data.login);
      nav.navigate('Chats');
      // socket.connectWithToken(data.token, data.userId, 'Chats');
    } catch (err) {
      nav.navigate('Home');
    }
  }, []);

  return <Loader />;
};
