import React, { ReactNode, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Text, StyleSheet, View } from 'react-native';
import styles, { css } from './Tooltip.styles';

interface Props {
  children: React.ReactChild;
  text: ReactNode;
  position?: 'bottom' | 'right';
}

interface Position {
  top: number;
  left: number;
}

const DELAY = 500;

export const Tooltip = ({ children, text, position = 'bottom' }: Props) => {
  const [isHovered, setIsHovered] = useState<Position | undefined>(undefined);
  const timeoutRef = useRef<number | undefined>();

  const showTooltip = (params: Position) => {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setIsHovered(params);
    }, DELAY) as unknown as number;
  };

  const onMouseOver: React.MouseEventHandler<HTMLDivElement> = e => {
    const viewportOffset = e.currentTarget.getBoundingClientRect();
    const { top, left, width, height } = viewportOffset;

    if (position === 'right') {
      if (left + 200 < window.innerWidth) {
        showTooltip({ top: top - 10, left: left + 40 });
      } else {
        showTooltip({ top: top + 30, left: left - 160 });
      }
    }

    if (position === 'bottom') {
      showTooltip({ top: top + height + 5, left: left + width / 2 });
    }
  };

  useEffect(() => {
    return () => clearInterval(timeoutRef.current);
  }, []);

  if (!text) {
    return children;
  }

  return (
    <div
      onMouseEnter={onMouseOver}
      onMouseLeave={() => {
        clearInterval(timeoutRef.current);
        setIsHovered(undefined);
      }}
      style={css.outerWrapper}
    >
      {children}
      {isHovered &&
        ReactDOM.createPortal(
          <View style={StyleSheet.compose(styles.tooltip, isHovered)}>
            {typeof text === 'string' ? (
              <Text style={styles.tooltipText}>{text.split('\\n').join('\n')}</Text>
            ) : (
              text
            )}
          </View>,
          document.querySelector('#root'),
        )}
    </div>
  );
};
