import React, { useState } from 'react';
import { View, Text, TextInput } from 'react-native';
import Button from '../../components/Button/Button';
import DefaultModal from '../../components/DefaultModal/DefaultModal';
import styles from './Profile.styles';
import ChangePasswordModal from '#/components/ChangePasswordModal';
import CurrentTariffInfoBox from '#/components/CurrentTariffInfoBox';
import { MigrateToBusinessBox } from './MigrateToBusinessBox';
import { ActiveTariff } from '#/api/types';
import dayjs from 'dayjs';
import { useAppNavigation } from '#/navigation/navigation.ref';
import mixins from '#/app/styles';
import { CheckBox } from 'react-native-web';

interface Props {
  user: { login: string; email?: string; tariffId: number };
  tariff: ActiveTariff | { tariffExpiresAt: string | null; name: string };
  showUpgradeToBusiness?: boolean;
  showDeleteAccountButton?: boolean;
  onChangePassword: (password: string) => void;
  onDeleteAccount: () => void;
  userIsBusiness?: boolean;
}

export const ProfileBox: React.FC<Props> = ({
  user,
  tariff,
  showUpgradeToBusiness,
  showDeleteAccountButton,
  onChangePassword,
  onDeleteAccount,
  userIsBusiness,
}) => {
  const navigation = useAppNavigation();

  const [isDeleteModalVisible, setDeleteModalVIsibility] = useState(false);
  const [isChangePasswordModalVisible, setChangePasswordModalVisibility] = useState(false);
  const [isDeleteConfirmed, setDeleteConfirmed] = useState(false);

  const handleChangePassword = (password: string) => {
    setChangePasswordModalVisibility(false);
    onChangePassword(password);
  };

  const handleDeleteAccount = () => {
    setDeleteModalVIsibility(false);
    onDeleteAccount();
  };

  return (
    <View style={styles.wrapper}>
      <View style={styles.row}>
        <Text style={styles.label}>Login</Text>
        <TextInput style={styles.input} value={user.login} editable={false} />
      </View>
      {user.email && (
        <View style={styles.row}>
          <Text style={styles.label}>Email</Text>
          <TextInput style={styles.input} value={user.email} editable={false} />
        </View>
      )}
      <View style={[styles.row, styles.rowSpaced]}>
        <Text style={styles.label}>Pricing plan</Text>
        <View style={{ flexDirection: 'row', flexGrow: 1 }}>
          <TextInput style={styles.input} value={tariff.name} editable={false} />
          <Button style={styles.button} onPress={() => navigation.navigate('Tariff')}>
            See all options
          </Button>
        </View>
      </View>
      <View style={[styles.row]}>
        <Text style={styles.label}></Text>
        {'tariffExpiresAt' in tariff ? (
          tariff.tariffExpiresAt && (
            <Text style={styles.whiteText}>
              Expires in {dayjs(tariff.tariffExpiresAt).diff(dayjs(), 'day')} days
            </Text>
          )
        ) : (
          <CurrentTariffInfoBox tariff={tariff} />
        )}
      </View>
      <View style={styles.row}></View>
      <View style={[styles.row, styles.rowSpaced]}>
        <Text style={styles.label}>Actions</Text>
        <View>
          <Button
            style={styles.actionButton}
            onPress={() => setChangePasswordModalVisibility(true)}
          >
            Change password
          </Button>
          {showDeleteAccountButton && (
            <Button
              style={[styles.actionButton, styles.deleteButton]}
              onPress={() => setDeleteModalVIsibility(true)}
            >
              Delete account
            </Button>
          )}
        </View>
      </View>
      {showUpgradeToBusiness && <MigrateToBusinessBox />}
      {isDeleteModalVisible && (
        <DefaultModal
          confirmText="Delete"
          dismissText="Cancel"
          title="Are you sure?"
          onDismiss={() => setDeleteModalVIsibility(false)}
          onConfirm={isDeleteConfirmed ? handleDeleteAccount : undefined}
        >
          <Text style={styles.deleteText}>You're about to delete your account,</Text>
          <Text style={styles.deleteText}>this step is irreversible.</Text>
          {userIsBusiness && (
            <Text style={styles.redText}>
              All sub users on your business account will also be deleted!
            </Text>
          )}
          <View style={styles.deleteCheckbox}>
            <CheckBox
              style={styles.checkbox}
              color={mixins.color.blue}
              value={isDeleteConfirmed}
              onValueChange={setDeleteConfirmed}
            />
            <Text
              style={styles.deleteTextCheckbox}
              onPress={() => setDeleteConfirmed(!isDeleteConfirmed)}
            >
              I confirm that I want do delete my account
              <br />
              and I understand the consequences.
            </Text>
          </View>
        </DefaultModal>
      )}
      {isChangePasswordModalVisible && (
        <ChangePasswordModal
          username={user.login}
          onNewPassword={handleChangePassword}
          onDismiss={() => setChangePasswordModalVisibility(false)}
        />
      )}
    </View>
  );
};
