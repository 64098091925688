import Button from '#/components/Button/Button';
import { ChatBox } from '#/components/ChatBox/ChatBox';
import Heading from '#/components/Heading/Heading';
import PageWrapper from '#/components/PageWrapper/PageWrapper';
import styles from './Chats.screen.styles';
import { SocketContext } from '#/socket/socket.provider';
import { getChannelsWithChatAvailable } from '#/utils/serverData';
import React, { useContext } from 'react';
import { View, Text } from 'react-native';
import PlusIcon from '#/assets/icons/plus.svg';
import mixins from '#/app/styles';
import { closeChatAction, initPlatformChat } from '#/socket/socket.actions';
import ChatsIcon from '../../assets/icons/message-text.svg';
import cssStyles from './Chats.screen.module.scss';
import CloseIcon from '#/assets/icons/close.svg';
import { useSocketConnectionSwitcher } from '#/utils';
import { BusinessUserSwitcher } from '#/components/BusinessUserSwitcher/BusinessUserSwitcher';

export const ChatsScreen: React.FC = () => {
  useSocketConnectionSwitcher();

  const { serverData, sendPatch } = useContext(SocketContext);
  const targetsWithChatAvailable = getChannelsWithChatAvailable(serverData);

  const chatsStarted = Object.entries(serverData.Platforms.Chats || {});
  const targetsToStart = Array.from(new Set(targetsWithChatAvailable.map(t => t.TargetId))).map(
    t => serverData.Targets[t],
  );

  const isStarted = (target: string) => {
    return chatsStarted.find(t => t[1].TargetId === target);
  };

  const handleChatClose = (targetId: string) => () => {
    const chatId = chatsStarted.find(chat => chat[1].TargetId === targetId)?.[0];

    if (!chatId) {
      return;
    }

    sendPatch(closeChatAction(chatId));
  };

  const renderContent = () => {
    if (targetsToStart.length === 0) {
      return (
        <View style={styles.alert}>
          <ChatsIcon width={64} fill={mixins.color.blueBright} />
          <Heading variant="alert">
            You didn’t set up any channels for chats or interactive overlays.
          </Heading>
          <Heading variant="alert">
            <a
              href="https://docs.streamster.io/docs/in-browser-app/features-5820/chats-9270/"
              target="_BLANK"
            >
              Learn how to make it work
            </a>
          </Heading>
        </View>
      );
    }

    return (
      <div className={cssStyles.grid}>
        {targetsToStart
          .filter(t => isStarted(t.Id))
          .map(target => (
            <View style={styles.chat} key={target.Id}>
              <View style={styles.chatNameRow}>
                <Text style={styles.chatName}>{target.Name}</Text>
                <Button
                  secondary
                  onPress={handleChatClose(target.Id)}
                  icon={<CloseIcon fill={mixins.color.white} width={16} />}
                />
              </View>
              <ChatBox targetId={target.Id} />
            </View>
          ))}
      </div>
    );
  };

  const handleInitialize = (targetId: string) => () => {
    sendPatch(initPlatformChat(targetId));
  };

  return (
    <PageWrapper style={styles.wrapper}>
      <BusinessUserSwitcher />
      <Heading variant="section">Chats</Heading>
      <View style={styles.buttonRow}>
        {targetsToStart
          .filter(t => !isStarted(t.Id))
          .map(target => (
            <Button
              key={target.Id}
              onPress={handleInitialize(target.Id)}
              secondary
              icon={<PlusIcon width={20} fill={mixins.color.whiteText} />}
            >
              {target.Name}
            </Button>
          ))}
      </View>
      {renderContent()}
    </PageWrapper>
  );
};
