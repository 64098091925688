import React from 'react';
import { styles } from './TariffBox.styles';
import { View, Text } from 'react-native';
import { Tariff } from '#/api/types';
import InfoTooltip from '#/components/InfoTooltip/InfoTooltip';
import CheckIcon from '#/assets/icons/check-bold.svg';
import Button from '#/components/Button/Button';
import { cleanName, getTooltipForLine } from '#/screens/tariff/Tariff.utils';
import mixins from '#/app/styles';

interface Props<T> {
  isActive: boolean;
  tariff: T;
  isMonthlyViewActive: boolean;
  onTariffSelected: (tariff: T) => void;
  onUpgradeTariff: () => void;
}

const generateDescription = (description: string) => {
  if (!description) {
    return null;
  }

  const lines = description.replace(/([.;])|(- )/g, '').split(/\n/);

  const jsxLines = lines
    .filter(l => !l.includes('1 month') && !l.includes('Bandwidth'))
    .map(line => {
      const [numberValue] = line.match(/(\d+[kp]\/\d+(FPS)?)|(\d+)( kbps)?/) || [''];
      const tooltip = getTooltipForLine(line);

      const textLine = line
        .replace(numberValue, '')
        .replace(':', '')
        .replace('included', '')
        .replace(/\((.*)\)/, '')
        .trim();

      if (numberValue) {
        return (
          <View key={line} style={styles.planFeature}>
            <Text style={styles.label}>{numberValue}</Text>
            <Text style={styles.value}>{textLine}</Text>
            {tooltip ? <InfoTooltip text={tooltip} style={styles.featureTooltipIcon} /> : null}
          </View>
        );
      }

      return (
        <View key={line} style={styles.planFeature}>
          <Text style={styles.label}>
            <CheckIcon style={styles.labelIcon} />
          </Text>
          <Text style={styles.value}>{textLine}</Text>
          {tooltip ? <InfoTooltip text={tooltip} style={styles.featureTooltipIcon} /> : null}
        </View>
      );
    });

  return jsxLines;
};

type TariffLike = Pick<
  Tariff,
  'id' | 'name' | 'serverMonthly' | 'server' | 'description' | 'appSumoTier'
>;

export const TariffBox = <T extends TariffLike>({
  isActive,
  tariff,
  isMonthlyViewActive,
  onTariffSelected,
  onUpgradeTariff,
}: Props<T>) => {
  return (
    <View key={tariff.id} style={[styles.planWrapper, isActive && styles.planWrapperActive]}>
      {isActive && <Text style={styles.currentPlan}>Current</Text>}
      <Text style={styles.planName}>{cleanName(tariff.name)}</Text>
      {isMonthlyViewActive ? (
        <>
          <View style={styles.planPriceRow}>
            <Text style={styles.planCurrency}>$</Text>
            <Text style={styles.planPrice}>{tariff.serverMonthly}</Text>
          </View>
          <Text style={styles.planTime}>
            {tariff.serverMonthly === 0 ? 'forever' : 'per month'}
          </Text>
        </>
      ) : (
        <>
          <View style={styles.planPriceRow}>
            <Text style={styles.planCurrency}>$</Text>
            <Text style={styles.planPrice}>
              {tariff.server > 0 ? (tariff.server * 60).toFixed(2) : 0}
            </Text>
          </View>
          <Text style={styles.planTime}>{tariff.server === 0 ? 'forever' : 'per hour'}</Text>
        </>
      )}
      <Text style={styles.planDescription}>{generateDescription(tariff.description)}</Text>
      {!isActive && (
        <Button style={styles.planSelect} onPress={() => onTariffSelected(tariff)}>
          Select
        </Button>
      )}
      {tariff.appSumoTier && [1, 2].includes(tariff.appSumoTier) && (
        <Button style={[styles.planSelect, mixins.styles.margin.topI3]} onPress={onUpgradeTariff}>
          Upgrade
        </Button>
      )}
    </View>
  );
};
