import { StyleSheet } from 'react-native';
import mixins from '../../app/styles';

const styles = StyleSheet.create({
  modal: {},
  backdrop: {
    alignItems: 'center',
    ['backdrop-filter' as any]: 'blur(5px)',
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, .5)',
  },
  container: {
    backgroundColor: mixins.color.dark,
    borderColor: mixins.color.grayDarkest,
    borderWidth: 2,
    justifyContent: 'center',
    minHeight: 300,
    margin: 'auto',
    minWidth: 600,
    shadowColor: mixins.color.black,
    shadowOpacity: 0.8,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 15,
    maxWidth: 950,
    maxHeight: 600,
    paddingBottom: 5,
  },
  containerMobile: {
    minWidth: 300,
    marginHorizontal: 15,
  },
  header: {
    backgroundColor: mixins.color.grayDarkest,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 10,
  },
  title: {
    fontSize: mixins.font.largeFont,
    color: mixins.color.whiteText,
  },
  dismissIconWrapper: {
    position: 'absolute',
    right: 10,
    top: 12,
  },
  iconWrapper: {
    position: 'absolute',
    left: 10,
    top: 5,
  },
  content: {
    flexGrow: 1,
    padding: 20,
    height: '100%',
  },
  contentCentered: {
    margin: 'auto',
  },
  rawContent: {
    flexGrow: 1,
    flexShrink: 1,
  },
  rawContentCentered: {
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: 'center',
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
    backgroundColor: mixins.color.grayDarkest,
    marginBottom: -5,
    gap: 15,
  },
  dismissButton: {},
  dismissButtonText: {
    color: mixins.color.blue,
  },
  text: {
    color: mixins.color.whiteText,
    textAlign: 'center',
    fontSize: 16,
    lineHeight: 30,
  },
});

export const defaultModalTextStyle = styles.text;

export default styles;
