export const AUTH = 'auth';
export const defaultState = {
  isAuth: false,
  accessToken: '',
  expires: 0,
  scope: '',
  tokenType: '',
  username: '',
  decodedAccessToken: {} as any,
};

export const SCOPE = 'csrv';
export const GRUNT_TYPE = 'password';
export const CLIENT_SECRET = 'xtreamer.id';
