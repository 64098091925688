export type ChatsAuthor = { Id: string; Name: string; Self: boolean; target?: string };
export type ChatsMessage = {
  Msg: string;
  Time: Date;
  AuthorId: string;
  Tip: number;
  Type: ChatsMessageType;
  Private: boolean;
  ToAuthorId: string | null;
  Internal: boolean;

  // custom
  target?: string;
};

export enum ChatsMessageType {
  Message = 0,
  Tip = 1,
}

export type PlatformChat = {
  authors: Record<string, ChatsAuthor>;
  messages: ChatsMessage[];
  unreadMessages: number;
  unseenTips: number;
};

export type ChatsData = Record<string, PlatformChat>;

export type NewChatsEvent = {
  Targets: Array<{ TargetId: string; Authors: ChatsAuthor[]; Messages: ChatsMessage[] }>;
};

export interface Store {
  chatsData: ChatsData;
  replyTo?: string;
}
