import { useState } from 'react';
import { View, Text, TextInput } from 'react-native';
import { Me, PaykickstartProduct } from '../../../api/types';
import Button from '../../../components/Button/Button';
import DefaultModal from '../../../components/DefaultModal/DefaultModal';
import PaykickstartModal from '../PaykickstartModal';
import { prepareForPaykickstartModal } from '../Tariff.utils';
import styles from './TopUpModal.styles';
import { useTopUpWithCryptoRequest } from '#/api/api.portal';

interface Props {
  onClose: () => void;
  isBalanceTooLow: boolean;
  funnelId: number;
  deposits: PaykickstartProduct[] | undefined;
  me: Me | undefined;
}

const TopUpModal: React.FC<Props> = ({ onClose, isBalanceTooLow, me, funnelId }) => {
  const { mutateAsync: topUpWithCrypto, isLoading } = useTopUpWithCryptoRequest();
  const [customTopUp, setCustomTopUp] = useState(25);
  const [dataset, setDataset] = useState<{ dataset: any }>();

  const BONUS_RANGES = [
    [0, 99, 0],
    [100, 199, 10],
    [200, 499, 20],
    [500, 1000, 30],
    [1000, undefined, 40],
  ] as const;

  const handleCryptoTopUp = async () => {
    const { data } = await topUpWithCrypto(customTopUp);

    location.href = data.redirect;
  };

  const handleCustomDeposit = () => {
    if (!me) {
      return;
    }

    const data = prepareForPaykickstartModal(
      me,
      {
        createdAt: '',
        updatedAt: '',
        funnelId,
        id: 999999 + Number(customTopUp),
        name: 'Custom',
        productId: 1,
        tariffId: 1,
        value: 1,
      },
      Number(customTopUp),
    );

    setDataset(data);
  };

  return (
    <>
      <DefaultModal
        title="Refill your account"
        confirmText="Close"
        onConfirm={onClose}
        confirmLoading={isLoading}
      >
        <View style={styles.row}>
          <View style={styles.leftColumn}>
            {isBalanceTooLow && (
              <Text style={styles.balanceTextSecondLine}>
                Your balance is too low, you need to first refill your account.
              </Text>
            )}
            <Text style={styles.heading}>Enter refill value</Text>

            <View style={styles.customValueRow}>
              <Button style={styles.amountButtons} onPress={() => setCustomTopUp(v => v - 1)}>
                -
              </Button>
              <TextInput
                value={`${customTopUp}`}
                onChangeText={v => setCustomTopUp(Number(v))}
                style={styles.input}
              />
              <Button style={styles.amountButtons} onPress={() => setCustomTopUp(v => v + 1)}>
                +
              </Button>
            </View>

            <Button onPress={handleCustomDeposit}>Refill</Button>
          </View>
          <View style={styles.bonusColumn}>
            <Text style={styles.heading}>Recieve bonuses by paying forward</Text>
            {BONUS_RANGES.map(([from, to, bonus], i) => (
              <View
                key={i}
                style={[styles.bonusRow, i % 2 === 0 ? styles.bonusRowEven : undefined]}
              >
                <Text style={styles.bonusRange}>
                  ${from}
                  {to ? ` - $${to}` : '+'}
                </Text>
                <Text style={styles.bonusValue}>+{bonus}%</Text>
              </View>
            ))}
          </View>
        </View>
      </DefaultModal>
      {dataset && (
        <PaykickstartModal
          onClose={() => setDataset(undefined)}
          paykickstartElement={dataset}
          onTopUp={handleCryptoTopUp}
        />
      )}
    </>
  );
};

export default TopUpModal;
