import { StyleSheet } from 'react-native';
import mixins from '../../app/styles';

const styles = StyleSheet.create({
  wrapper: {},
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 40,
  },
  column: {
    marginTop: 20,
  },
  columnHalf: {
    flexGrow: 1,
    flexShrink: 0,
    minWidth: 300,
    flexBasis: 50,
  },
  columnWebMobile: {
    margin: 0,
    padding: 0,
  },
  columnContent: {
    backgroundColor: 'rgba(0,0,0,.3)',
    minHeight: 320,
    padding: 10,
  },
  transactionRow: {
    flexDirection: 'row',
    color: mixins.color.white,
    paddingHorizontal: 15,
    paddingVertical: 3,
    borderBottomColor: mixins.color.grayDark,
    borderBottomWidth: 1,
    borderStyle: 'solid',
  },
  transactionHeadingRow: {
    backgroundColor: mixins.color.dark,
    paddingVertical: 8,
    borderBottomWidth: 0,
    marginBottom: 5,
  },
  columnDate: { flexBasis: 220, color: mixins.color.white },
  columnDescription: { flexBasis: 250, flexGrow: 1, flexShrink: 1, color: mixins.color.white },
  columnValue: { flexBasis: 80, textAlign: 'right', color: mixins.color.white },
  columnBalance: { flexBasis: 80, textAlign: 'right', color: mixins.color.white },
  paginationWrapper: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 25,
  },
});

export default styles;
