/* eslint-disable max-len */
import { useContext, useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import { useTransactionStateQuery } from '../../api/api.portal';
import mixins from '../../app/styles';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader/Loader';
import { SocketContext } from '../../socket/socket.provider';
import { AuthContext } from '../auth/auth.utils';
import styles from './ThanksForSubscribing.styles';
import { useAppNavigation, useAppRoute } from '#/navigation/navigation.ref';

const ThanksForSubscribingScreen = () => {
  const { cleanServerData } = useContext(SocketContext);
  const navigation = useAppNavigation();
  const { params } = useAppRoute<'ThanksForSubscribing'>();
  const { isAuth, logout } = useContext(AuthContext);
  const { data: transaction, refetch } = useTransactionStateQuery(
    params?.transactionId || '',
    params?.productId || '',
    {
      skip: !params?.transactionId || !params.productId,
      publicEndpoint: true,
    },
  );
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    let timeout: any;

    if (!transaction?.isProcessed) {
      timeout = setTimeout(() => refetch(), 2500);
    }

    return () => clearTimeout(timeout);
  }, [transaction, refetch, params]);

  const displayWaiting =
    isAuth && !params?.isProcessed && (!params?.transactionId || !transaction?.isProcessed);
  const pageType = transaction?.type || params?.type;
  const paymentType = params?.paymentMethod;

  useEffect(() => {
    let timeout: any;

    if (pageType === 'top-up' || paymentType === 'crypto') {
      setButtonDisabled(false);
    }

    if (!displayWaiting) {
      timeout = setTimeout(() => setButtonDisabled(false), 10000);
    }

    return () => clearTimeout(timeout);
  }, [displayWaiting, pageType, paymentType]);

  const handleButtonPress = () => {
    if (displayWaiting && paymentType === 'crypto') {
      return navigation.reset({ routes: [{ name: 'Home' }] });
    }

    if (pageType === 'top-up' && isAuth) {
      return navigation.reset({ routes: [{ name: 'Tariff' }] });
    }

    if (isAuth) {
      cleanServerData();
      logout();
    }

    setTimeout(() => {
      navigation.reset({ routes: [{ name: 'SignIn' }] });
    });
  };

  const getTitle = () => {
    if (displayWaiting) {
      return 'Thank you for your payment!';
    }
    if (pageType === 'tariff-per-minute' || pageType === 'top-up') {
      return 'Thank you!';
    }
    if (pageType === 'tariff-monthly') {
      return 'Congratulations!';
    }

    return 'Thank you!';
  };

  const getTextLines = () => {
    if (pageType === 'tariff-per-minute') {
      return [
        'Your plan was changed to per minute one.',
        'If you’re using the Streamster app and it is running, please relaunch it for changes to take effect.',
      ];
    }

    if (displayWaiting && paymentType === 'crypto') {
      return [
        `We’re processing it. The ${pageType === 'top-up' ? 'refill will be completed' : 'pricing plan will be changed'} once we receive a network confirmation. Usually, it takes about 10 minutes, but it could be more due to the high network load.`,
        'You can close this window and continue using the Streamster web app. You’ll be notified once the payment is confirmed.',
      ];
    }

    if (pageType === 'tariff-monthly') {
      const lines = [
        'Your pricing plan was changed. If you’re using the Streamster app and it is running, please relaunch it for changes to take effect.',
        'Please note, that the subscription will be automatically renewed in 1 month unless you disable the automatic renewal in your pricing plan settings.',
      ];

      if (paymentType === 'balance') {
        lines.push(
          'Please make sure that you have enough money on your balance for the subscription renewal.',
        );
      }

      return lines;
    }

    if (pageType === 'top-up') {
      return ['Your refill was completed successfully.'];
    }

    return [];
  };

  return (
    <View style={styles.outer}>
      <View style={styles.container}>
        <Text style={styles.heading}>{getTitle()}</Text>
        {getTextLines().map(l => (
          <Text style={styles.text} key={l}>
            {l}
          </Text>
        ))}
        {displayWaiting || buttonDisabled ? (
          <>
            <Loader style={mixins.styles.margin.topI10} />
            {buttonDisabled && <Text style={styles.text}>Processing...</Text>}
          </>
        ) : (
          <Button style={styles.button} onPress={handleButtonPress} disabled={buttonDisabled}>
            {buttonDisabled ? 'Processing...' : isAuth ? 'Go back to app' : 'Log in to app'}
          </Button>
        )}
        {displayWaiting && paymentType === 'crypto' && (
          <Button style={styles.button} onPress={handleButtonPress}>
            Close
          </Button>
        )}
      </View>
    </View>
  );
};

export default ThanksForSubscribingScreen;
