import { StyleSheet, Platform } from 'react-native';
import mixins from '../../../app/styles';

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: mixins.color.darker,
    paddingVertical: 10,
    paddingHorizontal: 15,
    position: 'relative',
    maxWidth: 1000,
    width: '100%',
    marginHorizontal: 'auto',
    marginVertical: 10,
  },
  title: {
    color: mixins.color.grayLightest,
    fontSize: 20,
    textTransform: 'uppercase',
  },
  contentWrapper: {
    paddingTop: 25,
  },
  toggleWrapper: {
    position: 'absolute',
    right: 15,
    top: 10,
  },
  icon: {
    width: 28,
    height: 28,
    transform: (Platform.OS === 'web' ? 'rotateZ(180deg)' : [{ rotateZ: '90deg' }]) as any,
  },
  iconActive: {
    width: 28,
    height: 28,
    transform: (Platform.OS === 'web' ? 'rotateZ(-90deg)' : [{ rotateZ: '90deg' }]) as any,
  },
});

export default styles;
