import { ActiveTariff } from '../api/types';

export const ALL_RESOLUTIONS = [
  '3840x2160',
  '2560x1440',
  '1920x1080',
  '1280x720',
  '960x720',
  '960x540',
  '640x360',
];

export const UNAVAILABLE_MOBILE_OPTIONS = ['3840x2160', '2560x1440', '960x720', 60, 50];

export const ALL_FPS = [60, 50, 30, 25, 20, 15, 10];

export const mapToSelect = (values: any[]) => values.map(v => ({ label: `${v}`, value: v }));

export const paymentMethodMap: Record<ActiveTariff['paymentMethod'], string> = {
  balance: 'User balance',
  card: 'Credit Card',
  crypto: 'Cryptocurrency',
  paypal: 'PayPal',
};

export const AUTH_OVERRIDE_KEY = 'authOverride';
