import React, { useMemo } from 'react';
import { View } from 'react-native';
import Button from '../Button/Button';
import styles from './Pagination.styles';

interface Props {
  totalPages: number;
  currentPage: number;
  pagesToDisplay?: number;
  handlePageChange: (page: number) => void;
}

const Pagination: React.FC<Props> = ({
  pagesToDisplay = 5,
  totalPages,
  currentPage,
  handlePageChange,
}) => {
  const pages = useMemo(() => {
    const finalPageNumbers: number[] = [];

    let distance = 0.5;

    while (finalPageNumbers.length < Math.min(pagesToDisplay, totalPages)) {
      const i = Math.floor(distance) + currentPage - 1;

      if (i > 0 && i <= totalPages) {
        finalPageNumbers.push(i);
      }

      distance = -((Math.abs(distance) + 0.5) * Math.sign(distance));
    }

    return finalPageNumbers.sort((a, b) => a - b);
  }, [totalPages, currentPage]);

  return (
    <View style={styles.row}>
      {pages.map(p => (
        <Button
          style={p === currentPage ? styles.buttonActive : styles.button}
          key={p}
          onPress={() => handlePageChange(p)}
        >
          {p}
        </Button>
      ))}
    </View>
  );
};

export default Pagination;
