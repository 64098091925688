import * as signalR from '@microsoft/signalr';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';
import api from '../api/api';
import { API_BALANCER_URLS } from '../api/api.const';
import { PermissionError } from '../error/error';
import { retry } from '../helpers/retry';
import { getAuthStore } from '../screens/auth/auth.utils';
import { navigationRef } from '../navigation/navigation.ref';
import { useChatMessagesStore } from '#/store/chatMessages/chatMessagesStore';
import { NewChatsEvent } from '#/store/chatMessages/chatMessagesStoreTypes';

const COUNT_RECONNECT = 3;
const DELAY = 2000;

export class HubConnection {
  public connection: signalR.HubConnection | undefined;
  public server = '';
  private tokenOverride = '';
  private currentUserId = -1;
  private firstStart = true;
  private started = false;
  private background = false;
  private onReceiveMessages?: (message: NewChatsEvent) => void;

  public constructor(
    options: {
      background?: boolean;
      onReceiveMessages?: (message: NewChatsEvent) => void;
      token?: string;
    } = {},
  ) {
    if (options.background) {
      this.background = options.background;
    }
    if (options.onReceiveMessages) {
      this.onReceiveMessages = options.onReceiveMessages;
    }
    if (options.token) {
      this.tokenOverride = options.token;
    }
  }

  private get token() {
    return this.tokenOverride || getAuthStore().accessToken;
  }

  public get usesTokenOverride() {
    return !!this.tokenOverride;
  }

  public getTokenOverride() {
    return this.tokenOverride;
  }

  public get currentlyConnectedUserId() {
    return this.currentUserId;
  }

  public get isConnected(): boolean {
    return this.connection?.state === signalR.HubConnectionState.Connected;
  }

  private async getServer() {
    for (const server of API_BALANCER_URLS) {
      try {
        const [response] = await api<{ server: string }>(
          `${server}/LoadBalancer/GetConnectionServer`,
          { method: 'GET' },
          {
            Authorization: `Bearer ${this.token}`,
          },
        );

        this.server = response.data.server;

        if (this.connection) {
          this.connection.baseUrl = this.URL;
        }

        return;
      } catch (err) {
        if (err instanceof PermissionError) {
          throw err;
        }
      }
    }
  }

  private get URL() {
    return `https://${this.server}/Hub`;
  }

  async createConnection(onJsonPatch: (_payload: any) => void) {
    try {
      await this.getServer();

      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(this.URL, {
          skipNegotiation: true,
          accessTokenFactory: () => this.token,
          transport: signalR.HttpTransportType.WebSockets,
        })
        .withHubProtocol(new MessagePackHubProtocol())
        .build();

      this.connection.onclose(this.handleConnectionClosed);
      this.connection.on('JsonPatch', onJsonPatch);
      this.connection.on(
        'ReceiveChatMessages',
        this.onReceiveMessages || useChatMessagesStore.getState().onReceiveChatMessages,
      );
    } catch (err) {
      throw err;
    }
  }

  private handleConnectionClosed = async (error: Error | undefined) => {
    this.started = false;

    if (
      !this.background &&
      getAuthStore().accessToken &&
      navigationRef.current?.getCurrentRoute()?.name !== 'Connect'
    ) {
      navigationRef.current?.reset({ routes: [{ name: 'Connect' }] });
    }

    if (this.background && error) {
      this.connection!.start();
    }
  };

  async start() {
    if (this.started) {
      return;
    }
    this.started = true;

    try {
      if (!this.firstStart) {
        this.firstStart = false;
        if (!this.background) {
          await getAuthStore().refreshTokens();
        }
        await this.getServer();
      }

      await this.stop();

      await retry(COUNT_RECONNECT, DELAY, () => this.connection!.start());
    } catch (e) {
      console.error(e);
      this.started = false;
      throw new Error('Unable to connect to cloud. Please try later');
    }
  }

  async end() {
    await this.stop();

    this.tokenOverride = '';
    this.started = false;
  }

  async connectWithToken(token: string, userId = -1, redirect = 'ActiveStream') {
    if (Number(userId) === this.currentUserId) {
      return;
    }

    await this.end();

    this.tokenOverride = token;
    this.currentUserId = userId ? Number(userId) : -1;

    if (!this.background) {
      navigationRef.current?.reset({
        routes: [{ name: 'Connect', params: { redirect } }],
      });
    }
  }

  async stop() {
    await this.connection?.stop().catch(() => console.log('could not stop connection'));
  }

  sendLog(message: string) {
    if (this.connection) {
      this.connection.invoke('Logs', {
        Logs: [
          JSON.stringify({
            '@t': new Date().toISOString(),
            '@mt': message,
          }),
        ],
      });
    }
  }

  invokeJsonPatch(patch: any) {
    if (!this.isConnected) {
      return;
    }

    this.connection?.invoke('JsonPatch', {
      Changes: JSON.stringify(patch),
      Reset: false,
    });
  }

  sendChatMessage(ChatId: string, Msg: string, AuthorId?: string) {
    if (!this.isConnected) {
      return;
    }

    this.connection?.invoke('SendChatMessage', {
      ChatId,
      Msg,
      AuthorId,
    });
  }
}

export default new HubConnection();
