import {
  ApiUserMonitoringChannel,
  ApiUserMonitoringIngest,
  UserMonitoringChannelState,
} from '#/api/types';
import mixins from '#/app/styles';
import { View, Text, StyleSheet } from 'react-native';
import ArrowUpIcon from '#/assets/icons/arrow-up.svg';
import ArrowTopRightIcon from '#/assets/icons/arrow-top-right.svg';

const StateToColor = {
  [UserMonitoringChannelState.Ok]: '#888',
  [UserMonitoringChannelState.Off]: '#404040',
  [UserMonitoringChannelState.Error]: mixins.color.redDark,
  [UserMonitoringChannelState.Warning]: mixins.color.orange,
};

const StateToColorIcon = {
  [UserMonitoringChannelState.Ok]: '#fff',
  [UserMonitoringChannelState.Off]: '#404040',
  [UserMonitoringChannelState.Error]: mixins.color.redDark,
  [UserMonitoringChannelState.Warning]: mixins.color.orange,
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  wrapper: {
    width: 100,
    flexShrink: 1,
    minWidth: 55,
  },
  kbps: {
    fontSize: 9,
  },
});

export const IngestItem = ({
  value,
}: {
  value: ApiUserMonitoringChannel | ApiUserMonitoringIngest;
}) => {
  const color = StateToColor[value.state];
  const ingestColor = StateToColorIcon[value.state];
  const topRowColor = 'name' in value ? ingestColor : color;

  const Icon = 'name' in value ? ArrowTopRightIcon : ArrowUpIcon;

  return (
    <View style={styles.wrapper}>
      <View style={styles.row}>
        <Icon fill={topRowColor} width={20} style={mixins.styles.margin.rightI1} />
        <Text ellipsizeMode="tail" numberOfLines={1} style={[{ color: topRowColor }]}>
          {'name' in value ? value.name : value.type}
        </Text>
      </View>
      <View>
        <Text style={{ color }}>
          {value.bitrate} <Text style={styles.kbps}>kbps</Text>
        </Text>
      </View>
    </View>
  );
};
