import React from 'react';
import { StyleProp, StyleSheet, TextStyle, View } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import mixins from '../../app/styles';
import ChevronIcon from '#/assets/icons/chevron-up.svg';

interface SelectOption<T> {
  value: T;
  label: string;
  disabled?: boolean;
}

interface Props<T> {
  style?: StyleProp<TextStyle>;
  value?: T;
  disabled?: boolean;
  handleChange?: (value: T) => void;
  values: ReadonlyArray<SelectOption<T>>;
}

const styles = StyleSheet.create({
  picker: {
    width: 200,
    flexShrink: 1,
    minWidth: 1,
    backgroundColor: '#232730',
    borderWidth: 1,
    borderColor: '#232730',
    color: mixins.color.whiteText,
    paddingVertical: 0,
    cursor: 'pointer',
    appearance: 'none',
    paddingRight: 35,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  pickerDisabled: {
    opacity: 1,
    color: mixins.color.grayMedium,
  },
  wrapper: {
    position: 'relative',
  },
});

function Select<T>({ value, handleChange, values, style, disabled = false }: Props<T>) {
  return (
    <View style={styles.wrapper}>
      <Picker
        enabled={!disabled}
        selectedValue={value}
        onValueChange={handleChange}
        style={[styles.picker, style, disabled && styles.pickerDisabled]}
      >
        {values.map(v => (
          <Picker.Item key={v.label} label={v.label} value={v.value} enabled={!v.disabled} />
        ))}
      </Picker>
      <ChevronIcon
        style={{
          rotate: '180deg',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(40%)',
          right: '5px',
          pointerEvents: 'none',
        }}
        width={20}
        fill={mixins.color.white}
      />
    </View>
  );
}

export default Select;
