import {
  useChangeBusinessTariffRequest,
  useChangeBusinessUserPasswordRequest,
  useDeleteBusinessUserMutation,
  useMyTariffQuery,
} from '#/api/api.portal';
import { ActiveTariff, Tariff } from '#/api/types';
import mixins from '#/app/styles';
import { useContext, useState } from 'react';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Select from '#/components/Select/Select';
import ChangePasswordModal from '#/components/ChangePasswordModal';
import DefaultModal from '#/components/DefaultModal/DefaultModal';
import { defaultModalTextStyle } from '#/components/DefaultModal/DefaultModal.styles';
import { ToasterContext } from '#/toaster/toaster.provider';
import { BusinessUserListItem } from '#/hooks/use-business-users';
import { formatUsage } from '#/components/BusinessUserManageList/utils';
import { getTariffsForUser } from '#/hooks/use-business-users';

dayjs.extend(relativeTime);

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    gap: 10,
    paddingHorizontal: 10,
    marginBottom: 5,
    marginTop: 20,
  },
  item: {
    gap: 10,
    backgroundColor: mixins.color.darker,
    paddingHorizontal: 10,
    paddingVertical: 5,
    flexDirection: 'row',
    borderWidth: 2,
    borderColor: mixins.color.dark,
    borderStyle: 'solid',
    alignItems: 'center',
  },
  list: {
    flexDirection: 'column',
    gap: 0,
  },
  value: {
    color: mixins.color.whiteText,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  active: {
    borderColor: mixins.color.blue,
  },

  headerText: {
    color: mixins.color.input,
    fontWeight: 'bold',
    fontSize: 15,
  },
  headerTextSmall: {
    color: mixins.color.input,
    fontSize: 11,
  },
  action: {
    color: mixins.color.blue,
  },
  actionHovered: {
    color: mixins.color.blueLightest,
  },
  tariffSelect: {
    backgroundColor: mixins.color.darker,
    borderColor: mixins.color.darker,
    height: 40,
    width: '100%',
  },

  loginColumn: {
    width: 100,
    flexGrow: 1,
  },
  planColumn: {
    width: 220,
  },
  renewalColumn: {
    width: 85,
    textAlign: 'center',
  },
  usageColumn: {
    width: 85,
    textAlign: 'right',
  },
  costColumn: {
    textAlign: 'right',
    width: 65,
  },
  actionColumn: {
    marginLeft: 15,
    width: 180,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
});

interface Props {
  users: BusinessUserListItem[];
  tariffs: Tariff[];
  selectedUserId: number | null;
  selectUser: (id: string | number | null, login?: string) => void;
  adminUserTariff?: ActiveTariff;
  onChangePassword?: (userId: number, password: string) => Promise<unknown>;
  onDeleteUser?: (userId: number) => Promise<unknown>;
  onChangeTariff?: (userId: number, tariffId: number) => Promise<unknown>;
  requestPending?: boolean;
}

export const BusinessUserManageList: React.FC<Props> = ({
  users = [],
  tariffs,
  selectUser,
  selectedUserId,
  adminUserTariff,
  onChangePassword,
  onDeleteUser,
  onChangeTariff,
  requestPending,
}) => {
  const [changePasswordForUser, setChangePasswordForUser] = useState<
    BusinessUserListItem | undefined
  >();
  const [userToDelete, setUserToDelete] = useState<BusinessUserListItem | undefined>();
  const { success, error } = useContext(ToasterContext);
  const [changeTariffDetails, setChangeTariffDetails] = useState<{
    user: BusinessUserListItem;
    tariffId: number;
  }>();

  const handlePasswordChange = async (newPassword: string) => {
    if (!changePasswordForUser) {
      return;
    }

    try {
      await onChangePassword?.(changePasswordForUser.id, newPassword);

      success({
        message: 'Password has been changed',
      });

      setChangePasswordForUser(undefined);
    } catch (err: any) {
      error({ title: 'Password is invalid', message: err.message });
    }
  };

  const handleDeleteUser = async () => {
    if (!userToDelete) {
      return;
    }

    await onDeleteUser?.(userToDelete.id);

    if (selectedUserId === userToDelete.id) {
      selectUser(users[0].id, users[0].login);
    }

    setUserToDelete(undefined);
  };

  const newTariffForUser = changeTariffDetails
    ? tariffs.find(t => t.id === changeTariffDetails.tariffId)
    : undefined;

  const handleChangeTariff = async () => {
    if (!changeTariffDetails) {
      return;
    }

    await onChangeTariff?.(changeTariffDetails.user.id, changeTariffDetails.tariffId);

    setChangeTariffDetails(undefined);
  };

  const getTariffValuesForUser = (user: BusinessUserListItem) =>
    getTariffsForUser(tariffs, user).map(t => ({ label: t.name, value: t.id }));

  return (
    <>
      <View style={styles.list}>
        <View style={styles.header}>
          <View style={styles.loginColumn}>
            <Text style={styles.headerText}>Login</Text>
          </View>
          <View style={styles.planColumn}>
            <Text style={styles.headerText}>Plan</Text>
          </View>
          <View style={styles.renewalColumn}>
            <Text style={styles.headerText}>Renewal</Text>
          </View>
          <View style={styles.usageColumn}>
            <Text style={styles.headerText}>Usage</Text>
            <Text style={styles.headerTextSmall}>this month</Text>
          </View>
          <View style={styles.costColumn}>
            <Text style={styles.headerText}>Cost, $</Text>
            <Text style={styles.headerTextSmall}>this month</Text>
          </View>
          {(onChangePassword || onDeleteUser) && (
            <View style={styles.actionColumn}>
              <Text style={styles.headerText}>Actions</Text>
            </View>
          )}
        </View>
        {users.map((user, i) => (
          <Pressable
            key={`${user.id}${i}`}
            style={[styles.item, selectedUserId === user.id && styles.active]}
            onPress={() => selectUser(user.id, user.login)}
          >
            <View style={styles.loginColumn}>
              <Text style={styles.value}>{user.login}</Text>
            </View>
            <View style={styles.planColumn}>
              {user.admin ? (
                <Select
                  disabled
                  values={[{ label: adminUserTariff?.name || '', value: '' }]}
                  style={styles.tariffSelect}
                />
              ) : (
                <Select
                  value={user.tariffId}
                  values={getTariffValuesForUser(user)}
                  style={styles.tariffSelect}
                  disabled={user.admin || !onChangeTariff}
                  handleChange={tariffId =>
                    setChangeTariffDetails({ user, tariffId: Number(tariffId) })
                  }
                />
              )}
            </View>
            <View style={styles.renewalColumn}>
              {user.admin ? (
                <Text style={styles.value}>
                  {adminUserTariff?.validTo ? dayjs().to(dayjs(adminUserTariff.validTo)) : '-'}
                </Text>
              ) : (
                <Text style={styles.value}>
                  {user.tariffExpiresAt ? dayjs().to(dayjs(user.tariffExpiresAt)) : '-'}
                </Text>
              )}
            </View>
            <View style={styles.usageColumn}>
              <Text style={styles.value}>{formatUsage(user.usageMinutes)}</Text>
            </View>
            <View style={styles.costColumn}>
              <Text style={styles.value}>{user.usageCost || 0}</Text>
            </View>
            {(onChangePassword || onDeleteUser) && (
              <View style={styles.actionColumn}>
                {!user.admin && (
                  <>
                    {onChangePassword && (
                      <Pressable onPress={() => setChangePasswordForUser(user)}>
                        {({ hovered }) => (
                          <Text style={[hovered ? styles.actionHovered : styles.action]}>
                            Change password
                          </Text>
                        )}
                      </Pressable>
                    )}
                    {onDeleteUser && (
                      <Pressable onPress={() => setUserToDelete(user)}>
                        {({ hovered }) => (
                          <Text style={[hovered ? styles.actionHovered : styles.action]}>
                            Delete
                          </Text>
                        )}
                      </Pressable>
                    )}
                  </>
                )}
              </View>
            )}
          </Pressable>
        ))}
      </View>
      {changePasswordForUser && (
        <ChangePasswordModal
          onNewPassword={handlePasswordChange}
          username={changePasswordForUser.login}
          onDismiss={() => setChangePasswordForUser(undefined)}
        />
      )}
      {newTariffForUser && (
        <DefaultModal
          confirmLoading={requestPending}
          title="Change user tariff?"
          confirmText="Change"
          dismissText="Cancel"
          onConfirm={handleChangeTariff}
          onDismiss={() => setChangeTariffDetails(undefined)}
        >
          <Text style={defaultModalTextStyle}>
            You're about to change tariff for user{' '}
            <strong>{changeTariffDetails?.user.login}</strong> to{' '}
            <strong>{newTariffForUser.name}</strong>.{'\n'}
            Tariff price is
            {newTariffForUser.serverMonthly > 0
              ? ` $${newTariffForUser.serverMonthly} per month`
              : ` $${newTariffForUser.server} per minute of use`}
            .{'\n\n'}
            <strong>Existing tariff will be deactivated.</strong>
          </Text>
        </DefaultModal>
      )}
      {userToDelete && (
        <DefaultModal
          confirmLoading={requestPending}
          title="Delete user?"
          onConfirm={handleDeleteUser}
          onDismiss={() => setUserToDelete(undefined)}
          dismissText="Cancel"
          confirmText="Delete"
        >
          <Text style={defaultModalTextStyle}>
            Delete user <strong>{userToDelete.login}</strong>?{'\n'}This step is irreversible.
          </Text>
        </DefaultModal>
      )}
    </>
  );
};
