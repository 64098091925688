import React from 'react';
import { View, Text } from 'react-native';

import CloudIcon from '../../assets/icons/cloud.svg';
import WebCamIcon from '../../assets/icons/webcam.svg';
import CPUIcon from '../../assets/icons/cpu.svg';
import SettingsBorderIcon from '../../assets/icons/settings-border.svg';

import { adaptiveSize } from '../../app/styles';
import styles from './Indicator.styles';

const iconMap = {
  cpu: CPUIcon,
  fps: WebCamIcon,
  encoder: SettingsBorderIcon,
  bitrate: CloudIcon,
};

type IndicatorProps = {
  value: string | number;
  label: keyof typeof iconMap;
  fill: string;
  isPortrait: boolean;
};

const ICON_SIZE = adaptiveSize(30);

const Indicator: React.FC<IndicatorProps> = ({ value, label, fill, isPortrait }) => {
  const Icon = iconMap[label];

  return (
    <View style={[styles.indicatorContent, isPortrait && styles.indicatorContentLandscape]}>
      <Icon width={ICON_SIZE} height={ICON_SIZE} fill={fill} />
      <Text
        style={[
          styles.indicatorText,
          { color: fill },
          isPortrait && styles.indicatorTextLandscape,
        ]}
      >
        {value}
      </Text>
    </View>
  );
};

export default Indicator;
