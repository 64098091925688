import { useManageBoxControls } from '#/screens/stream/manageBox/utils';
import { initPlatformChat } from '#/socket/socket.actions';
import { SendPatch } from '#/socket/socket.type';
import { useEffect, useRef } from 'react';
import { Platform } from 'react-native';

export const useChatInitialization = ({
  sendPatch,
  chatId,
  targetId,
}: {
  sendPatch: SendPatch;
  chatId: string | undefined;
  targetId: string | undefined;
}) => {
  if (Platform.OS === 'web') {
    return;
  }

  const chatInitializedRef = useRef(false);
  const manageBoxControls = useManageBoxControls();

  useEffect(() => {
    if (chatId) {
      chatInitializedRef.current = true;
    }
    if (!chatId && targetId) {
      if (chatInitializedRef.current) {
        manageBoxControls.setChannelScreen('SETTINGS');
      } else {
        sendPatch(initPlatformChat(targetId));
      }
    }
  }, [chatId, targetId]);
};
