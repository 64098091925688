import Clipboard from '@react-native-clipboard/clipboard';
import { Platform } from 'react-native';
import { Buffer } from 'buffer';

export * from './random';
export * from './device';
export * from './hooks';

export const copyToClipboard = (text: string) => {
  if (Platform.OS === 'web') {
    navigator.clipboard.writeText(text);
  } else {
    Clipboard.setString(text);
  }
};

export const formatPrice = (value: number | undefined) =>
  value !== undefined ? `$${value.toFixed(2)}`.replace('$-', '-$') : '-';

export const decodeToken = (token: string) =>
  JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('ascii'));
