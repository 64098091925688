import { useContext, useState } from 'react';
import { Platform } from 'react-native';
import useIsMounted from '../../hooks/is-mounted-hook';
import { ToasterContext } from '../../toaster/toaster.provider';
import { Context, ContextData } from './auth.type';
import React from 'react';
import { useStore } from '../../store/store';
import { AUTH, defaultState } from './auth.const';
import { useAppRoute } from '#/navigation/navigation.ref';

export const authStoreRef: { store: Context } = { store: null as any };

export const AuthContext = React.createContext<Context>(undefined!);

export const useAuthStore = () => useStore<ContextData>(AUTH, defaultState);

export const getAuthStore = () => authStoreRef.store;

export const useLogin = () => {
  const route = useAppRoute<'SignIn'>();
  const [twoFactorTokenId, setTwoFactorTokenId] = useState('');
  const [code, setCode] = useState('');
  const [progress, setProgress] = useState(false);
  const isMounted = useIsMounted();
  const { login, submitTwoFactorCode } = useContext(AuthContext);
  const { error } = useContext(ToasterContext);
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    try {
      setProgress(true);

      if (twoFactorTokenId) {
        if (!code) {
          return error({ message: `Please, enter two-factor code` });
        }

        await submitTwoFactorCode(twoFactorTokenId, code);

        return;
      }

      if (name.trim() && password.trim()) {
        const loginResult = await login({
          username: name.trim(),
          password: password.trim(),
          redirect:
            Platform.OS === 'web' && route.params?.handler === 'after-registration'
              ? 'Tariff'
              : undefined,
        });

        if (loginResult.result === '2FA') {
          setTwoFactorTokenId(loginResult.tokenId);
        }
      } else {
        error({ message: `Please, enter your ${!name ? 'username' : 'password'}` });
      }
    } catch (e: any) {
      if (e?.payload?.code === 400) {
        error({ message: 'Wrong username/password or your account is not active' });
      } else if (e?.payload?.code === 500) {
        error({ message: 'Error - No connection. Please, try again later' });
      } else {
        error({
          message: `Please contact service administrator - ${e?.payload?.code}`,
        });
      }
    } finally {
      if (isMounted()) {
        setProgress(false);
      }
    }
  };

  return {
    progress,
    name,
    password,
    setName,
    setPassword,
    handleLogin,
    show2fa: Boolean(twoFactorTokenId),
    code,
    setCode,
  };
};
