import React, { useContext, useState } from 'react';
import { View, Text } from 'react-native';

import { SocketContext } from '../../../socket/socket.provider';

import styles, { webStyles } from './ManageChannels.styles';
import Button from '../../../components/Button/Button';
import { getRandomId, useIsMobile } from '../../../utils';
import { Channel, ChannelState, Target } from '../../../socket/document.type';
import EditChannelModal from '../../../components/EditChannelModal/EditChannelModal';

import mixins from '../../../app/styles';
import TranscodingModal from '../../../components/TranscodingModal/TranscodingModal';
import AppleStoreBadge from '../../../assets/badges/apple-store.svg';
import GooglePlayBadge from '../../../assets/badges/google-play.svg';
import AddChannelModal from '../../../components/AddChannelModal/AddChannelModal';
import ChannelList from '../../../components/ChannelList/ChannelList';
import { getDataFromChannelAndTarget } from '../../../components/ChannelToggle/ChannelToggle.utils';
import { addNewChannelAction } from '#/socket/socket.actions';

const ManageChannels: React.FC = () => {
  const isMobile = useIsMobile();
  const [transcoderModalVisible, setTranscoderModalVisibility] = useState(false);
  const { serverData, sendPatch } = useContext(SocketContext);
  const [isAddModalVisible, setAddModalVisibility] = useState(false);
  const [targetToAdd, setTargetToAdd] = useState<Target>();

  const handleAdd = (channelData: Partial<Channel>) => {
    setTargetToAdd(undefined);
    sendPatch(addNewChannelAction(channelData));
  };

  if (Object.values(serverData.Targets).length === 0) {
    return <Text>Loading...</Text>;
  }

  return (
    <>
      <View style={styles.channelList}>
        <ChannelList handleShowTranscoding={() => setTranscoderModalVisibility(true)} />
        {!isMobile && (
          <View style={styles.addChannelWrapper}>
            <Button onPress={() => setAddModalVisibility(true)}>Add channel</Button>
          </View>
        )}
      </View>
      {isMobile && (
        <>
          <Text style={styles.grayText}>
            Download Streamster mobile app to have more control over your live stream.
          </Text>
          <View style={[styles.row, mixins.styles.margin.topI6]}>
            <a
              href="https://play.google.com/store/apps/details?id=io.streamster"
              target="_blank"
              style={webStyles.downloadButtons}
            >
              <GooglePlayBadge height={50} />
            </a>
            <a
              href="https://apps.apple.com/md/app/streamster/id1578293910"
              target="_blank"
              style={webStyles.downloadButtons}
            >
              <AppleStoreBadge height={50} />
            </a>
          </View>
        </>
      )}
      {isAddModalVisible && (
        <AddChannelModal
          handleClose={() => setAddModalVisibility(false)}
          handleChooseTargetToAdd={target => {
            setTargetToAdd(target);
            setAddModalVisibility(false);
          }}
          targets={serverData.Targets}
        />
      )}
      {targetToAdd && (
        <EditChannelModal
          channel={getDataFromChannelAndTarget({ channel: {}, target: targetToAdd })}
          handleDismiss={() => setTargetToAdd(undefined)}
          handleSave={handleAdd}
          isAddVariant
        />
      )}
      {transcoderModalVisible && (
        <TranscodingModal handleClose={() => setTranscoderModalVisibility(false)} />
      )}
    </>
  );
};

export default ManageChannels;
