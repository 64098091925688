import { FC, PropsWithChildren } from 'react';
import { View, Text, Pressable, StyleSheet } from 'react-native';
import LeftArrow from '#/assets/icons/arrow-left.svg';
import mixins from '#/app/styles';

type Props = { onBackPressed?: () => void; title: string };

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    backgroundColor: mixins.color.backgroundBlue,
    alignItems: 'center',
  },
  button: {
    padding: 15,
  },
  title: {
    fontSize: 18,
    color: mixins.color.blue,
  },
  actions: {
    marginLeft: 'auto',
    alignItems: 'center',
    paddingRight: 15,
    gap: 15,
    flexDirection: 'row',
  },
});

export const MobileBackHeader: FC<PropsWithChildren<Props>> = ({
  onBackPressed,
  title,
  children,
}) => {
  return (
    <View style={styles.row}>
      <Pressable onPress={onBackPressed} style={styles.button}>
        <LeftArrow fill={mixins.color.white} width={25} height={30} />
      </Pressable>
      <Text style={styles.title}>{title}</Text>
      <View style={styles.actions}>{children}</View>
    </View>
  );
};
