import { useEffect, useRef } from 'react';
import { useAppNavigation } from '#/navigation/navigation.ref';

const LogRouteToGoogleAnalytics = () => {
  const navigation = useAppNavigation();
  const lastPath = useRef('');

  useEffect(() => {
    const sendNewPage = () => {
      setTimeout(() => {
        const w = window as any;

        const path = location.pathname;

        if (path === lastPath.current) {
          return;
        }

        lastPath.current = path;

        if (w.gtag) {
          w.gtag('set', 'page_path', path);
          w.gtag('event', 'page_view');
        }

        if (w.dataLayer) {
          w.dataLayer.push({
            event: 'pageview',
          });
        }
      }, 100);
    };

    navigation.addListener('state', sendNewPage);

    return () => navigation.removeListener('state', sendNewPage);
  }, [navigation]);

  return null;
};

export default LogRouteToGoogleAnalytics;
