import Loader from '#/components/Loader/Loader';
import { useContext } from 'react';
import {
  useBusinessUserQuery,
  useChangeBusinessUserPasswordRequest,
  useChangePasswordRequest,
  useDeleteBusinessUserMutation,
  useMeQuery,
  useMyTariffQuery,
} from '#/api/api.portal';
import mixins from '#/app/styles';
import Heading from '#/components/Heading/Heading';
import PageWrapper from '#/components/PageWrapper/PageWrapper';
import { ToasterContext } from '#/toaster/toaster.provider';
import { ProfileBox } from '#/screens/profile/Profile';
import { useSelectedBusinessUserStore } from '#/screens/home/selectedBusinessUser.store';
import { BusinessUserSwitcher } from '#/components/BusinessUserSwitcher/BusinessUserSwitcher';
import { Me } from '#/api/types';
import { AuthContext } from '#/screens/auth/auth.utils';

export const ProfileScreen = () => {
  const { data: profile } = useMeQuery();
  const { selectedUserId } = useSelectedBusinessUserStore();

  if (!profile) {
    return <Loader />;
  }

  const isBusiness = profile.business;
  const isMyUserSelected = selectedUserId === profile.userId || !selectedUserId;

  return (
    <PageWrapper>
      {selectedUserId ? (
        <BusinessUserSwitcher />
      ) : (
        <Heading style={mixins.styles.margin.topI8}>Profile</Heading>
      )}
      {isBusiness && !isMyUserSelected ? (
        <ProfileBusinessContainer selectedUserId={selectedUserId} />
      ) : (
        <ProfileContainer profile={profile} />
      )}
    </PageWrapper>
  );
};

const ProfileBusinessContainer = ({ selectedUserId }: { selectedUserId: number }) => {
  const { data: user } = useBusinessUserQuery(selectedUserId);
  const [changePassword] = useChangeBusinessUserPasswordRequest();
  const deleteMutation = useDeleteBusinessUserMutation();
  const { error, success } = useContext(ToasterContext);
  const { selectUser } = useSelectedBusinessUserStore();

  if (!user) {
    return <Loader />;
  }

  const handleChangePassword = (password: string) => {
    changePassword(selectedUserId, password)
      .then(() => success({ message: 'Password has been changed' }))
      .catch(err => error({ title: 'Password is invalid', message: err.message }));
  };

  const handleDeleteAccount = () => {
    deleteMutation.mutateAsync(selectedUserId).then(() => {
      selectUser(null);
    });
  };

  return (
    <ProfileBox
      user={user}
      tariff={{ name: user.tariffName || '', tariffExpiresAt: user.tariffExpiresAt }}
      onChangePassword={handleChangePassword}
      onDeleteAccount={handleDeleteAccount}
    />
  );
};

const ProfileContainer = ({ profile }: { profile: Me }) => {
  const { logout, deleteAccount } = useContext(AuthContext);
  const { error, success } = useContext(ToasterContext);
  const [changePassword] = useChangePasswordRequest();
  const { data: myTariff } = useMyTariffQuery();

  const handleDeleteAccount = async () => {
    try {
      await deleteAccount();
      await logout();
      success({ message: 'You have been logged out', title: 'Your account has been deleted' });
    } catch (err) {
      error({ message: 'Please try again' });
    }
  };

  const handleChangePassword = async (newPassword: string) => {
    try {
      await changePassword(newPassword);

      success({
        title: 'Please log in to access your account',
        message: 'Your password was changed',
      });
    } catch (err: any) {
      error({ title: 'Password is invalid', message: err.message });
    }
  };

  if (!myTariff) {
    return <Loader />;
  }

  return (
    <ProfileBox
      user={profile}
      tariff={myTariff}
      showUpgradeToBusiness={!profile.business}
      onChangePassword={handleChangePassword}
      onDeleteAccount={handleDeleteAccount}
      userIsBusiness={profile.business}
      showDeleteAccountButton
    />
  );
};
