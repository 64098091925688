import React, { memo, useEffect, useRef, useState } from 'react';

import VolumeHighIcon from '../../assets/icons/volume-high.svg';
import VolumeMutedIcon from '../../assets/icons/volume-muted.svg';
import EnterFullscreenIcon from '../../assets/icons/fullscreen.svg';
import ExitFullscreenIcon from '../../assets/icons/fullscreen-exit.svg';
import InfoIcon from '../../assets/icons/info.svg';
import HelpIcon from '../../assets/icons/help-circle-outline.svg';

import styles from './WebRTCPreview.module.scss';
import Loader from '../Loader/Loader';

interface Props {
  url: string;
  isStreaming: boolean;
  onPlaying: () => void;
}

const WebRTCPreview: React.FC<Props> = ({ url, isStreaming }) => {
  const [muted, setMuted] = useState(true);
  const [status, setStatus] = useState<'NOT_PLAYING' | 'LOADING' | 'PLAYING'>('LOADING');
  const [isFullScreen, setFullScreen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const STUN_URL = 'stun:stun.12connect.com';

  useEffect(() => {
    let pc: RTCPeerConnection;
    let ws: WebSocket;

    if (!videoRef.current || !isStreaming) {
      return;
    }

    pc = new RTCPeerConnection({ iceServers: [{ urls: STUN_URL }] });

    pc.ontrack = evt => {
      if (!videoRef.current) {
        return;
      }
      videoRef.current.srcObject = evt.streams[0];
    };

    pc.onicecandidate = evt => {
      evt.candidate && ws.send(JSON.stringify(evt.candidate));
    };
    ws = new WebSocket(url, []);

    ws.onmessage = async function (evt) {
      if (/^[\{"'\s]*candidate/.test(evt.data)) {
        pc.addIceCandidate(JSON.parse(evt.data));
      } else {
        await pc.setRemoteDescription(new RTCSessionDescription(JSON.parse(evt.data)));
        const answer = await pc.createAnswer();
        await pc.setLocalDescription(answer);
        ws.send(JSON.stringify(pc.localDescription));
        videoRef.current?.play();
      }
    };

    return () => {
      if (ws != null) {
        ws.close();
      }
      if (pc != null) {
        pc.close();
      }
    };
  }, [url, isStreaming]);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    if (!isStreaming) {
      setStatus('LOADING');
      return;
    }

    const playingHandler = () => {
      setStatus('PLAYING');
    };

    const video = videoRef.current;

    video.addEventListener('playing', playingHandler);

    const timeout = setTimeout(() => {
      if ((videoRef.current?.readyState || 0) <= 2) {
        setStatus('NOT_PLAYING');
      }
    }, 7000);

    const handler = () => {
      setFullScreen(f => !f);
    };

    document.addEventListener('fullscreenchange', handler);

    return () => {
      clearTimeout(timeout);
      document.removeEventListener('fullscreenchange', handler);
      video.removeEventListener('playing', playingHandler);
    };
  }, [isStreaming]);

  const showLoader = ['NOT_PLAYING', 'LOADING'].includes(status);

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperInner}>
        <div className={styles.spacer}>
          {showLoader && isStreaming && <Loader />}
          {!isStreaming && <div>Waiting for stream</div>}
        </div>
        <div
          className={`${styles.videoInner} ${showLoader ? styles.loading : ''}`}
          ref={wrapperRef}
        >
          <video
            ref={videoRef}
            autoPlay={isStreaming}
            controls={false}
            className={styles.video}
            muted={muted}
          />
          <div className={styles.controls}>
            <button type="button" onClick={() => setMuted(v => !v)}>
              {muted ? <VolumeMutedIcon /> : <VolumeHighIcon />}
            </button>
            <button
              type="button"
              onClick={() => {
                document.fullscreenElement
                  ? document.exitFullscreen()
                  : wrapperRef.current?.requestFullscreen();
              }}
            >
              {isFullScreen ? <ExitFullscreenIcon /> : <EnterFullscreenIcon />}
            </button>
          </div>
        </div>
      </div>
      {status === 'NOT_PLAYING' && (
        <p className={styles.errorMsg}>
          <InfoIcon />
          Preview did not start? Don't worry, this issue does not affect your stream quality.
          However, to make the preview work please use one of the recommended browsers: Google
          Chrome, Firefox, or Microsoft Edge
        </p>
      )}
      {status === 'PLAYING' && (
        <a
          href="https://docs.streamster.io/docs/in-browser-app/getting-started-5818/optimal-settings-for-obs-6214/"
          className={styles.infoMsg}
          target="_blank"
          rel="noopener"
        >
          <HelpIcon />
          Having issues with the preview? Please make sure that your stream preferences are correct
        </a>
      )}
    </div>
  );
};

export default memo(WebRTCPreview);
