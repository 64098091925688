import React, { useContext } from 'react';
import { View } from 'react-native';
import mixins from '../../app/styles';
import { DeviceIndicators, IndicatorState } from '../../socket/document.type';
import { SocketContext } from '../../socket/socket.provider';
import Indicator from '../Indicator/Indicator';
import styles from './IndicatorsRow.styles';

interface Props {
  isPortrait?: boolean;
}

const getColorFromState = (state: IndicatorState) => {
  switch (state) {
    case IndicatorState.Disabled:
      return mixins.color.black;
    case IndicatorState.Ok:
      return mixins.color.green;
    case IndicatorState.Warning:
    case IndicatorState.Warning2:
      return mixins.color.orange;
    case IndicatorState.Error:
    case IndicatorState.Error2:
    case IndicatorState.ExceededBitrate:
      return mixins.color.red;
    default:
      return mixins.color.white;
  }
};

const shouldShowIndicator = (indicator: DeviceIndicators[keyof DeviceIndicators]) => {
  if (!indicator) {
    return false;
  }
  switch (indicator.State) {
    case IndicatorState.Ok:
    case IndicatorState.Warning:
    case IndicatorState.Warning2:
    case IndicatorState.Error:
    case IndicatorState.Error2:
    case IndicatorState.ExceededBitrate:
      return true;
    case IndicatorState.Disabled:
    default:
      return false;
  }
};

const getTexStatus = (state: IndicatorState) => {
  switch (state) {
    case IndicatorState.Ok:
      return 'OK';
    case IndicatorState.Warning:
    case IndicatorState.Warning2:
      return 'W';
    case IndicatorState.Error:
    case IndicatorState.Error2:
    case IndicatorState.ExceededBitrate:
      return 'E';
    case IndicatorState.Disabled:
    default:
      return 'D';
  }
};

const IndicatorsRow = ({ isPortrait = false }: Props) => {
  const {
    commonData: { selectedSceneOwner },
    serverData,
  } = useContext(SocketContext);

  const { KPIs } = serverData.Devices[selectedSceneOwner] || {};

  if (!KPIs) {
    return null;
  }

  return (
    <View style={isPortrait ? styles.container : styles.containerLandscape}>
      {shouldShowIndicator(KPIs.Cpu) && (
        <Indicator
          value={KPIs.Cpu.Load}
          label="cpu"
          fill={getColorFromState(KPIs.Cpu.State)}
          isPortrait={isPortrait}
        />
      )}
      {shouldShowIndicator(KPIs.Encoder) && (
        <Indicator
          value={getTexStatus(KPIs.Encoder.State)}
          label="encoder"
          fill={getColorFromState(KPIs.Encoder.State)}
          isPortrait={isPortrait}
        />
      )}
      {shouldShowIndicator(KPIs.CloudOut) && (
        <Indicator
          value={((KPIs.CloudOut.Bitrate || 0) / 1000).toFixed(1)}
          label="bitrate"
          fill={getColorFromState(KPIs.CloudOut.State)}
          isPortrait={isPortrait}
        />
      )}
    </View>
  );
};

export default IndicatorsRow;
