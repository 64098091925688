import { Channel, Target } from '../../socket/document.type';

export const getDataFromChannelAndTarget = ({
  channel = {},
  target,
}: {
  channel?: Partial<Channel>;
  target: Target;
}): Partial<Channel> => ({
  ...channel,
  Name: channel.Name || target.Name,
  RtmpUrl: channel.RtmpUrl || target.DefaultRtmpUrl,
  WebUrl: channel.WebUrl || target.WebUrl,
  TargetId: channel.TargetId || target.Id || '',
});
