import mixins from '#/app/styles';
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  unavailable: {
    color: mixins.color.whiteText,
    textAlign: 'center',
    fontSize: mixins.font.bigFont,
  },
  wrapper: {
    margin: 'auto',
    paddingHorizontal: 15,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 50,
  },
});
