import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 1,
    minHeight: 40,
  },
  containerLandscape: {
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 1,
    minWidth: 40,
    gap: 10,
    flexGrow: 1,
  },
});

export default styles;
