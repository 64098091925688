import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { Area, AreaChart, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useUsageDailyQuery } from '../../api/api.portal';
import mixins from '../../app/styles';
import Loader from '../../components/Loader/Loader';

import CellphoneIcon from '../../assets/icons/cellphone.svg';
import HelpIcon from '../../assets/icons/help-circle-outline.svg';
import WindowsIcon from '../../assets/icons/ms-windows.svg';
import OBSIcon from '../../assets/icons/obs.svg';

import styles from './styles.module.scss';
import Button from '../../components/Button/Button';
import Heading from '../../components/Heading/Heading';
import { SocketContext } from '../../socket/socket.provider';
import CustomChartTooltip from '../../components/CustomChartTooltip/CustomChartTooltip';
import { DeviceState, IndicatorState } from '../../socket/document.type';
import { useIsBusinessUser } from '../../hooks';
import { useAppNavigation } from '#/navigation/navigation.ref';

export const HomeScreenUser = () => {
  const isBusinessUser = useIsBusinessUser();
  const { commonData, serverData } = useContext(SocketContext);
  const navigation = useAppNavigation();
  const { isLoading: dailyLoading, data: dailyStats } = useUsageDailyQuery({
    enabled: !isBusinessUser,
  });

  const dailyData = (dailyStats || []).map(d => ({
    name: dayjs(d.time).format('DD/MM'),
    usage: d.value / 60,
  }));

  const supportsStreaming = (type: string) => type === 'win' || type === 'ext';

  const deviceReady = Object.entries(serverData.Devices)
    .filter(Boolean)
    .find(
      ([_k, d]) =>
        supportsStreaming(d.Type) && d.State !== undefined && d.State !== DeviceState.Offline,
    );

  const renderStatus = () => {
    if (!commonData.selectedSceneOwner) {
      return 'Unknown, 0 kb/s';
    }

    if (commonData.KPIs && commonData.KPIs.CloudOut.State !== IndicatorState.Disabled) {
      return `Active, ${commonData.KPIs.CloudOut.Bitrate || 0} kb/s`;
    } else {
      return 'Online, not streaming';
    }
  };

  return (
    <div className={styles.wrapper}>
      <Heading style={mixins.styles.margin.topI8}>Start with</Heading>
      <div className={styles.row}>
        <a
          className={styles.box}
          href="/active-stream?tab=source&source=app"
          onClick={e => {
            e.preventDefault();
            navigation.replace('ActiveStream', { tab: 'source', source: 'app' });
          }}
        >
          <WindowsIcon className={styles.icon} />
          <h4>Streamster App</h4>
          <p>Optimal way to stream from your Windows PC</p>
        </a>
        <a
          className={styles.box}
          href="/active-stream?tab=source&source=external"
          onClick={e => {
            e.preventDefault();
            navigation.replace('ActiveStream', { tab: 'source', source: 'external' });
          }}
        >
          <OBSIcon className={styles.icon} />
          <h4>External Encoder</h4>
          <p>Use well-known encoders, such as OBS, in your multistreaming experience</p>
        </a>
        <a
          className={styles.box}
          href="/active-stream?tab=source&source=mobile"
          onClick={e => {
            e.preventDefault();
            navigation.replace('ActiveStream', { tab: 'source', source: 'mobile' });
          }}
        >
          <CellphoneIcon className={styles.icon} />
          <h4>Smartphone</h4>
          <p>Multistream with your mobile or control a stream from Streamster mobile app</p>
        </a>
      </div>
      {deviceReady && (
        <>
          <Heading style={mixins.styles.margin.topI11}>Live stream</Heading>
          <div className={styles.activeWrapper}>
            {renderStatus()}
            <Button
              style={mixins.styles.margin.leftI11}
              onPress={() => {
                navigation.replace('ActiveStream', {
                  tab: 'status',
                  source: commonData.isStreamingExternalSource ? 'external' : 'app',
                });
              }}
            >
              Enter
            </Button>
          </div>
        </>
      )}
      {!isBusinessUser && (
        <>
          <Heading style={mixins.styles.margin.topI11}>History</Heading>
          <div className={styles.chartWrapper}>
            {dailyLoading && <Loader />}
            {dailyStats && (
              <ResponsiveContainer width="100%" height={400}>
                <AreaChart
                  width={400}
                  height={200}
                  data={dailyData}
                  margin={{ top: 15, right: 30, left: 20, bottom: 35 }}
                >
                  <XAxis dataKey="name" style={{ fill: mixins.color.grayMedium }} offset={5} />
                  <YAxis
                    style={{ fill: mixins.color.grayMedium }}
                    tickFormatter={v => v.toFixed(2)}
                  >
                    <Label
                      value="Usage [h]"
                      fill={mixins.color.grayMedium}
                      position="insideLeft"
                      angle={-90}
                    />
                  </YAxis>
                  <Tooltip content={CustomChartTooltip} />
                  <Area
                    unit="h"
                    type="monotone"
                    dataKey="usage"
                    fillOpacity={0.1}
                    strokeWidth={3}
                    stroke={mixins.color.green}
                    fill={mixins.color.green}
                  />
                </AreaChart>
              </ResponsiveContainer>
            )}
          </div>
          <a
            href="/statistics"
            onClick={e => {
              e.preventDefault();
              navigation.replace('Statistics');
            }}
            className={styles.help}
          >
            <HelpIcon fill={mixins.color.blue} width={20} />
            Get more statistics
          </a>
        </>
      )}
    </div>
  );
};
