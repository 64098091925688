import mixins from '#/app/styles';
import { useUserLimits } from '#/components/ChannelList/utils';
import { CustomTarget } from '#/helpers/defaultData';
import { validateRMTPUrl } from '#/helpers/validators';
import { AutoLoginState, ChannelState, TargetMode } from '#/socket/document.type';
import { SocketContext } from '#/socket/socket.provider';
import { useContext, useMemo } from 'react';

const common = {
  inactive: {
    tooltip: undefined,
    color: '#404040',
    textColor: mixins.color.whiteText,
    statusColor: undefined,
    status: 'inactive',
  },
  active: {
    tooltip: undefined,
    color: mixins.color.greenDarker,
    statusColor: mixins.color.green,
    textColor: mixins.color.whiteText,
    status: 'active',
  },
  error: {
    tooltip: undefined,
    color: mixins.color.redDark,
    textColor: mixins.color.whiteText,
    statusColor: undefined,
    status: 'error',
  },
  warning: {
    tooltip: undefined,
    color: mixins.color.calmOrange,
    textColor: mixins.color.whiteText,
    statusColor: '#ffa200',
    status: 'warning',
  },
};

export const useChannelStatus = (channelId: string) => {
  const {
    serverData,
    commonData: { isStreaming },
  } = useContext(SocketContext);
  const { tri: maxInput, tra: transcodingChannels } = useUserLimits();

  const data = serverData.Channels[channelId];
  const settings = serverData.Settings;
  const target = serverData.Targets[data?.TargetId] || CustomTarget;
  const transcodingEnabled = Number(transcodingChannels) > 0;

  const message = useMemo(() => {
    if (!data) {
      return {
        ...common.inactive,
        message: undefined,
      };
    }

    if (data.IsOn) {
      if (!isStreaming) {
        return {
          ...common.inactive,
          message: 'Waiting for stream to cloud',
        };
      }
      switch (data.State) {
        case undefined:
        case ChannelState.Idle: {
          return {
            ...common.inactive,
            message: 'Connecting...',
          };
        }
        case ChannelState.RunningOk: {
          return {
            ...common.active,
            message: 'Connected',
          };
        }
        case ChannelState.RunningConnectError: {
          return {
            ...common.error,
            message: 'Failed. Check your key',
          };
        }
        case ChannelState.RunningInitError: {
          return {
            ...common.error,
            message: 'Unknown error',
          };
        }
        default: {
          if (data.BitrateWarning) {
            return {
              ...common.warning,
              message: 'Stream may be incompatible',
              tooltip: data.BitrateWarning,
            };
          }

          return {
            ...common.error,
            message: 'Unknown failure',
          };
        }
      }
    } else {
      if (data.State !== undefined && data.State !== ChannelState.Idle) {
        return {
          ...common.inactive,
          message: 'Disconnecting...',
        };
      }
      if (data.TargetMode === TargetMode.AutoLogin) {
        if (data.AutoLoginState === AutoLoginState.NotAuthenticated) {
          return {
            ...common.error,
            message: 'Not authenticated',
          };
        }
        if (data.AutoLoginState === AutoLoginState.InProgress) {
          return {
            ...common.active,
            message: 'Authenticating...',
          };
        }
        if (data.AutoLoginState === AutoLoginState.KeyNotFound) {
          return {
            ...common.warning,
            message: 'Problem with auth data',
          };
        }
      } else {
        if (!data.RtmpUrl && !target.DefaultRtmpUrl) {
          return {
            ...common.warning,
            message: 'RTMP url is not set',
          };
        }
        if (data.RtmpUrl && !validateRMTPUrl(data.RtmpUrl)) {
          return {
            ...common.warning,
            message: 'Wrong RTMP url format',
          };
        }
        if (!data.Key) {
          return {
            ...common.warning,
            message: 'Stream key is not set',
          };
        }
      }

      if (data.TranscoderId && transcodingEnabled) {
        const [height, fps] = maxInput.split('x').map(v => Number(v));
        if (settings.Resolution.Height > height || settings.Fps > fps) {
          return {
            ...common.error,
            message: 'Bad transcoding config',
          };
        }
      }
    }

    return {
      ...common.inactive,
      message: undefined,
      statusColor: undefined,
    };
  }, [
    data?.IsOn,
    data?.State,
    data?.RtmpUrl,
    data?.Key,
    data?.TranscoderId,
    isStreaming,
    target.DefaultRtmpUrl,
    transcodingEnabled,
    maxInput,
    settings.Resolution.Height,
    settings.Fps,
    data.AutoLoginState,
    data.TargetMode,
  ]);

  return message;
};
