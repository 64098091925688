import PageWrapper from '#/components/PageWrapper/PageWrapper';
import { AuthContext } from '#/screens/auth/auth.utils';
import { useContext, useEffect, useState } from 'react';
import { useSocketConnectionSwitcher } from '#/utils';
import { BusinessUserSwitcher } from '#/components/BusinessUserSwitcher/BusinessUserSwitcher';
import { useSelectedBusinessUserStore } from '#/screens/home/selectedBusinessUser.store';
import { useBusinessUserTokenRequest } from '#/api/api.portal';
import { OverlaysView } from '#/screens/overlays/OverlaysView';
import { EventDispatcher } from '#/screens/overlays/Overlays.screen.types';

export const OverlaysScreen = () => {
  useSocketConnectionSwitcher();

  const { selectedUserId, selectedUserLogin } = useSelectedBusinessUserStore();
  const auth = useContext(AuthContext);
  const [request] = useBusinessUserTokenRequest({
    adminRequest: auth.webAuth?.admin,
    tokenType: 'overlays',
  });
  const [token, setToken] = useState('');
  const [eventDispatcher, setEventDispatcher] = useState<EventDispatcher | null>(null);

  useEffect(() => {
    if (!selectedUserId || selectedUserId === auth.webAuth?.userId) {
      setToken(auth.webAuth?.accessToken || '');
    } else {
      request(selectedUserId, selectedUserLogin).then(response =>
        setToken(response.data.access_token),
      );
    }
  }, [selectedUserId]);

  useEffect(() => {
    import('streamster-overlays').then(module => {
      setEventDispatcher((module as any).eventDispatcher);
    });
  }, []);

  return (
    <PageWrapper>
      {selectedUserId && <BusinessUserSwitcher />}
      {token && eventDispatcher ? (
        <OverlaysView accessToken={token} eventDispatcher={eventDispatcher} />
      ) : null}
    </PageWrapper>
  );
};
