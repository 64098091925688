import { UserMonitoringUserSessionState } from '#/api/types';
import mixins from '#/app/styles';
import { Text, StyleSheet } from 'react-native';

interface Props {
  size?: 'small' | 'normal' | 'medium';
  status?: UserMonitoringUserSessionState;
  active?: boolean;
  letter?: string;
}

const styles = StyleSheet.create({
  0: {
    backgroundColor: mixins.color.red,
  },
  1: {
    backgroundColor: mixins.color.green,
  },
  small: {
    width: 11,
    height: 11,
    lineHeight: 10,
    fontSize: 10,
  },
  medium: {
    width: 16,
    height: 16,
    lineHeight: 16,
    fontSize: 10,
  },
  outer: {
    width: 20,
    display: 'flex',
    justifyContent: 'center',
    height: 20,
    borderRadius: 50,
    color: mixins.color.darker,
    textAlign: 'center',
    lineHeight: 20,
    fontWeight: 'bold',
    flexShrink: 0,
    fontSize: 12,
  },
});

export const ActivityStatus: React.FC<Props> = ({ size, status, letter, active }) => {
  return (
    <Text
      style={[
        styles.outer,
        size && styles[size],
        status !== undefined && styles[status],
        active !== undefined && styles[active ? 1 : 0],
      ]}
    >
      {letter}
    </Text>
  );
};
