import React, { useEffect, useState } from 'react';
import hoistStatics from 'hoist-non-react-statics';
import AsyncStorage from '@react-native-community/async-storage';

export const setData = async (key: string, value) => {
  try {
    const jsonValue = JSON.stringify(value);
    await AsyncStorage.setItem(key, jsonValue);
  } catch (e) {
    console.log('setData error =>', e);
  }
};

export const getData = async (key: string) => {
  try {
    const jsonValue = await AsyncStorage.getItem(key);
    return jsonValue !== null ? JSON.parse(jsonValue) : {};
  } catch (e) {
    console.log('getData error =>', e);
  }
};

function connect(defaultState, mapStateToProps, keyStore) {
  return WrappedComponent => {
    function ConnectFunction(props) {
      const [state, setState] = useState({
        restore: false,
        ...defaultState,
      });

      const updateStore = data => {
        if (data) {
          setData(keyStore, data);
        }
      };

      useEffect(() => {
        async function restoreData() {
          try {
            const data = await getData(keyStore);
            setState({
              restore: true,
              ...data,
            });
          } catch (e) {
            console.log(e);
          }
        }
        restoreData();
      }, []);

      if (!state.restore) {
        // TODO: need add loading
        return null;
      }
      return React.createElement(WrappedComponent, { state, updateStore }, props.children);
    }
    return hoistStatics(ConnectFunction, WrappedComponent);
  };
}

export function useStore<T>(
  keyStore: string,
  defaultState: T,
): { state: T | null; updateStore: (data: Partial<T>) => void } {
  const [state, setState] = useState({
    restored: false,
    ...defaultState,
  });

  const updateStore = (data: Partial<T>) => {
    if (data) {
      setData(keyStore, data);
    }
  };

  useEffect(() => {
    async function restoreData() {
      try {
        const data = await getData(keyStore);
        setState({
          restore: true,
          ...data,
        });
      } catch (e) {
        console.log(e);
      }
    }
    restoreData();
  }, []);

  if (!state.restored) {
    // TODO: need add loading
    return { state: null, updateStore };
  }

  return { state, updateStore };
}

export default connect;
