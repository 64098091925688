import React, { useState } from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import type { SourceType } from './ActiveStream.utils';
import ManageChannels from './ManageChannels/ManageChannels';

import Source from './Source/Source';
import Status from './Status/Status';
import Tab from './Tab/Tab';
import { BusinessUserSwitcher } from '#/components/BusinessUserSwitcher/BusinessUserSwitcher';
import { useAppRoute } from '#/navigation/navigation.ref';
import { useSocketConnectionSwitcher } from '#/utils';
import { useSelectedBusinessUserStore } from '#/screens/home/selectedBusinessUser.store';

const ActiveStreamScreen: React.FC<{}> = ({}) => {
  useSocketConnectionSwitcher();

  const { selectedUserId } = useSelectedBusinessUserStore();
  const route = useAppRoute<'ActiveStream'>();
  const [currentSource, setCurrentSource] = useState<SourceType>(
    (route.params?.source || 'app').toUpperCase() as SourceType,
  );

  return (
    <PageWrapper>
      {selectedUserId && <BusinessUserSwitcher />}
      <Tab title="How to stream with" defaultOpen={route.params?.tab === 'source'}>
        <Source source={currentSource} handleSourceChange={setCurrentSource} />
      </Tab>
      <Tab title="Status" defaultOpen>
        <Status source={currentSource} />
      </Tab>
      <Tab title="Restreaming destinations" defaultOpen>
        <ManageChannels />
      </Tab>
    </PageWrapper>
  );
};

export default ActiveStreamScreen;
