import React, { useContext, useState } from 'react';
import { View, Text, ScrollView } from 'react-native';
import ArrowLeftIcon from '../../assets/icons/arrow-left.svg';

// context
import { ToasterContext } from '../../toaster/toaster.provider';

// styles
import styles from './resetPassword.styles';
import { MaterialInput } from '../../components/MaterialInput/MaterialInput';
import { AuthContext } from '../auth/auth.utils';
import { useIsMobile } from '../../utils';
import mixins from '../../app/styles';
import Button from '../../components/Button/Button';
import { useAppNavigation, useAppRoute } from '#/navigation/navigation.ref';

const ResetPasswordScreen = () => {
  const isMobile = useIsMobile();
  const navigation = useAppNavigation();
  const route = useAppRoute<'ResetPassword'>();
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { error, success } = useContext(ToasterContext);
  const { requestPasswordChange, resetPassword } = useContext(AuthContext);

  const handleRequestPasswordReset = async () => {
    if (login.length < 3) {
      return error({ message: 'Please enter your credentials' });
    }
    setLogin('');
    try {
      await requestPasswordChange(login);
      success({
        message: 'We will send instructions to matching account',
        title: 'Password reset request was submitted',
      });
    } catch (e: any) {
      error({ message: e.message, title: 'Something went wrong' });
    }
  };

  const handleChangePassword = async () => {
    if (!password) {
      return error({ message: '', title: 'Password cannot be empty' });
    }
    if (password !== confirmPassword) {
      return error({ message: 'Please type them again', title: "Passwords don't match!" });
    }
    try {
      await resetPassword({ password, code: route.params?.code! });
      success({
        message: 'Your password was changed!',
        title: 'Now you can log in using new credentials',
      });
      navigation.navigate('SignIn');
    } catch (e: any) {
      error({ message: e.message, title: 'Something went wrong' });
    }
  };

  return (
    <ScrollView>
      <View style={styles.webWrapper}>
        <View style={styles.box}>
          <ArrowLeftIcon onClick={() => navigation.navigate('SignIn')} style={styles.goBackIcon} />
          {route.params?.code ? (
            <>
              <Text style={[styles.webHeader, isMobile && mixins.styles.margin.topI6]}>
                Reset your password
              </Text>
              <Text style={styles.webSubHeader}>enter your new password</Text>

              <View
                style={[
                  styles.inputContainer,
                  styles.webInputContainer,
                  isMobile && mixins.styles.margin.bottomI10,
                ]}
              >
                <MaterialInput
                  onChangeText={setPassword}
                  value={password}
                  autoComplete="password"
                  label="Password"
                  secureTextEntry
                />
                <MaterialInput
                  onChangeText={setConfirmPassword}
                  value={confirmPassword}
                  autoComplete="password"
                  label="Confirm password"
                  secureTextEntry
                />
                <Button style={styles.btn} onPress={handleChangePassword}>
                  Submit
                </Button>
              </View>
            </>
          ) : (
            <>
              <Text style={[styles.webHeader, isMobile && mixins.styles.margin.topI6]}>
                Reset your password
              </Text>
              <Text style={styles.webSubHeader}>
                enter your data and we will email you instructions
              </Text>
              <Text style={styles.usernameReminder}>
                If you forgot your login, you will receive it in email.
              </Text>
              <View
                style={[
                  styles.inputContainer,
                  styles.webInputContainer,
                  isMobile && mixins.styles.margin.bottomI10,
                ]}
              >
                <MaterialInput
                  onChangeText={setLogin}
                  value={login}
                  autoComplete="email"
                  label="Login or e-mail"
                />
                <Button style={styles.btn} onPress={handleRequestPasswordReset}>
                  Submit
                </Button>
              </View>
            </>
          )}
        </View>
      </View>
    </ScrollView>
  );
};

export default ResetPasswordScreen;
