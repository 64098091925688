import { create } from 'zustand';
import { Props } from '../DefaultModal/DefaultModal';

interface Store {
  modal: Props | null;
  show: (opts: Props) => void;
  hide: () => void;
}

export const useGlobalModalStore = create<Store>(set => ({
  modal: null,
  show: (opts: Props) => set({ modal: opts }),
  hide: () => set({ modal: null }),
}));
