import { useAppNavigation, useAppRoute } from '#/navigation/navigation.ref';
import { ToasterContext } from '#/toaster/toaster.provider';
import React, { Suspense, useContext, lazy, useEffect } from 'react';
import type { OverlaysUser } from 'streamster-overlays';
import Loader from '#/components/Loader/Loader';
import { useIsMobile } from '#/utils';
import { Text, View } from 'react-native';
import mixins from '#/app/styles';
import Icon from '#/assets/icons/layers-off.svg';
import { SocketContext } from '#/socket/socket.provider';
import { updateOverlayListAction } from '#/socket/socket.actions';
import styles from './Overlays.screen.styles';
import {
  EventDispatcher,
  OverlaysCallback,
  OverlaysEvent,
} from '#/screens/overlays/Overlays.screen.types';

const UserOverlayEditor = lazy(() =>
  import('streamster-overlays').then(module => {
    return { default: module.UserOverlayEditor };
  }),
);

const UserOverlayManager = lazy(() =>
  import('streamster-overlays').then(module => {
    return { default: module.UserOverlayManager };
  }),
);

type Props = {
  accessToken: string;
  eventDispatcher: EventDispatcher;
};

export const OverlaysView: React.FC<Props> = ({ accessToken, eventDispatcher }) => {
  const isMobile = useIsMobile();
  const route = useAppRoute<'Overlays'>();
  const navigation = useAppNavigation();
  const toaster = useContext(ToasterContext);
  const { serverData, sendPatch } = useContext(SocketContext);

  const websocketUrl = Object.values(serverData.Sockets || {}).find(s => s.Kind === 0)?.Url;

  const user: OverlaysUser = {
    authToken: accessToken,
  };

  const handleOverlayManager = ([error, action]: OverlaysCallback) => {
    if (error) {
      console.log(error);
      toaster.error({ message: 'Something went wrong' });
      return;
    }

    if (action[1] === 'edit' || action[1] === 'create') {
      navigation.navigate('Overlays', { action: 'edit', id: action[0] });
    }
  };

  const handleOverlayEditor = (_payload: [error: any, action: any] | undefined) => {
    navigation.navigate('Overlays');
  };

  useEffect(() => {
    const handler = (event: OverlaysEvent) => {
      if (event.action === 'list_overlays') {
        sendPatch(
          updateOverlayListAction(
            event.overlays.items.map(o => ({
              Id: o._id,
              Name: o.name,
              Url: `https://o.streamster.io/${o.pathname}`,
            })),
            serverData.Settings.Overlays === undefined,
          ),
        );
      }
    };

    eventDispatcher.on('overlays:refreshed', handler);

    return () => eventDispatcher.removeListener('overlays:refreshed', handler);
  }, [serverData.Settings.Overlays !== undefined]);

  if (isMobile) {
    return (
      <View style={styles.wrapper}>
        <Icon width={100} fill={mixins.color.white} />
        <Text style={styles.unavailable}>
          Overlays are not available on mobile.{'\n\n'}
          Please visit this tab on desktop to create and manage your Streamster overlays.
        </Text>
      </View>
    );
  }

  return (
    <Suspense fallback={<Loader size="large" />}>
      <div>
        {route.params?.action !== 'edit' && (
          <UserOverlayManager user={user} onComplete={handleOverlayManager} onNotif={console.log} />
        )}
        {route.params?.action === 'edit' && (
          <UserOverlayEditor
            user={user}
            //@ts-expect-error overlays lib is a lazy dev and didn't add types
            websocketUrl={websocketUrl}
            onComplete={handleOverlayEditor}
            overlayId={route.params.id}
          />
        )}
      </div>
    </Suspense>
  );
};
