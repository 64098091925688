import mixins from '#/app/styles';
import { Pressable, StyleSheet, Text } from 'react-native';
import CheckIcon from '#/assets/icons/check.svg';

type Props = {
  children?: string;
  onPress?: () => void;
  selected?: boolean;
};

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: '#2e3c43',
    borderRadius: 20,
    paddingHorizontal: 16,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
  wrapperActive: {
    backgroundColor: '#525e63',
  },
  text: {
    color: mixins.color.whiteText,
  },
});

export const ButtonSwitch: React.FC<Props> = ({ children, onPress, selected }) => {
  return (
    <Pressable onPress={onPress} style={[styles.wrapper, selected && styles.wrapperActive]}>
      {selected && <CheckIcon height={15} width={20} fill={mixins.color.whiteText} />}
      <Text style={styles.text}>{children}</Text>
    </Pressable>
  );
};
