import React, { useContext, useState } from 'react';
import { View, Text, TextInput } from 'react-native';

import styles from './ReferAFriend.styles';
import Button from '#/components/Button/Button';
import handleLink from '#/helpers/handle-link';
import { ReferralStats } from '#/api/types';
import { ToasterContext } from '#/toaster/toaster.provider';
import { copyToClipboard, formatPrice } from '#/utils';
import DefaultModal from '#/components/DefaultModal/DefaultModal';
import { useWithdrawalRequestMutation } from '#/api/api.portal';
import mixins from '#/app/styles';
import Heading from '#/components/Heading/Heading';
import dayjs from 'dayjs';

interface Props {
  user: { login: string; email?: string; tariffId: number };
  stats: ReferralStats;
}

export const ReferAFriendBox: React.FC<Props> = ({ user, stats }) => {
  const [isWithdrawModalVisible, setWithdrawModalVisibility] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState(stats.currentBalance.toString());
  const mutation = useWithdrawalRequestMutation();
  const { success, error } = useContext(ToasterContext);
  const handleCopy = (url: string) => () => {
    copyToClipboard(url);
    success({
      message: 'Copied to clipboard',
    });
  };
  const requestWithdrawal = async (method: 'TRANSFER' | 'WITHDRAWAL') => {
    const amount = Number(withdrawAmount);
    if (amount === 0) {
      error({
        title: 'Cannot withdraw',
        message: "You don't have any funds to withdraw.",
      });
      return;
    }
    if (amount > stats.currentBalance) {
      error({
        title: 'Cannot withdraw',
        message: 'Your current balance is less than the value you want to withdraw!',
      });
      return;
    }
    if (method === 'WITHDRAWAL' && amount < 30) {
      error({
        title: 'Cannot withdraw',
        message: 'Minimum amount for this withdrawal method is $30',
      });
      return;
    }

    await mutation.mutateAsync({ value: amount, type: method });

    if (method === 'TRANSFER') {
      success({ message: 'Funds have been transferred' });
    } else {
      success({ message: 'Your request was submitted' });
    }

    setWithdrawModalVisibility(false);
  };

  const TYPE_DESCRIPTION: Record<ReferralStats['history'][number]['type'], string> = {
    PROVISION: '10% reward',
    WITHDRAWAL: 'Withdrawal request',
    TRANSFER: 'Transfer to deposit',
    REGISTRATION: 'Welcome reward',
  };

  const handleValueChange = (v: string) => {
    const newV = v.replace(',', '.');

    if (Number.isFinite(Number(newV))) {
      setWithdrawAmount(newV);
    }
  };

  return (
    <View style={styles.wrapper}>
      <Text style={styles.description}>
        Share your unique sign-up link or a promo code with your friends or audience. Your invitee
        will get a $10 credit. You will earn $10 once your invitee purchases any paid subscription.
        In addition, you will earn 10% on all of your invitees’ purchases, including subscription
        rebills and deposit top-ups, lifetime!
        <Text
          style={styles.linkText}
          onPress={() =>
            handleLink(
              'https://docs.streamster.io/docs/account/affiliate-program-5707/getting-started-with-streamster-referral-program-7994/',
              '_blank',
            )
          }
        >
          Read more
        </Text>
      </Text>
      <View style={styles.linkSection}>
        <View style={styles.link}>
          <Text style={styles.label}>Sign up link</Text>
          <TextInput
            editable={false}
            style={styles.linkInput}
            value={stats.url}
          />
          <Button onPress={handleCopy(stats.url)}>Copy</Button>
        </View>
        <View style={styles.link}>
          <Text style={styles.label}>Promo code</Text>
          <TextInput editable={false} style={[styles.linkInput, styles.code]} value={stats.code} />
          <Button onPress={handleCopy(stats.code)}>Copy</Button>
        </View>
      </View>
      <View>
        <Text style={styles.statsHeading}>Statistics</Text>
        <View style={styles.statsTop}>
          <Text style={styles.statsItem}>Users registered: {stats.usersRegistered}</Text>
          <Text style={styles.statsItem}>Paid users: {stats.paidUsers}</Text>
          <Text style={styles.statsItem}>Earned total: {formatPrice(stats.earnedTotal)}</Text>
        </View>
        <View style={styles.statsBottom}>
          <Text style={styles.statsItem}>Current balance</Text>
          <Text style={styles.currentBalance}>{formatPrice(stats.currentBalance)}</Text>
          <Button onPress={() => setWithdrawModalVisibility(true)}>Withdraw</Button>
        </View>
      </View>
      {stats.history.length > 0 && (
        <View style={styles.tableWrapper}>
          <Heading style={mixins.styles.margin.topI8}>Earned credits history</Heading>
          <View>
            <View style={[styles.transactionRow, styles.transactionHeadingRow]}>
              <Text style={styles.columnDate}>Date</Text>
              <Text style={styles.columnDescription}>Description</Text>
              <Text style={styles.columnLogin}>Invitee username</Text>
              <Text style={styles.columnValue}>Value</Text>
              <Text style={styles.columnBalance}>Balance</Text>
            </View>
            {stats.history.map(t => (
              <View style={styles.transactionRow} key={t.createdAt}>
                <Text style={styles.columnDate}>
                  {dayjs(t.createdAt).format('DD MMM, YYYY HH:mm:ss')}
                </Text>
                <Text style={styles.columnDescription}>
                  {TYPE_DESCRIPTION[t.type]}
                  {t.description ? ` (${t.description})` : ''}
                </Text>
                <Text style={styles.columnLogin}>{t.referredUser?.login || '-'}</Text>
                <Text style={styles.columnValue}>{formatPrice(t.value)}</Text>
                <Text style={styles.columnBalance}>{formatPrice(t.balance)}</Text>
              </View>
            ))}
          </View>
        </View>
      )}
      {isWithdrawModalVisible && (
        <DefaultModal
          title="Withdraw your reward"
          confirmText="Transfer to deposit"
          confirmLoading={mutation.isLoading}
          onConfirm={() => requestWithdrawal('TRANSFER')}
          onDismiss={() => setWithdrawModalVisibility(false)}
          dismissText="Cancel"
          additionalButtons={
            <Button loading={mutation.isLoading} onPress={() => requestWithdrawal('WITHDRAWAL')}>
              Submit a withdrawal request (minimum $30)
            </Button>
          }
        >
          <Text style={styles.modalText}>
            You can transfer your rewards to your Streamster deposit to use it for any paid
            Streamster services. Alternatively, you can withdraw it as a cash: after creating a
            withdrawal request we'll contact you within 2 days with more details.
          </Text>
          <Text style={styles.modalText}>Enter withdrawal value</Text>
          <View style={styles.customValueRow}>
            <Button
              style={styles.amountButtons}
              disabled={Number(withdrawAmount) < 1}
              onPress={() => setWithdrawAmount(v => (Number(v) - 1).toString())}
            >
              -
            </Button>
            <TextInput
              value={withdrawAmount}
              onChangeText={handleValueChange}
              style={styles.input}
            />
            <Button
              style={styles.amountButtons}
              disabled={Number(withdrawAmount) >= stats.currentBalance}
              onPress={() => setWithdrawAmount(v => (Number(v) + 1).toString())}
            >
              +
            </Button>
          </View>
        </DefaultModal>
      )}
    </View>
  );
};
