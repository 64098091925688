import { Platform, StyleSheet } from 'react-native';
import mixins from '../../app/styles';
import { DEVICE_WIDTH } from '../../utils';

const isWeb = Platform.OS === 'web';

const IMAGE_ORIGINAL_SIZE = {
  width: 934,
  height: 277,
};

const IMAGE_HEIGHT =
  (IMAGE_ORIGINAL_SIZE.height * (DEVICE_WIDTH - mixins.indent.i9 * 2)) / IMAGE_ORIGINAL_SIZE.width;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
  },
  flex: {
    flex: 1,
  },
  logoImg: {
    marginHorizontal: mixins.indent.i9,
    width: DEVICE_WIDTH - mixins.indent.i9 * 2,
    height: IMAGE_HEIGHT,
    marginTop: mixins.indent.i11 * 2,
  },
  inputContainer: {
    marginBottom: mixins.indent.i11 * 3,
    ...(!isWeb && { alignItems: 'center' }),
  },
  inputWrap: {
    marginHorizontal: mixins.indent.i11,
    shadowColor: mixins.color.white,
    shadowOffset: {
      width: 5,
      height: 5,
    },
    shadowOpacity: 0.6,
    shadowRadius: mixins.indent.i10,
    elevation: 30,
    ...(!isWeb && { width: '85%' }),
  },
  input: {
    height: mixins.indent.i12,
    backgroundColor: mixins.color.white,
    paddingHorizontal: mixins.indent.i7,
    color: mixins.color.dirty,
    fontWeight: 'bold',
  },
  inputUp: {
    borderTopLeftRadius: mixins.indent.i3,
    borderTopRightRadius: mixins.indent.i3,
    borderBottomWidth: 0.5,
    borderBottomColor: mixins.color.input,
  },
  inputDown: {
    borderBottomLeftRadius: mixins.indent.i3,
    borderBottomRightRadius: mixins.indent.i3,
  },
  btnLogin: {
    backgroundColor: mixins.color.blue,
    borderRadius: mixins.indent.i3,
    alignSelf: 'center',
    paddingVertical: mixins.indent.i4,
    paddingHorizontal: mixins.indent.i11,
    borderColor: mixins.color.blueLight,
    borderWidth: 1,
    marginTop: mixins.indent.i5,
    ...(Platform.OS === 'web' && {
      alignSelf: 'flex-end',
    }),
  },
  btnText: {
    color: mixins.color.white,
    fontSize: mixins.indent.i8,
    fontWeight: 'bold',
  },
  linkWrap: {
    alignItems: 'center',
    marginBottom: mixins.indent.i10,
  },
  linkText: {
    color: mixins.color.blueLightest,
    fontSize: mixins.indent.i8,
    marginBottom: mixins.indent.i8,
  },
  indicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  actionRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: mixins.indent.i11,
  },
  actionRowMobile: {
    flexDirection: 'column-reverse',
  },
  forgotPassword: {
    color: mixins.color.blueLightest,
    fontSize: mixins.font.defaultFont,
  },

  // WEB

  webWrapper: {
    flex: 1,
    alignItems: 'center',
    paddingHorizontal: 15,
    justifyContent: 'center',
  },
  webInputContainer: {
    marginBottom: mixins.indent.i11 * 3,
    maxWidth: 400,
    width: '100%',
  },
  webHeader: {
    color: mixins.color.whiteText,
    fontSize: 30,
    marginTop: 30,
  },
  webSubHeader: {
    color: mixins.color.whiteText,
    fontSize: 20,
  },
  registered: {
    color: mixins.color.whiteText,
    marginVertical: mixins.indent.i10,
    backgroundColor: mixins.color.blue,
    paddingHorizontal: mixins.indent.i10,
    paddingVertical: mixins.indent.i7,
    borderRadius: 10,
    textAlign: 'center',
  },
  registeredMobile: {
    color: mixins.color.whiteText,
    textAlign: 'center',
  },
  registeredMobileWrapper: {
    borderRadius: 5,
    backgroundColor: mixins.color.blue,
    paddingHorizontal: mixins.indent.i10,
    width: '90%',
    paddingVertical: mixins.indent.i7,
    marginBottom: mixins.indent.i11,
  },
  box: {
    maxWidth: 600,
    width: '100%',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,.3)',
    marginBottom: 50,
  },
  boxMobile: {
    paddingVertical: 10,
    marginBottom: 15,
  },
  createAccountButtonMobile: {
    marginBottom: mixins.indent.i12,
    paddingVertical: 8,
    paddingHorizontal: 40,
  },
  createAccountButtonMobileText: {
    fontSize: 14,
  },
  createAccountButton: {
    borderWidth: 2,
    borderColor: mixins.color.blue,
    borderStyle: 'solid',
    borderRadius: 5,
    color: mixins.color.whiteText,
    marginTop: 25,
    paddingVertical: 10,
    paddingHorizontal: 50,
    fontSize: 18,
    fontWeight: '600',
  },
});

export default styles;
