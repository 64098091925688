import { StyleSheet } from 'react-native';
import mixins from '../../app/styles';

const styles = StyleSheet.create({
  wrapper: {
    maxWidth: 700,
  },
  description: {
    color: mixins.color.whiteText,
    fontSize: mixins.font.defaultFont,
    lineHeight: mixins.font.defaultFont * 1.5,
  },
  linkText: {
    color: mixins.color.blue,
    cursor: 'pointer',
    fontWeight: 'bold',
    marginLeft: 10,
  },
  linkSection: {
    borderBottomColor: mixins.color.grayDarkest,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    paddingBottom: 30,
    paddingTop: 50,
    marginBottom: 30,
  },
  link: {
    alignItems: 'center',
    marginVertical: 10,
    flexDirection: 'row',
  },
  label: {
    color: mixins.color.whiteText,
    fontSize: mixins.font.bigFont,
    width: 150,
  },
  linkInput: {
    marginRight: 10,
    height: 40,
    backgroundColor: mixins.color.darker,
    paddingHorizontal: 10,
    width: 400,
    color: mixins.color.whiteText,
  },
  code: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  statsHeading: {
    textAlign: 'center',
    textTransform: 'uppercase',
    color: mixins.color.whiteText,
    fontSize: mixins.font.sectionFont,
    marginBottom: 30,
  },
  statsTop: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 50,
  },
  statsBottom: {
    alignItems: 'center',
  },
  statsItem: {
    fontSize: mixins.font.bigFont,
    color: mixins.color.whiteText,
  },
  currentBalance: {
    textAlign: 'center',
    fontSize: mixins.font.titleFont,
    color: mixins.color.whiteText,
    marginVertical: 10,
  },

  row: {
    flexDirection: 'row',
  },
  customValueRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 25,
  },
  input: {
    backgroundColor: mixins.color.darker,
    paddingVertical: 8,
    borderRadius: 5,
    paddingHorizontal: 10,
    marginHorizontal: 5,
    fontSize: 24,
    width: 100,
    color: mixins.color.white,
    textAlign: 'center',
  },
  amountButtons: {
    paddingHorizontal: 15,
    fontSize: 24,
  },
  modalText: {
    color: mixins.color.whiteText,
    marginTop: mixins.indent.i6,
    marginBottom: mixins.indent.i6,
    fontSize: mixins.font.bigFont,
    lineHeight: mixins.font.bigFont * 1.5,
    textAlign: 'center',
  },

  tableWrapper: {
    marginTop: 40,
    borderTopColor: mixins.color.grayDarkest,
    borderTopWidth: 1,
    borderStyle: 'solid',
  },
  transactionRow: {
    flexDirection: 'row',
    color: mixins.color.white,
    paddingHorizontal: 15,
    paddingVertical: 3,
    borderBottomColor: mixins.color.grayDark,
    borderBottomWidth: 1,
    borderStyle: 'solid',
  },
  transactionHeadingRow: {
    backgroundColor: 'rgba(0,0,0,.3)',
    paddingVertical: 8,
    borderBottomWidth: 0,
    marginBottom: 5,
  },
  columnDate: { flexBasis: 150, color: mixins.color.white },
  columnLogin: { flexBasis: 100, flexGrow: 1, flexShrink: 1, color: mixins.color.white },
  columnDescription: { flexBasis: 200, flexGrow: 1, flexShrink: 1, color: mixins.color.white },
  columnValue: { flexBasis: 80, textAlign: 'right', color: mixins.color.white },
  columnBalance: { flexBasis: 80, textAlign: 'right', color: mixins.color.white },
});

export default styles;
