import DefaultModal from '../../../components/DefaultModal/DefaultModal';
import { View, Text, Image, Pressable } from 'react-native';
import { FC, useContext, useState } from 'react';
import Button from '../../../components/Button/Button';

import imageCrypto from '../../../assets/img/payment_crypto.png';
import imagePaypal from '../../../assets/img/payment_paypal.png';
import imageBalance from '../../../assets/img/payment_balance.png';
import imageCard from '../../../assets/img/payment_card.png';
import mixins from '../../../app/styles';
import { ActiveTariff } from '../../../api/types';
import { cleanName } from '../Tariff.utils';
import dayjs from 'dayjs';
import { ToasterContext } from '../../../toaster/toaster.provider';
import styles from './PaykickstartModal.styles';
import handleLink, { onPressOpenLink } from '#/helpers/handle-link';

interface Props {
  paykickstartElement?: { dataset: any };
  tariffId?: number;
  myTariff?: Pick<ActiveTariff, 'name' | 'id' | 'validTo'>;
  allowCredit?: boolean;
  onChangeTariff?: (method: 'CRYPTO' | 'CREDIT') => void;
  onTopUp?: () => void;
  onClose: () => void;
}

const PaykickstartModal: FC<Props> = ({
  onClose,
  tariffId,
  paykickstartElement,
  onChangeTariff,
  onTopUp,
  myTariff,
  allowCredit,
}) => {
  const [paymentMethod, setPaymentMethod] = useState<'CARD' | 'PAYPAL' | 'CRYPTO' | 'CREDIT'>();
  const { error } = useContext(ToasterContext);

  return (
    <DefaultModal
      title="Choose payment method"
      onDismiss={onClose}
      rawContent
      confirmText="Proceed"
      onConfirm={
        paymentMethod
          ? () => {
              if (paymentMethod !== 'CREDIT' && paymentMethod !== 'CRYPTO' && paykickstartElement) {
                if (!(window as any).PKWIDGET) {
                  return error({
                    message: 'Please disable adblock/browser extensions and try again',
                    title: 'Unable to initiate payment process',
                  });
                }

                (window as any).PKWIDGET.openModal(paykickstartElement);
              } else {
                if (
                  tariffId &&
                  onChangeTariff &&
                  (paymentMethod === 'CREDIT' || paymentMethod === 'CRYPTO')
                ) {
                  onChangeTariff(paymentMethod);
                }
                if (!tariffId && onTopUp && paymentMethod === 'CRYPTO') {
                  onTopUp();
                }
              }
            }
          : undefined
      }
    >
      {myTariff?.validTo && (
        <View style={styles.currentTariff}>
          <Text style={styles.text}>
            You have currently enabled pricing plan:{' '}
            <Text style={styles.textBold}>{cleanName(myTariff.name)}</Text>
          </Text>
          <Text style={styles.text}>
            Subscription is valid to:{' '}
            <Text style={styles.textBold}>{dayjs(myTariff!.validTo).format('YYYY-MM-DD')}</Text>
          </Text>
          <Text style={[styles.text, mixins.styles.margin.topI7]}>
            Changing pricing plan will cancel previous one.
          </Text>
        </View>
      )}
      <View style={styles.row}>
        <Button onPress={() => setPaymentMethod('CARD')} secondary={paymentMethod !== 'CARD'}>
          Credit Card
        </Button>
        <Button onPress={() => setPaymentMethod('PAYPAL')} secondary={paymentMethod !== 'PAYPAL'}>
          PayPal
        </Button>
        <Button onPress={() => setPaymentMethod('CRYPTO')} secondary={paymentMethod !== 'CRYPTO'}>
          Cryptocurrency
        </Button>
        {allowCredit && (
          <Button onPress={() => setPaymentMethod('CREDIT')} secondary={paymentMethod !== 'CREDIT'}>
            User balance
          </Button>
        )}
      </View>
      {paymentMethod === 'PAYPAL' && (
        <View style={styles.wrapper}>
          <Text style={styles.text}>
            You are being redirected to a payment page. Please select the “PayPal” option on the
            next pop-up as it is shown in the screenshot below and then proceed to your PayPal
            account.
          </Text>

          <Image
            style={styles.image}
            //@ts-expect-error - wrong types for web/mobile
            source={imagePaypal}
            resizeMode="contain"
          />
        </View>
      )}
      {paymentMethod === 'CARD' && (
        <View style={styles.wrapper}>
          <Text style={styles.text}>
            You are being redirected to a payment page. Please don’t change the default payment
            method on the next pop-up as it is shown in the screenshot below and fill in your credit
            card details
          </Text>
          <Image
            style={styles.image}
            //@ts-expect-error - wrong types for web/mobile
            source={imageCard}
            resizeMode="contain"
          />
        </View>
      )}
      {paymentMethod === 'CREDIT' && (
        <View style={styles.wrapper}>
          <Text style={styles.text}>
            Use your Streamster balance to renew your subscription every month. Please note, that to
            use this method you should have enough money on your balance at least for a one-month
            period.
          </Text>
          <Image
            style={styles.image}
            //@ts-expect-error - wrong types for web/mobile
            source={imageBalance}
            resizeMode="contain"
          />
        </View>
      )}
      {paymentMethod === 'CRYPTO' && (
        <View style={styles.wrapper}>
          <Text style={styles.text}>You are being redirected to a payment page.</Text>
          <Text style={[styles.text, styles.textRed, mixins.styles.margin.topI5]}>
            If you need more cryptocurrency options, please contact us via online chat or{' '}
            <Pressable
              onPress={onPressOpenLink('https://help.streamster.io/support/tickets/new', '_blank')}
            >
              <Text style={[styles.text, styles.textRed, styles.textUnderline]}>
                submit a ticket
              </Text>
            </Pressable>
            . Kindly provide us with the information about the currency you want to use.
          </Text>
        </View>
      )}
    </DefaultModal>
  );
};

export default PaykickstartModal;
